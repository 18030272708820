
export var MenuState = {
    UNLOCK: 0,
    LOCK: 1,
    toString: function(value) {
        switch(value) {
            case MenuState.UNLOCK: return "unlock";
            case MenuState.LOCK: return "lock";
            default: return "unknown";
        }
    } 
}

export var BoardShareState = {
    STOP: 0,
    START: 1,
    toString: function(value) {
        switch(value) {
            case BoardShareState.STOP: return "stop";
            case BoardShareState.START: return "start";
            default: return `unknown-${value}`;
        }
    } 
}

export var LiveState = {
    STOP: 0,
    START: 1,
    toString: function(value) {
        switch(value) {
            case LiveState.STOP: return "stop";
            case LiveState.START: return "start";
            default: return `unknown-${value}`;
        }
    } 
}

export var BackgroudViewType = {
    BOARD: 1,
    VIDEO: 2,
    toString: function(value) {
        switch(value) {
            case BackgroudViewType.BOARD: return "board";
            case BackgroudViewType.VIDEO: return "video";
            default: return `unknown-${value}`;
        }
    } 
}

export var ScreenShareState = {
    STOP: 0,
    START: 1,
    toString: function(value) {
        switch(value) {
            case ScreenShareState.STOP: return "stop";
            case ScreenShareState.START: return "start";
            default: return `unknown-${value}`;
        }
    } 
}
