import moment from "moment";
import { Log } from "../../util/log";
import { config } from "../../util/version";

export class DialHistory {

    constructor(itemKey = "vmeeting::dialHistory") {
        this.__itemKey = itemKey;

        this.__history = JSON.parse(window.localStorage.getItem(this.__itemKey) || "[]");
        this.__lastAddTime = null;
    }

    add(key, content) {
        if (window.localStorage) {
            this.__lastAddTime = moment().unix();
            this.__history = this.__history.slice(0, Math.max(config.call.maxDialHistory - 1, 0));
            this.__history.unshift(Object.assign({ key: `${key}_${this.__lastAddTime}` }, content));
            window.localStorage.setItem(this.__itemKey, JSON.stringify(this.__history))
            return this.__history;
        }

        return [];
    }

    del(key) {
        if (window.localStorage) {
            let tagIndex = this.__history.findIndex((item, index) => item?.key === key)
            if (tagIndex !== -1) {
                this.__history.splice(tagIndex, 1);
            }
            window.localStorage.setItem(this.__itemKey, JSON.stringify(this.__history))
            return this.__history;
        }

        return [];
    }

    mod(key, content) {
        if (window.localStorage) {
            let item = this.__history.find((item) => item?.key === `${key}_${this.__lastAddTime}`)
            if (item) {
                item = Object.assign(item, content)
                window.localStorage.setItem(this.__itemKey, JSON.stringify(this.__history))
            } else {
                Log.warn("not find dial, mod failed. key:", key, "content:", content, "dials:",this.__history)
            }
            return this.__history;
        }

        return [];
    }

    getAll() {
        return this.__history;
    }

    getFirst(field = undefined) {
        let first = this.__history[0];
        if (!field) {
            return first;
        } else {
            return first ? first[field] : undefined;
        }
    }
}