export class MediaHistory {

    constructor(itemKey = "vmeeting::mediaHistory") {
        this.__itemKey = itemKey;
    }

    set(content) {
        if (window.localStorage) {
            try {
                window.localStorage.setItem(this.__itemKey, JSON.stringify(content))
            } catch (error) {
                
            }
        }
    }

    get() {
        let data = undefined;
        if (window.localStorage) {
            try {
                data = JSON.parse(window.localStorage.getItem(this.__itemKey) || "");
            } catch (error) {
                data = undefined;
            }
        }

        return data;
    }
}