import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import { Switch } from 'react-router';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { configStore, loadStoreState } from './redux/store';

import Main from './pages/main/main';
import NoMatch from './pages/error/nomatch';
import GotoTest from './pages/error/gotoTest';
import GotoShow from './pages/error/gotoShow';
import GotoLocal from './pages/error/gotoLocal';
import GotoDev from './pages/error/gotoDev';
import VersionError from './pages/error/version';
import Test from './pages/test/test';

import './i18n';
import './index.css';

import zh_CN from 'antd/lib/locale-provider/zh_CN';
import * as serviceWorker from './serviceWorker';

const store = configStore(loadStoreState());

ReactDOM.render(
    <ConfigProvider locale={zh_CN}>
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={Main}/>
                    <Route exact path='/version_error' component={VersionError}/>
                    <Route exact path='/test' component={Test} />
                    <Route exact path='/goto_dev' component={GotoDev}/>
                    <Route exact path='/goto_test' component={GotoTest}/>
                    <Route exact path='/goto_show' component={GotoShow} />
                    <Route exact path='/goto_local' component={GotoLocal} />
                    <Route path='*' component={NoMatch}/>
                </Switch>
            </BrowserRouter>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
