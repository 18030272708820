/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
class Test extends Component {
    constructor () {
        super()
        this.state = {
            visible: false
        }
    }
    handleStart() {
        this.setState({
            visible: true
        })
        this.props.history.push("/version_error")
    }
    handleCancel() {
        this.setState({
            visible: false
        })
    }
    render() {
        return (<div>
            <button onClick={this.handleStart.bind(this)}>start</button>
        </div>);
    }
}

export default Test;