/* eslint-disable no-unused-vars */

import React, { Component } from "react";
import moment from "moment";
import { Space, Button, Statistic, Modal } from "antd";

import { Log } from "../../util/log";
import { State as FsmState } from "../../component/fsm/fsm";
import { Kind, Dir, } from "../../component/media/stream";
import { device as mediaDevice } from "../../component/media/device";
import { notification } from "../../component/media/notification";
import { version, config } from "../../util/version";
import { ScreenShareState,  } from "../../component/common/common";
import { message } from "../../util/message";
import { printObject, getTimeDesc2 } from "../../util/logic";
import { apiConferenceScreenShareStart, apiConferenceScreenShareStop } from "../../api/conference";

// 注册成功
function sipRegSessionOnConnected(e, This) {
    let { fsm, stream, sipConfig } = This.state;
    let { t } = This.props;
    Log.info(This.sipSessionInfo(e.session), `session register connected`);

    if (fsm.test(FsmState.Login.PROCESS)) {
        message.success(t('login.message.status.success'));
        fsm.transfer(FsmState.Login.DONE)
        let timeout = 0.5;
        This.registerInitTimer = setTimeout(() => {
            stream.stop(This.mainStream, 'display');
            fsm.transfer(FsmState.Call.INIT);
            if (This.tmpConfCode) {
                This.setState({
                    ...This.pageVisible('join'),
                    code: This.tmpConfCode,
                }, () => {
                    This.tmpConfCode = null;

                    let loop = () => {
                        if (!stream.preview.status || mediaDevice.microphonePerm !== mediaDevice.permission.allow || mediaDevice.cameraPerm !== mediaDevice.permission.allow) {
                            Log.debug(This.sipSessionInfo(e.session), `auto join loop again. `)
                            setTimeout(() => {
                                loop()
                            }, 1000)
                        } else {
                            This.callType = "audiovideo";
                            // 更新配置
                            let config = Object.assign(sipConfig, {
                                s_call_type: This.callType,
                            })
                            This.sipConfig(config);
                            This.meetJoinOnClick()
                        }
                    }

                    loop()
                });
            } else {
                This.setState({
                    ...This.pageVisible(),
                });
            }

            This.focusInputTimer = setTimeout(() => {
                if (This.codeInputDOM) {
                    This.codeInputDOM.focus();
                }
            }, 500);

            This.deviceLoopPermission();

        }, timeout * 1000);
        Log.debug(This.sipSessionInfo(e.session), `start timer(${This.registerInitTimer} after ${timeout}s) to jump to call page`)
    }
}

// 注册失败/注销
function sipRegSessionOnTerminated(e, This) {
    let { fsm, websocketStatus } = This.state;
    let { t } = This.props;
    let status = fsm.curState()
    let sipCode = e.getSipResponseCode();
    Log.info(This.sipSessionInfo(e.session), `session register terminated`);
    Log.debug(This.sipSessionInfo(e.session), `sip code: ${sipCode}`)
    switch(status){
        case FsmState.Login.INIT:
        case FsmState.Login.PROCESS:{
            switch(sipCode){
                case 403:
                    message.error(t('login.message.status.forbidden'));
                    break;
                case 200:
                    break;
                default:
                    message.error(t('login.message.status.error'));
                    break;
            }
            // This.exit(false, false);
            break;
        }
        case FsmState.Login.OUT:
            if (websocketStatus === 'success') {
                message.info(t('login.message.status.out'));
            } 
            break;

        default:
            This.exit(false, true);
            break;
    }
}

// 呼叫成功
function sipCallSessionOnConnected(e, This) {
    let that = This;
    let { fsm } = This.state;
    Log.info(This.sipSessionInfo(e.session), `session call connected`);

    let connected = () => {
        let { t } = This.props;
        let { dialHistory, code, theme, number } = This.state;
        let sessionId = e.session.getId();
        Log.info(This.sipSessionInfo(e.session), `session has stable`)

        fsm.transfer(FsmState.Call.STABLE);
        let content = {
            code: code,
            number: number,
            theme: theme,
            status: "success",
        }
        dialHistory.mod(sessionId, content)
        // 菜单栏超时自动隐藏
        // This.timerBarVisibleStart();
        // rtc打样数据清空
        This.rtc = [];
        let startTime = moment().unix();
        let state = {
            sipCallSessionId: sessionId,
            sipCallStartTime: startTime,
            sipCallExpireTime: startTime + config.call.duration.max,
            ...This.pageInvisible(),
        }
        switch (This.callType) {
            case "screenshare":
                message.success(t('call.message.notify.status.screen.connected'));
                Object.assign(state, {
                    localEnable: false,
                })
                break;
        
            default:
                message.success(t('call.message.notify.status.connected'));
                break;
        }

        This.setState(state, () => {
            setTimeout(() => {
                This.forceUpdate();
            }, 500);
        })

        // 一分钟检查一次状态
        This.expireTimer = setInterval(() => {
            let { sipCallExpireTime } = This.state;
            let remaining = sipCallExpireTime - moment().unix();
            if (0 < remaining && remaining <= config.call.duration.expireTipDelta) {
                Log.debug(This.sipSessionInfo(), `send a nofity to user, the call will expire`)
                // 如果当前页面没有被激活
                if (that.pageHidden) {
                    This.notify = notification.open(t('call.message.notify.expire.title'), {
                        body: t('call.message.notify.expire.tips', {remaining: getTimeDesc2(remaining, t)})
                    })
                    if (This.notify) {
                        This.notify.onshow = function() {
                            // console.log('notification shows up');
                        };
                
                        //消息框被点击时被调用
                        This.notify.onclick = function() {
                            // console.log('notification on click');
                            Log.info(This.sipSessionInfo(), `ui event: notification onclick`)
                            window.focus();
                            if (This.notify) {
                                This.notify.close();
                            }
                        };
                
                        //如果没有granted授权，创建Notification对象实例时，也会执行onerror函数
                        This.notify.onerror = function(e) {
                            // console.log('notification encounters an error' + e);
                        };
                
                        This.notify.onclose = function() {
                            // console.log('notification is closed');
                            This.notify = null;
                        }
                    }
                }
                message.open({
                    // key: `call-duration-expire-${remaining}`,
                    key: `call-duration-expire`,
                    className: "call-duration-expire",
                    content: <span className="content">
                        <span className="description">
                            {t('call.message.notify.expire.tips')}
                            {
                                <Statistic.Countdown 
                                    className="countdown" 
                                    value={sipCallExpireTime * 1000} 
                                    format={`m${t('common.minute')}s${t('common.second')}`} 
                                    onFinish={()=>{
                                        let { sipCallExpireTime } = This.state;
                                        let remaining = sipCallExpireTime - moment().unix();
                                        if (remaining <= 0) {
                                            message.destroy();
                                            if (This.notify) {
                                                This.notify.close();
                                            }
                                            This.sipHangup();
                                        }
                                    }} 
                                />
                            }
                        </span>
                        <Space className="btns">
                            <Button type="text" className="btn" onClick={(e) => {
                                e.stopPropagation();
                                Log.info(This.sipSessionInfo(), `ui event: ignore onclick`)
                                if (This.notify) {
                                    This.notify.close();
                                }
                                message.destroy();
                            }}>{t('call.message.notify.expire.btn.ignore')}</Button>
                            <Button type="link" danger className="btn" onClick={(e) => {
                                e.stopPropagation();
                                Log.info(This.sipSessionInfo(), `ui event: exit onclick`)
                                message.destroy();
                                if (This.notify) {
                                    This.notify.close();
                                }
                                This.sipHangup();
                            }}>{t('call.message.notify.expire.btn.exit')}</Button>
                            <Button type="link" className="btn" onClick={(e) => {
                                e.stopPropagation();
                                Log.info(This.sipSessionInfo(), `ui event: renew onclick`)
                                let { sipCallExpireTime } = This.state;
                                This.setState({
                                    sipCallExpireTime: sipCallExpireTime + config.call.duration.max
                                })
                                if (This.notify) {
                                    This.notify.close();
                                }
                                message.destroy();
                                message.success(t('call.message.notify.expire.status.success', {time: getTimeDesc2(config.call.duration.max, t)}))
                            }}>{t('call.message.notify.expire.btn.renew')}</Button>
                        </Space>
                    </span>,
                    type: "warning",
                    duration: config.call.duration.expireTipDelta,
                    destroy: false,
                    onClose: () => {
                        if (This.notify) {
                            This.notify.close();
                        }
                    }
                });
            } else if (remaining <= 0) {
                // 已经超时
                This.sipHangup('call duration expire')
            }
        }, config.call.duration.expireCheckInterval * 1000)
    }

    fsm.stopTimer(FsmState.Call.PROCESS);

    // 循环检查ice状态，没到connected/completed，不能继续下去
    let cnt = 0;
    let interval = 100;
    let times = 600;
    const loop = () => {
        let { iceStatus } = This.state;
        if (fsm.test(FsmState.Call.PROCESS) && iceStatus !== "connected" && iceStatus !== "completed") {
            // Log.warn(This.sipSessionInfo(), `check ice status: ${iceStatus}(!= 'connected' | 'completed')`)
            This.checkIceTimer = setTimeout(() => {
                cnt += 1;
                if (cnt < times) {
                    loop();
                } else {
                    Log.error(This.sipSessionInfo(e.session), `ice status is not ready, ${interval * times / 1000}s timeout and hangup call`);
                    that.sipHangup('ice is not ready');
                }
            }, interval);
        } else if (fsm.test(FsmState.Call.PROCESS) && iceStatus === 'connected'){
            Log.info(This.sipSessionInfo(e.session), `ice status:${iceStatus} is ready`)
            connected();
        } else if (fsm.test(FsmState.Call.HANGUP)) {
            Log.info(This.sipSessionInfo(e.session), `the call is hangup, break the loop`);
            e.session.hangup();
            return;
        } else {
            Log.warn(This.sipSessionInfo(e.session), `ice status:${iceStatus} is not ready`)
            that.sipHangup('ice is not ready');
        }
    }

    Log.debug(This.sipSessionInfo(e.session), `start a loop to check ice status(= connected or completed) by ${times} times per ${interval/ 1000}s`)
    loop();
}

// 呼叫失败/挂断/取消
export function sipCallSessionOnTerminated(e, This, reason) {
    let {fsm, stream, dialHistory, sipCallSessionId, devicePreference, conferenceReportVisible, lastPageVisiable } = This.state;
    let { t } = This.props;
    let status = fsm.curState();
    reason = reason || This.state.reason

    Log.info(This.sipSessionInfo(e.session), `session call terminated. reason:`, reason);

    // 挂断之前先清理掉一些长期悬挂的提示（剩余时间不足等）
    message.destroy();

    switch(status) {
        case FsmState.Call.PROCESS:
        case FsmState.Call.STABLE:
        case FsmState.Call.HANGUP: {
            let content = {};
            if (status === FsmState.Call.PROCESS) {
                if (reason === 'cancel') {
                    message.info(t('call.message.notify.status.cancel'));
                    content = {
                        status: "cancel",
                        reason: t('call.reason.cancel'),
                    }
                }else {
                    if (This.callType === "screenshare") {
                        message.error(t('call.message.notify.status.screen.error'));
                    } else {
                        message.error(t('call.message.notify.status.error'));
                    }
                    content = {
                        status: "failed",
                        reason: t('call.reason.error'),
                    }
                }
            } else {
                if (This.callType === "screenshare") {
                    message.info(t('call.message.notify.status.screen.ended'));
                } else {
                    if (reason === 'join limit') {
                        content = {
                            status: "failed",
                            reason: t('call.reason.join_limit'),
                        }
                    } else if (reason === 'call destroy') {
                        message.info(t('call.message.notify.status.destroy'));
                    } else {
                        message.info(t('call.message.notify.status.ended'));
                    }
                }
                content.end = moment().unix()
            }
            // This.timerBarVisibleStop();
            dialHistory.mod(e?.session?.getId() || sipCallSessionId || 0, content);
            stream.deattchAll();
            stream.report.stop(Kind.ALL, Dir.ALL);
            if (!conferenceReportVisible && (conferenceReportVisible || lastPageVisiable !== 'report')) {
                stream.preview.start(devicePreference.get('camera', mediaDevice));
            }
            stream.stop(This.mainStream, 'display');

            fsm.transfer(FsmState.Call.INIT);
            fsm.attach(0);
            if (This.notify) {
                This.notify.close();
            }
            This.setState({
                ...This.initCallSession(),
                ...This.initModal(),
                ...This.pageVisible(),
            });
            This.focusInputTimer = setTimeout(() => {
                if (This.codeInputDOM) {
                    This.codeInputDOM.focus();
                }
            }, 500);
            if (This.hangupTimer) {
                Log.debug(This.sipSessionInfo(e.session), `stop timer(${This.hangupTimer})`)
                clearTimeout(This.hangupTimer);
                This.hangupTimer = null;
            }
            if (This.byeTimer) {
                Log.debug(This.sipSessionInfo(e.session), `stop timer(${This.byeTimer})`)
                clearTimeout(This.byeTimer);
                This.byeTimer = null;
            }
            if (This.expireTimer) {
                Log.debug(This.sipSessionInfo(e.session), `stop timer(${This.expireTimer})`)
                clearInterval(This.expireTimer);
                This.expireTimer = null;
            }
            break;
        }
        default:
            break;
    }
}

// 新增音视频流信息
function sipCallSessionAvAdd(e, This) {
    let { fsm, stream, optionLoudspeakerEnable } = This.state;
    let mediaStream = e.getParam();
    let pc = e.getUserData();
    if (pc) {
        stream.peerConnection = pc;
    }

    function colloct(report) {
        let { rtcInfoVisible } = This.state;
        if (rtcInfoVisible) {
            This.setState(report)
        }

        if (This.rtcDropCnt < config.rtc.dropFristAmount) {
            This.rtcDropCnt += 1;
            return;
        }

        if (!This.rtc) {
            This.rtc = [];
        }

        // 打样最近N个点
        if (This.rtc.length > config.rtc.dataAmount) {
            This.rtc.shift();
        }

        let newReport = JSON.parse(JSON.stringify(report));
        for (let kind in newReport) {
            let reports = newReport[kind] || {}
            for (let index in reports) {
                for (let key in reports[index]) {
                    if (key !== 'inbound-rtp' && key !== 'outbound-rtp') {
                        delete reports[index][key]
                    }
                }
            }
        }

        This.rtc.push({
            time: moment().unix() * 1000,
            report: newReport,
        })
    }

    if (fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
        if (e.type === "m_stream_video_remote_added") {
            // video receiver 有多路视频(9)
            stream.report.add(mediaStream, Kind.VIDEO, Dir.RECV);
            stream.report.start(colloct);
            if (!This.previewRemote) {
                This.previewRemoteCnt += 1;
                if (This.previewRemoteCnt === 2) {
                    Log.debug(This.sipSessionInfo(), "start remote stream. ", mediaStream)
                    stream.preview.remoteStart(mediaStream)
                    This.previewRemote = true;
                }
            }
            stream.reattachVideoRecv(mediaStream);
        } else if (e.type === "m_stream_audio_remote_added"){
            stream.report.add(mediaStream, Kind.AUDIO, Dir.RECV);
            stream.report.start(colloct);
            // audio receiver 有多路音频(3)
            stream.reattachAudioRecv(mediaStream);
            mediaStream.getTracks().forEach(t => t.enabled = optionLoudspeakerEnable);
        } else if (e.type === "m_stream_video_local_added")  {
            // video sender 有多路视频(2)
            stream.report.add(mediaStream, Kind.VIDEO, Dir.SEND);
            stream.report.start(colloct);
            if (!This.previewLocal) {
                Log.debug(This.sipSessionInfo(), "start local stream. ", mediaStream)
                stream.preview.localStart(mediaStream)
                This.previewLocal = true;
            }
        } else if (e.type === "m_stream_audio_local_added")  {
            stream.report.add(mediaStream, Kind.AUDIO, Dir.SEND);
            stream.report.start(colloct);
        } else {
            Log.error(This.sipSessionInfo(), "unsupported type. ", e.type)
            return;
        }
    }
}

// 移除音视频流信息
function sipCallSessionAvRemoteRemoved(e, This) {
    let {stream} = This.state;
    switch(e.type){
        case "m_stream_audio_remote_removed":
            stream.report.stop(Kind.AUDIO, Dir.RECV);
            break;
        case "m_stream_audio_local_removed":
            stream.report.stop(Kind.AUDIO, Dir.SEND);
            break;
        case "m_stream_video_remote_removed":
            stream.report.stop(Kind.VIDEO, Dir.RECV);
            break;
        case "m_stream_video_local_removed":
            stream.report.stop(Kind.VIDEO, Dir.SEND);
            break;
        default:
            break;
    }
}

// 协议栈事件通知
function sipStackOnEvent(e /* SIPml.Stack.Event */, This) {
    Log.info(This.sipSessionInfo(), "stack event: " + e.type);
    let { sipStack, fsm } = This.state;
    let { t } = This.props;
    let param = e.getParam() || {};
    let description = e.getDescription() || {};
    switch (e.type) {
        // 协议栈启动中
        case "starting": {
            This.setState({
                sipStackStatus: 'starting',
            })
            break;
        }
        // 协议栈启动完成
        case "restarted":
        case "started": {
            let { sipRegSession } = This.state;
            if (!sipRegSession) {
                // catch exception for IE (DOM not ready)
                try {
                    // LogIn (REGISTER) as soon as the stack finish starting
                    sipRegSession = sipStack.newSession("register", {
                        expires: 300,
                        ...configSessionListener(This),
                    });
                    Log.info(This.sipSessionInfo(sipRegSession), `new register session ${sipRegSession.getId()}`)

                    This.registerTimer = setTimeout(() => sipRegSession.register(), 1000);
                    This.setState({
                        sipRegSession: sipRegSession,
                    })
                    fsm.transfer(FsmState.Login.PROCESS)

                } catch (e) {
                    Log.error(This.sipSessionInfo(sipRegSession), "catch: " + e)
                }
            } else {
                sipRegSession.register();
            }

            This.setState({
                sipStackStatus: 'started',
                sipStackRestartCnt: 0,
            })
            break;
        }
        case "failed_to_start": {
            message.error(t('login.message.status.error'));
            This.exit(false, true);
            break;
        }
        case "restarting": {
            Log.warn(This.sipSessionInfo(), "stack restarting. reason: " + e.getPhrase());
            This.setState({
                sipStackStatus: 'starting',
                sipStackRestartCnt: This.state.sipStackRestartCnt + 1
            })
            break;
        }
        case "stopping": 
        case "failed_to_stop":
        case "stopped": {
            if (!fsm.test(FsmState.Login.INIT)) {
                if (!fsm.test(FsmState.Login.OUT)) {
                    message.error(t('login.message.status.disconnected'));
                }
                This.exit(false, true);
            }
            break;
        }
        
        // 收到呼叫
        case "i_new_call": {
            let that = This;
            let { dialHistory, sipCallSession, sipConfig, fsm } = This.state;
            let newSipCallSession = e.newSession;
            let sessionId = newSipCallSession.getId();
            let confCode = newSipCallSession.getHeader('X-Conference-Code');
            let confSipNum = newSipCallSession.getRemoteFriendlyName();
            
            Log.info(This.sipSessionInfo(newSipCallSession), `new conference ${confCode}(${confSipNum}) incoming`)

            // 允许SIP直呼
            if (config.call.allowDirectCall && !This.manual) {
                This.setState({
                    number: confSipNum,
                    code: confCode,
                    theme: "临时会议",
                    ...This.pageInvisible(),
                })
                if (fsm.test(FsmState.Call.INIT)) {
                    fsm.transfer(FsmState.Call.PROCESS);
                }
            }

            const call = () => {
                if (sipCallSession) {
                    Log.warn(This.sipSessionInfo(newSipCallSession), `there have another call session(${sipCallSession.getClassfiy()} ${sipCallSession.getId()}), reject it`);
                    e.newSession.reject();
                    return;
                } else {
                    let config = Object.assign(sipConfig, {
                        sip_headers: [],
                        call_type: This.callType,
                        ...configSessionListener(This),
                    })
                    let {number, code, theme, creator} = This.state;
                    let content = {
                        code: code, 
                        number: number,
                        creator: creator,
                        type: This.callType,
                        theme: theme,
                        start: moment().unix(),
                        end: undefined,
                        status: "process",
                    }
                    fsm.attach(sessionId);
                    dialHistory.add(sessionId, content)
                    Log.info(This.sipSessionInfo(newSipCallSession), `accept the call`)
                    newSipCallSession.accept(config);
                    This.setState({
                        sipCallSession: newSipCallSession,
                        sipCallSessionId: sessionId,
                        sipCallStartTime: moment().unix(),
                    })
                }
            }

            let cnt = 0;
            let interval = 100;
            let times = 30;
            const loop = () => {
                if (!fsm.test(FsmState.Call.PROCESS)) {
                    This.startCallTimer = setTimeout(() => {
                        cnt += 1;
                        if (cnt < times) {
                            loop();
                        } else {
                            Log.info(This.sipSessionInfo(newSipCallSession), `timeout, reject the conference ${confCode}`)
                            e.newSession.reject();
                            let { number, code, theme } = that.state; 
                            let content = {
                                code: code, 
                                number: number,
                                type: that.callType,
                                theme: theme,
                                start: moment().unix(),
                                end: moment().unix(),
                                status: "failed",
                            }
                            dialHistory.add(e.newSession.getId(), content)
                        }
                    }, interval)
                } else {
                    fsm.stopTimer(FsmState.Call.PROCESS);
                    call();
                }
            }

            Log.debug(This.sipSessionInfo(newSipCallSession), `start a loop to check fsm status(= ${FsmState.Call.PROCESS}) ${times}times per ${interval}ms`)
            loop();
            
            break;
        }

        // 权限已请求
        case "m_permission_requested": {
            let mediaType = e.getUserData() || {};
            Log.info(This.sipSessionInfo(), "permission requested: " + mediaType.s_name)
            break;
        }
        
        // 权限已请求成功
        case "m_permission_accepted": {
            let mediaType = e.getUserData() || {};
            Log.info(This.sipSessionInfo(), "permission accepted: " + mediaType.s_name)
            break;
        }

        // 权限请求失败
        case "m_permission_refused": {
            let { stream, dialHistory, sipCallSession, sipCallSessionId } = This.state;
            let mediaType = e.getUserData() || {};
            Log.warn(This.sipSessionInfo(), "permission refused: " + mediaType.s_name)

            function hangup() {
                if (fsm.test(FsmState.Call.PROCESS)) {
                    fsm.stopTimer(FsmState.Call.PROCESS)
                    This.sipHangup('no permission');
                    stream.stop(This.mainStream, 'display');
                    fsm.transfer(FsmState.Call.INIT);
                    dialHistory.mod(sipCallSession?.getId() || sipCallSessionId, {
                        status: "failed",
                        reason: t('call.reason.nopermission'),
                    });
                    This.setState({
                        ...This.sipInitCallSession(),
                        ...This.pageVisible(),
                    });
                    This.focusInputTimer = setTimeout(() => {
                        if (This.codeInputDOM) {
                            This.codeInputDOM.focus();
                        }
                    }, 500);
                }
            }

            if (This.callType === "screenshare") {
                if (window.tmedia_cmp(mediaType, window.tmedia_type_e.SCREEN_SHARE)) {
                    message.error(t('permission.message.status.sceen_share_refused'));
                    hangup();
                }
            } else {
                message.error(t('permission.message.status.refused'));
                This.setState({
                    permission: false,
                })
                hangup();
            }
            
            break;
        }

        // ICE 状态通知
        case "m_ice_status": {
            Log.info(This.sipSessionInfo(), `ice status: ${param} ${printObject(description)}`);
            This.setState({
                iceStatus: param,
                iceDescription: description,
            })
            break;
        }

        default: 
            break;
    }
}

// 会话事件通知
function sipSessionOnEvent(e /* SIPml.Session.Event */, This) {
    let { t } = This.props;
    if (e.type !== "i_info") {
        Log.info(This.sipSessionInfo(), `session event: ${e.type}`);
    }
    let { sipRegSession, sipCallSession } = This.state;
    switch (e.type) {
        case "connecting": {
            break;
        }
        case "connected": {
            if (e.session === sipRegSession) {
                // 当前会话是注册
                sipRegSessionOnConnected(e, This)
            } else if (e.session === sipCallSession) {
                // 当前会话是呼叫
                sipCallSessionOnConnected(e, This)
            } else {
                Log.warn(This.sipSessionInfo(), "I can't handle on the event")
            }
            break;
        }
        case "terminating":
        case "terminated":{
            if (e.session === sipRegSession) {
                // 当前会话是注册
                sipRegSessionOnTerminated(e, This);
            } else if (e.session === sipCallSession) {
                // 当前会话是呼叫
                sipCallSessionOnTerminated(e, This);
            } else {
                Log.warn(This.sipSessionInfo(), "I can't handle on the event")
            }
            break;
        }
        
        case "m_stream_audio_local_added": 
        case "m_stream_video_local_added":
        case "m_stream_audio_remote_added": 
        case "m_stream_video_remote_added": {
            if (e.session === sipCallSession) {
                // 当前会话是呼叫
                sipCallSessionAvAdd(e, This);
            } else {
                Log.warn(This.sipSessionInfo(), "I can't handle on the event")
            }
            break;
        }
        case "m_stream_audio_local_removed":
        case "m_stream_video_local_removed":
        case "m_stream_audio_remote_removed":
        case "m_stream_video_remote_removed": {
            if (e.session === sipCallSession) { 
                // 当前会话是呼叫
                sipCallSessionAvRemoteRemoved(e, This)
            } else {
                Log.warn(This.sipSessionInfo(), "I can't handle on the event")
            }
            break;
        }
        case "m_track_local_requested": {
            Log.info(This.sipSessionInfo(), "permission requested: " + e.getParam().s_name);
            break;
        }
        case "m_track_local_accepted": {
            let { number, stream, screenShare } = This.state;
            let mediaStream = e.getDescription() || undefined;
            mediaStream.type = e.getParam();
            stream.report.replace(mediaStream, Kind.VIDEO, Dir.SEND, 0);
            switch (This.callType) {
                case "screenshare":
                    break;
                default:
                    Log.info(This.sipSessionInfo(), "track local accepted: " + e.getParam().s_name);
                    if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE) && screenShare === ScreenShareState.STOP) {
                        Log.info(This.sipSessionInfo(), `screen share started.`);
                        apiConferenceScreenShareStart(Object.assign({sessionInfo: This.sipSessionInfo}, This.props), number, false, (dispatch, rsp, req) => {
                            Log.debug(This.sipSessionInfo(), `screen share start notify success`)
                            message.success(t('screen.message.notify.status.success'));
                            This.setState({
                                screenShare: ScreenShareState.START,
                            })
                        }, (dispatch, rsp, req) => {
                            Log.warn(This.sipSessionInfo(), `screen share start notify failed. detail: ${printObject(rsp)}`)
                            if (rsp.status === 403) {
                                Modal.confirm({
                                    className: "screen-force",
                                    title: t('screen.force.title'),
                                    content: t('screen.force.content'),
                                    okText: t('screen.force.btn.ok'),
                                    okType: 'danger',
                                    okButtonProps: {danger: true},
                                    cancelText: t('screen.force.btn.cancel'),
                                    onOk: () => {
                                        apiConferenceScreenShareStart(Object.assign({sessionInfo: This.sipSessionInfo}, This.props), number, true, (dispatch, rsp, req) => {
                                            Log.debug(This.sipSessionInfo(), `screen share start notify success`)
                                            message.success(t('screen.message.notify.status.success'));
                                            This.setState({
                                                screenShare: ScreenShareState.START,
                                            })
                                        }, (dispatch, rsp, req) => {
                                            message.success(t('screen.message.notify.status.error'));
                                        })
                                    },
                                    onCancel: () => {
                                        message.info(t('screen.message.notify.status.not_allowed'));
                                        sipCallSession.switch_video('video');
                                    }
                                })
                            } else {
                                message.success(t('screen.message.notify.status.error'));
                            }
                        })
                    }
                    break;
            }
            break;
        }
        case "m_track_local_refused": {
            let { number, screenShare } = This.state;
            Log.info(This.sipSessionInfo(), "track local refused: " + e.getParam().s_name);
            switch (This.callType) {
                case "screenshare":
                    if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE)) {
                        Log.info(This.sipSessionInfo(), `screen share refused.`);
                        This.sipHangup('screen share refused');
                    }
                    break;
            
                default:
                    if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE) && screenShare === ScreenShareState.STOP) {
                        // 屏幕共享被用户拒绝
                        Log.warn(This.sipSessionInfo(), `screen share refused. reason: ${e.getDescription()}`);
                        if (e.getDescription().indexOf("NotAllowedError") !== -1) {
                            message.warn(t('screen.message.notify.status.not_allowed'));
                            sipCallSession.switch_video('video');
                            apiConferenceScreenShareStop(Object.assign({sessionInfo: This.sipSessionInfo}, This.props), number, (dispatch, rsp, req) => {
                                Log.debug(This.sipSessionInfo(), `screen share stop notify success`)
                            }, (dispatch, rsp, req) => {
                                Log.warn(This.sipSessionInfo(), `screen share stop notify failed. detail: ${printObject(rsp)}`)
                            })
                            This.setState({
                                screenShare: ScreenShareState.STOP,
                            })
                        } else {
                            message.error(t('screen.message.notify.status.error'));
                        }
                    }
                    break;
            }
            break;
        }
        case "m_track_local_ended": {
            let { number, screenShare } = This.state;
            Log.info(This.sipSessionInfo(), "track local ended: " + e.getParam().s_name);
            switch (This.callType) {
                case "screenshare":
                    if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE)) {
                        Log.info(This.sipSessionInfo(), `screen share stopped.`);
                        This.sipHangup('screen share stopped');
                    }
                    break;
            
                default:
                    if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE) && screenShare === ScreenShareState.START) {
                        // 屏幕共享结束
                        Log.info(This.sipSessionInfo(), `screen share stopped.`);
                        sipCallSession.switch_video('video');
                        message.info(t('screen.message.notify.status.ended'));
                        apiConferenceScreenShareStop(Object.assign({sessionInfo: This.sipSessionInfo}, This.props), number, (dispatch, rsp, req) => {
                            Log.debug(This.sipSessionInfo(), `screen share stop notify success`)
                        }, (dispatch, rsp, req) => {
                            Log.warn(This.sipSessionInfo(), `screen share stop notify failed. detail: ${printObject(rsp)}`)
                        })
                        This.setState({
                            screenShare: ScreenShareState.STOP,
                        })
                    }
                    break;
            }
            
            break;
        }
        
        // 收到响应消息
        case "i_ao_request":{
            if (e.session === sipCallSession) {
                switch(e.getSipResponseCode()) {
                    case 180:
                    case 183:
                        break;
                    default:
                        break;
                }
            }
            break;
        }

        default:
            break;
    }
}

// 配置会话事件通知
function configSessionListener(This) {
    return {
        events_listener: { events: "*", listener: (e) => sipSessionOnEvent(e, This) },
    }
}

// 配置协议栈事件通知
function configStackListener(This) {
    return {
        events_listener: { events: "*", listener: (e) => sipStackOnEvent(e, This) },
    }
}

export function sipSessionInfo(session = null) {
    let sess = session || this.state.sipCallSession;
    let fsm = this.state.fsm;
    let text = "";
    if (sess) {
        text += `${sess.getClassfiy()}#${sess.getId()}`
    } else {
        text += `None#0`
    }
    text += `@${fsm?.curState() || "none"}`
    return text;
}

// 初始化 SIPml
export function sipInit() {
    let { t } = this.props;
    Log.info(this.sipSessionInfo(), `sip init`);
    // 检查 WebRTC
    if (!window.SIPml.isWebRtcSupported()) {
        message.error(t('check.message.webrtc.nosupported'))
        Log.error(`sip check webrtc supported: no`);
        return
    }
    Log.debug(this.sipSessionInfo(), `sip check webrtc supported: yes`);

    // 检查 WebSocket
    if (!window.SIPml.isWebSocketSupported()) {
        message.error(t('check.message.websocket.nosupported'))
        Log.error(`sip check websocket supported: no`);
        return;
    }
    Log.debug(this.sipSessionInfo(), `sip check websocket supported: yes`);

    let sipConfig = {
        outbound_proxy_url: null,
        enable_rtcweb_breaker: true,
        enable_early_ims: false, // Must be true unless you"re using a real IMS network
        enable_media_stream_cache: false,
        ...configStackListener(this),
    };

    this.setState({
        sipConfig: sipConfig,
    })
}

// 初始化协议栈相关变量
export function sipInitStack() {
    return {
        sipStack: null,
        sipStackStatus: 'disconnected',
        sipStackRestartCnt: 0,
    }
}

// 初始化呼叫相关变量
export function sipInitCallSession() {
    this.rtcDropCnt = 0;
    return {
        sipCallSession: null,
        sipCallSessionId: 0,
        sipCallStartTime: 0,
    }
}

// 初始化注册相关变量
export function sipInitRegisterSession() {
    return {
        sipRegSession: null,
    }
}

// 更新配置
export function sipConfig(config) {
    let { sipStack, sipConfig, } = this.state;

    Log.info(this.sipSessionInfo(), `sip config update. config: ${printObject(config)}`);

    let newConfig = Object.assign(sipConfig, {
        ...config,
        ...configStackListener(this),
    });
    if (sipStack) {
        // 协议栈底层对头域的处理是增加！重新配置协议栈参数不需要增加头域
        newConfig.sip_headers = [];
        sipStack.setConfiguration(newConfig);
        this.setState({
            sipConfig: newConfig,
        })
    }
}

// 发起注册
export function sipRegister(sipRealm, sipUsername, sipPassword, websocketUrl) {
    let { sipConfig, devicePreference } = this.state;
    let { t } = this.props;
    try {
        Log.info(this.sipSessionInfo(), `sip register`);
        if (version.isProduction()) {
            if (!window.location.protocol.match("https:")) {
                message.error(t('check.message.http.ssl.nosupported'))
                Log.error(this.sipSessionInfo(), `sip check https supported: no`);
                return;
            }
        }
        Log.debug(this.sipSessionInfo(), `sip check https supported: yes`);

        if (!sipUsername || sipUsername.length === 0 || !sipPassword || sipPassword.length === 0) {
            message.error(t('sip.message.status.account.empty'))
            return;
        }
        Log.debug(this.sipSessionInfo(), `sip check username and password result: ok`);

        let config = Object.assign(sipConfig, {
            websocket_proxy_url: websocketUrl,
            ice_servers: [{url: "stun:1.2.3.4:3478", addr: "1.2.3.4", internal: true, online: "123456"}],
            realm: sipRealm,
            impi: sipUsername,
            impu: `sip:${sipUsername}@${sipRealm}`,
            password: sipPassword,
            display_name: sipUsername,
            sip_headers: [
                { name: "User-Agent", value: `VMeeting-client-${version.software}` },
                { name: "Organization", value: "www.ludiqiao.com" }
            ],
            constraint: { 
                audio: {
                    bandwidth: 256,
                },
                video: {
                    bandwidth: 2048,
                    bitrate: { start: 1500, min: 800, max: 3000 },
                    mainstream: {
                        width: 1280,
                        height: 720,
                        frameRate: 20,
                    },
                    secondarystream: {
                        width: 320,
                        height: 180,
                        frameRate: 20,
                    }
                }
            },
            video_input_device_id: devicePreference.get('camera', mediaDevice),
            audio_input_device_id: devicePreference.get('microphone', mediaDevice),
            audio_output_device_id: devicePreference.get('loudspeaker', mediaDevice),
            ...configStackListener(this),
        })

        let sipStack = new window.SIPml.Stack(config);

        Log.debug(this.sipSessionInfo(), `sip stack is starting...`);
        if (sipStack.start() !== 0) {
            Log.error(this.sipSessionInfo(), `sip stack start failed.`)
            message.error(t('sip.message.status.fail_to_start'))
        } else {
            this.setState({
                sipConfig: config,
                sipStack: sipStack,
            })
            return;
        }
    } catch (e) {
        Log.error(this.sipSessionInfo(), "catch: " + e)
    }
}

// 发起注销
export function sipUnregister() {
    let {fsm, sipStack, sipCallSession, sipRegSession} = this.state;
    Log.info(this.sipSessionInfo(), `sip unregister`);
    this.setState({
        loginStatus: 'disconnected',
    })

    if (sipCallSession) {
        sipCallSession.hangup();
    }
    if (sipRegSession) {
        sipRegSession.unregister();
    }
    this.unregisterTimer = setTimeout(() => {
        if (sipStack) {
            Log.info(this.sipSessionInfo(), `sip stack is stopping...`);
            sipStack.stop();
        }
    }, 1000);

    if (!fsm.test(FsmState.Login.INIT, FsmState.Login.OUT)) {
        fsm.transfer(FsmState.Login.OUT);
    }
}

// 发起呼叫
export function sipCall(type, number, code) {
    let that = this;
    Log.info(this.sipSessionInfo(), `sip call ${type} -> "${code}"${number}`)
    let { fsm, stream, dialHistory, sipConfig, sipStack, sipCallSession } = this.state;
    let { t } = this.props;
    if (!fsm.test(FsmState.Call.INIT)) {
        Log.error(this.sipSessionInfo(), `status is not right, ${fsm.curState()} != ${FsmState.Call.INIT}`)
        return;
    }

    // stream.preview.stop();
    if (sipStack && !sipCallSession && number?.length >= 0) {
        let config = Object.assign(sipConfig, {
            sip_headers: [],
            ...configSessionListener(this),
        })

        sipCallSession = sipStack.newSession(type, config);
        if (sipCallSession.call(number) !== 0) {
            sipCallSession = null;
            return;
        }

        Log.info(this.sipSessionInfo(), `new call session ${sipCallSession.getId()}`)
        let now = moment().unix();
        this.setState({
            sipCallSession: sipCallSession,
            sipCallStartTime: now,
        })

        if (this.callType === "screenshare") {
            message.loading(t('call.message.notify.status.screen.doing'))
        } else {
            message.loading(t('call.message.notify.status.doing'))
        }

        fsm.transfer(FsmState.Call.PROCESS)
        fsm.startTimer(FsmState.Call.PROCESS, () => {
            that.sipHangup('timeout');
        });
        let content = {
            code: code, 
            number: number,
            type: type,
            theme: "",
            start: now,
            end: undefined,
            status: "process",
        }
        dialHistory.add(sipCallSession.getId(), content)
    }
}

// 发起挂断
export function sipHangup(reason = 'normal') {
    let { fsm, sipCallSession } = this.state;
    let { t } = this.props; 
    let that = this;

    if (fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
        Log.info(this.sipSessionInfo(), `sip hangup call. reason: ${reason}`)
        this.setState({
            reason: reason,
        }, () => {
            fsm.stopTimer(FsmState.Call.PROCESS);
            fsm.transfer(FsmState.Call.HANGUP);
            if (sipCallSession) {
                sipCallSession.hangup();
            }

            let timeout = 3;
            this.byeTimer = setTimeout(() => {
                Log.warn(that.sipSessionInfo(), `the timer(${that.byeTimer}) timeout, somethings gose wrong`)
                sipCallSessionOnTerminated({session: that.state.sipCallSession}, that, reason);
                that.byeTimer = null;
            }, timeout * 1000);
            Log.debug(this.sipSessionInfo(), `start timer(${this.byeTimer} after ${timeout}s) just in case of some problems in hangup progress`)
        })
    }
}

// 静音
export function sipMute(type, mute) {
    let { sipCallSession } = this.state;
    if (sipCallSession) {
        sipCallSession.mute(type, mute);
    }
}

// 切换视频和共享屏幕
export function sipSwitchVideo(type) {
    let { sipCallSession } = this.state;
    if (sipCallSession) {
        sipCallSession.switch_video(type);
    }
}