import React, { Component } from "react";

// 在线引入
// import { createFromIconfontCN } from '@ant-design/icons';
// export const IconFont =  createFromIconfontCN({
//     scriptUrl: '//at.alicdn.com/t/font_1819908_fb6xqygmkc.js', // 在 iconfont.cn 上生成
// })


// 本地引入
export class IconFont extends Component {

    render() {
        let { className, type, onClick, onMouseEnter, onMouseLeave, onFocus} = this.props;
        return <span role="img" tabIndex="-1" className={"anticon " + (className || "")} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onFocus={onFocus}>
            <svg className="icon-svg" aria-hidden="true">
                <use xlinkHref={`#${type}`}/>
            </svg>
        </span>
    }
}