/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from "react-redux"
import moment from "moment";
import { Button, Result, Tabs, Progress, Row, Col, Select, Descriptions, Statistic, Modal, Input, Form, Switch, InputNumber, Typography } from "antd";
import { ToolFilled, FilterFilled, AudioFilled, CaretRightOutlined, PauseOutlined, LockOutlined, BugOutlined, BugFilled, AimOutlined, AppstoreFilled } from "@ant-design/icons";
import { withTranslation } from 'react-i18next'
import { State as FsmState } from "../fsm/fsm";
import { device as mediaDevice } from '../media/device';
import { adapter } from '../adapter';
import { version, config } from '../../util/version';
import { Log } from "../../util/log"
import { message } from "../../util/message";
import { uploadInfo, getTimeDesc2 } from '../../util/logic';
import { Definition, AspectRatio } from '../../api/structure';
import { notification } from '../media/notification';
import { IconFont } from '../../util/iconFont';

import "./setting.less";

@withTranslation('translation', {withRef: true})
class Setting extends Component {
    constructor(props){
        super(props);

        this.deviceOnChange = this.deviceOnChange.bind(this);

        this.tabOnChange = this.tabOnChange.bind(this);

        this.cameraOnChange = this.cameraOnChange.bind(this);
        this.microphoneOnChange = this.microphoneOnChange.bind(this);
        this.microphoneTestOnClick = this.microphoneTestOnClick.bind(this);
        this.loudspeakerOnChange = this.loudspeakerOnChange.bind(this);
        this.loudspeakerTestOnClick = this.loudspeakerTestOnClick.bind(this);
        this.debugOnClick = this.debugOnClick.bind(this);

        this.state = {
            debug: false,

            cameraDeviceName: "",
            cameraPreviewError: "",
            cameraTestStartTime: 0,
            cameraTestGetMediaTime: 0,
            cameraDefinitionId: Definition.HD,
            cameraDefinitionDefaultId: Definition.HD,

            microphoneTestStart: false,
            microphoneVolume: 0,
            microphoneDeviceName: "",
            microphoneTestStartTime: 0,
            microphoneTestGetMediaTime: 0,
            
            loudspeakerTestStart: false,
            loudspeakerDeviceName: "",
            loudspeakerTestStartTime: 0,
            loudspeakerTestGetMediaTime: 0,
        };
    }

    deviceOnChange(event) {
        let { cameraDefinitionId } = this.state;
        let { cameraDeviceId, cameras, 
             microphoneDeviceId, microphones,
             loudspeakerDeviceId, loudspeakers
        } = this.props;
      
        this.cameraOnChange(cameraDeviceId, cameraDefinitionId, cameras);
        this.microphoneOnChange(microphoneDeviceId, microphones);
        this.loudspeakerOnChange(loudspeakerDeviceId, loudspeakers);
    }

    tabOnChange(key) {
        let { sessionInfo, stream, loudspeakerDeviceId } = this.props;
        Log.info(sessionInfo(), `ui event: setting page tab ${key} onclick`)

        switch(key) {
            case 'camera': {
                stream.attach('dom_v_setting', this.cameraStream)
                break;
            }
            case 'microphone': {
                break;
            }
            case 'loudspeaker': {
                stream.setSinkId('dom_a_setting', loudspeakerDeviceId);
                break;
            }
            default: {
                break;
            }
        }
    }

    cameraOnChange(deviceId, cameraDefinitionId = undefined, cameras = []) {
        let { t, stream, onChange, sessionInfo, } = this.props;

        if (cameras.length === 0) {
            cameras = this.props.cameras;
        }

        if (cameraDefinitionId === undefined) {
            cameraDefinitionId = this.state.cameraDefinitionId;
        }

        let findRet = cameras.find((device) => {
            // console.log(device)
            return device.deviceId === deviceId
        });

        // 没有找到，则默认选第一个
        if (!findRet) {
            findRet = cameras[0];
        }

        if (findRet) {
            stream.stop(this.cameraStream, 'camera');
            this.cameraStream = null;
            this.setState({
                cameraTestStartTime: new Date().getTime(),
                cameraTestGetMediaTime: 0,
            })
            
            adapter.getUserMedia({
                video: {
                    ...Definition.get(cameraDefinitionId),
                    deviceId: { exact: findRet.deviceId }
                },
            }, (mediaStream) => {
                // 成功的回调
                Log.debug(sessionInfo(), `open camera media stream(${mediaStream.id}) success. `)
                this.cameraStream = mediaStream;
                stream.attach('dom_v_setting', mediaStream)
                this.setState({
                    cameraPreviewError: undefined,
                    cameraTestGetMediaTime: new Date().getTime(),
                })
            }, (error) => {
                // 失败的回调
                Log.error(sessionInfo(), "open camera media error: ", error);
                this.setState({
                    cameraPreviewError: t('setting.camera.media.error'),
                    cameraTestGetMediaTime: new Date().getTime(),
                })
            })
        }

        Log.info(sessionInfo(), "camera onchange label:", findRet ? findRet.label : undefined)

        this.setState({
            cameraDeviceName: findRet?.label,
            cameraDefinitionId: cameraDefinitionId,
        })

        if (onChange) {
            onChange('camera', findRet?.deviceId);
        }
    }

    microphoneOnChange(deviceId, microphones = []) {
        if (microphones.length === 0) {
            microphones = this.props.microphones;
        }
        let { onChange } = this.props;
        let findRet = microphones.find((device) => {
            // console.log(device)
            return device.deviceId === deviceId
        });

        // 没有找到，则默认选第一个
        if (!findRet) {
            findRet = microphones[0];
        }

        this.setState({
            microphoneDeviceName: findRet?.label,
        })

        if (onChange) {
            onChange('microphone', findRet?.deviceId);
        }
    }

    microphoneTestOnClick() {
        let { microphoneTestStart } = this.state;
        let { t, stream, microphoneDeviceId, sessionInfo, } = this.props;
        let that = this;
        
        stream.stop(this.microphoneStream, 'microphone');
        this.microphoneStream = null;
        if (this.audioCtx) {
            this.audioCtx.close();
            this.audioCtx = null;
        }

        this.setState({
            microphoneTestStartTime: new Date().getTime(),
            microphoneTestGetMediaTime: 0,
        })
        if (!microphoneTestStart) {
            adapter.getUserMedia({
                audio: {
                    deviceId: { exact: microphoneDeviceId }
                },
                video: false,
                data: false,
            }, (mediaStream) => {
                Log.debug(sessionInfo(), `microphone stream: ${mediaStream.id} opened`);
                that.microphoneStream = mediaStream;
    
                if (!that.audioCtx) {
                    let audioContext = window.AudioContext || window.webkitAudioContext;
                    that.audioCtx = new audioContext();
                }
                let liveSource = that.audioCtx.createMediaStreamSource(mediaStream);
                let levelChecker = that.audioCtx.createScriptProcessor(4096,1,1); 
                liveSource.connect(levelChecker); 
                levelChecker.connect(that.audioCtx.destination);
                levelChecker.onaudioprocess = function(e) { 
                    let buffer0 = e.inputBuffer.getChannelData(0); 
                    let volume = Math.max.apply(null, buffer0) * 100; 
    
                    // console.log("volume：" + Math.round(volume));
                    that.setState({
                        microphoneVolume: Math.round(volume),
                    })
                };
                
                that.setState({
                    microphonePreviewError: undefined,
                    microphoneTestGetMediaTime: new Date().getTime(),
                })

            }, (error) => {
                that.setState({
                    microphonePreviewError: t('setting.microphone.media.error'),
                    microphoneTestGetMediaTime: new Date().getTime(),
                })
                Log.error(sessionInfo(), "get microphone media error: ", error);
            })
        } 
        this.setState({
            microphoneTestStart: !microphoneTestStart,
            microphoneVolume: 0,
        })
    }

    loudspeakerOnChange(deviceId, loudspeakers = []) {
        if (loudspeakers.length === 0) {
            loudspeakers = this.props.loudspeakers;
        }
        let { stream, onChange } = this.props;
        let findRet = loudspeakers.find((device) => {
            // console.log(device)
            return device.deviceId === deviceId
        });

        // 没有找到，则默认选第一个
        if (!findRet) {
            findRet = loudspeakers[0];
        }

        if (findRet) {
            stream.setSinkId('dom_a_setting', findRet.deviceId)
        }

        this.setState({
            loudspeakerDeviceName: findRet?.label,
        })

        if (onChange) {
            onChange('loudspeaker', findRet?.deviceId);
        }
    }

    loudspeakerTestOnClick(e) {
        let { loudspeakerTestStart } = this.state;
        let element = document.getElementById('dom_a_setting');
        if (element) {
            if (!loudspeakerTestStart) {
                element.play();
            } else {
                element.pause();
            }
            this.setState({
                loudspeakerTestStart: !loudspeakerTestStart,
            })
        }
    }

    debugOnClick(e) {
        let that = this;
        let { debug, password } = this.state;
        let { t } = this.props;
        if (debug) {
            this.setState({
                debug: false,
                password: undefined,
            })
        } else {
            function onOk(close) {
                if (that.state.password === `vmeeting${moment().format("YYYYMMDD")}`) {
                    that.setState({debug: true})
                    if (typeof close === "function") {
                        close();
                    } else {
                        Modal.destroyAll();
                    }
                }
            }
            function onCancel(close) {
                that.setState({debug: false, password: undefined})

                if (typeof close === "function") {
                    close();
                } else {
                    Modal.destroyAll();
                }
            }
            Modal.confirm({
                title: t('setting.base.debug.title'), 
                content: (<div>
                    <Input.Password autoFocus={true} value={password} 
                        autoComplete="on" 
                        prefix={<LockOutlined />}
                        placeholder={t('setting.base.debug.password.placeholder')} 
                        onChange={(e) => {
                            that.setState({password: e.target.value})
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                onOk(); 
                            }
                        }} />
                </div>), 
                okText: t('setting.base.debug.btn.ok'),
                onOk: onOk,
                cancelText: t('setting.base.debug.btn.cancel'),
                onCancel: onCancel,
            })
        }
    }

    mkBase(style) {
        let { debug } = this.state;
        let { t, height, width, login, profile, identity, copyOnClick } = this.props;

        let expire = undefined;
        if (debug && login) {
            let thatTime = moment(login.expire, 'YYYY-MM-DD HH:mm:ss GMT').add('hours', 8)
            thatTime.toDate()
            expire = <span className="expire">
                <span className="deadline">{thatTime.format('YYYY-MM-DD HH:mm:ss')}</span>
                (<Statistic.Countdown className="remaining" title={t('setting.base.descriptions.profile.expire_remaining')} value={thatTime.toDate()}/>)
            </span>
        }

        let component = <div className="tabpane-content tabpane-content-base" style={style}>
            <Descriptions className="descriptions" title={t('setting.base.descriptions.profile.title')} column={1}>
                <Descriptions.Item label={t('setting.base.descriptions.profile.name')}>{profile?.name}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.profile.avatar')}>
                    <img className="avatar" alt="" src={profile?.avatarUrl} />
                </Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.profile.sipNum')}>
                    {profile?.phone}
                    <span className="operation"><Button type="link" 
                        onClick={(e) => {
                            if (copyOnClick) {
                                copyOnClick(e, "default", profile?.phone || "")
                            }
                        }
                    }>{t('common.copy')}</Button></span>
                </Descriptions.Item>
                {debug && profile ? <Descriptions.Item label={t('setting.base.descriptions.profile.password')}>{profile.password}</Descriptions.Item> : undefined}
                {debug && profile ? <Descriptions.Item label={t('setting.base.descriptions.profile.sipDomain')}>{profile.sipDomain}</Descriptions.Item> : undefined}
                {debug && login ? <Descriptions.Item label={t('setting.base.descriptions.profile.token')}>{login.token}</Descriptions.Item> : undefined}
                {debug && login ? <Descriptions.Item label={t('setting.base.descriptions.profile.expire')}>{expire}</Descriptions.Item> : undefined}
            </Descriptions>

            {
                debug ? <Descriptions className="descriptions" title={t('setting.base.descriptions.server.title')} column={1}>
                    <Descriptions.Item label={t('setting.base.descriptions.server.media')}>{identity || "无"}</Descriptions.Item>
                    <Descriptions.Item label={t('setting.base.descriptions.server.websocket')}>{profile.wsUrl}</Descriptions.Item>
                    <Descriptions.Item label={t('setting.base.descriptions.server.sip')}>{profile.sipServer}</Descriptions.Item>
                </Descriptions> : undefined
            }

            <Descriptions className="descriptions" title={t('setting.base.descriptions.version.title')} column={1}>
                <Descriptions.Item label={t('setting.base.descriptions.version.environment')}>{version.environment}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.version.software')}>{version.software}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.version.sip')}>{version.sip}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.version.webrtc')}>{adapter.explorerInfo.type} {adapter.explorerInfo.version}</Descriptions.Item>
                {debug ? <Descriptions.Item label={t('setting.base.descriptions.version.window')}>{width} * {height}</Descriptions.Item> : undefined}
            </Descriptions>

            <Descriptions className="descriptions" title={t('setting.base.descriptions.feature.title')} column={1}>
                <Descriptions.Item className={adapter.isDeviceListSupported.toString()} label={t('setting.base.descriptions.feature.device_list')}>{t(`permission.device_list.${adapter.isDeviceListSupported}`)}</Descriptions.Item>
                <Descriptions.Item className={adapter.isScreenShareSupported.toString()} label={t('setting.base.descriptions.feature.screen_share')}>{t(`permission.screen_share.${adapter.isScreenShareSupported}`)}</Descriptions.Item>
                <Descriptions.Item className={notification.permission} label={t('setting.base.descriptions.feature.notification')}>{t(`permission.notification.${notification.permission}`)}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.feature.max_call_duration')}>{getTimeDesc2(config.call.duration.max, t)}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.feature.refresh_token_interval')}>{getTimeDesc2(config.refreshTokenInterval, t)}</Descriptions.Item>
            </Descriptions>

        </div>
        return component;
    }

    mkGlobal(style) {
        let { t } = this.props;

        let component = <div className="tabpane-content tabpane-content-base" style={style}>
            <Form className="descriptions">
                <Typography.Title level={5}>{t("setting.global.call.form.title")}</Typography.Title>
                <Form.Item
                    label={t("setting.global.call.form.item.allow_direct_call.label")}
                    help={t("setting.global.call.form.item.allow_direct_call.help")}
                >
                    <Switch
                        checked={config.call.allowDirectCall}
                        onChange={(checked) => {
                            config.call.allowDirectCall = checked
                            this.forceUpdate();
                        }
                    } />
                </Form.Item>
                <Form.Item
                    label={t("setting.global.call.form.item.max_dial_history.label")}
                    help={t("setting.global.call.form.item.max_dial_history.help")}
                >
                    <InputNumber
                        value={config.call.maxDialHistory}
                        onChange={(e) => {
                            config.call.maxDialHistory = e
                            this.forceUpdate();
                        }
                    } />
                    <span className="ant-form-text">{t("common.ge")}</span>
                </Form.Item>
                <Form.Item
                    label={t("setting.global.call.form.item.max_duration.label")}
                    help={t("setting.global.call.form.item.max_duration.help")}
                >
                    <InputNumber
                        min={1}
                        max={8}
                        value={config.call.duration.max / 3600}
                        onChange={(e) => {
                            config.call.duration.max = e * 3600
                            this.forceUpdate();
                        }
                    } />
                    <span className="ant-form-text">{t("common.hour")}</span>
                </Form.Item>
            </Form>
        </div>
        return component;
    }
    
    mkCamera(style) {
        let { cameraDeviceName, cameraDefinitionId, cameraDefinitionDefaultId, cameraPreviewError, cameraTestStartTime, cameraTestGetMediaTime } = this.state;
        let { t, cameraDeviceId, cameras } = this.props;

        let options = []
        for (let i in Definition) {
            let value = Definition[i];
            if (typeof value === "number") {
                options.push({description: Definition.toString(value), value: value});
            }
        }

        let cameraDetailComponent = undefined;
        if (this.cameraStream) {
            let track = this.cameraStream.getTracks()[0];
            let settings = track?.getSettings() || {};
            cameraDetailComponent = <Descriptions className="descriptions" column={1}>
                <Descriptions.Item label={t('setting.camera.test.aspect_ratio')}>{AspectRatio.toString(settings.aspectRatio)}</Descriptions.Item>
                <Descriptions.Item label={t('setting.camera.test.definition')}>{settings.width} × {settings.height}</Descriptions.Item>
                <Descriptions.Item label={t('setting.camera.test.frame_rate')}>{parseInt(settings.frameRate)}</Descriptions.Item>
                {/* <Descriptions.Item label={t('setting.camera.test.brightness')}>{settings.brightness}</Descriptions.Item>
                <Descriptions.Item label={t('setting.camera.test.sharpness')}>{settings.sharpness}</Descriptions.Item>
                <Descriptions.Item label={t('setting.camera.test.saturation')}>{settings.saturation}</Descriptions.Item> */}
            </Descriptions>
        }

        let component = undefined;
        if (cameras.length > 0) {
            let delay = cameraTestGetMediaTime - cameraTestStartTime;
            let delayClassName = "";
            let extra = "";
            if (delay <= 0) {
                delay = t('setting.camera.test.nostart');
            } else {
                if (0 < delay && delay <= 2000) {
                    delayClassName = "good";
                    extra = t('setting.camera.test.result.good');
                } else if (2000 < delay && delay <= 5000) {
                    delayClassName = "just-so-so";
                    extra = t('setting.camera.test.result.just_so_so');
                } else {
                    delayClassName = "bad";
                    extra = t('setting.camera.test.result.bad');
                }
                delay += t('common.millisecond') + `(${extra})`;
            }
            component = <div className="tabpane-content tabpane-content-camera" style={style}>
                <Row justify="space-between" align="top" >
                    <Col span={13} className="video-col">
                        <span className="name">{cameraDeviceName}</span>
                        <div className="video-wrapper">
                            <video className="video" height="100%" width="100%" id="dom_v_setting" autoPlay muted 
                                // poster="./images/video-poster.png" 
                            /> 
                        </div>
                    </Col>
                    <Col span={1}/>
                    <Col span={10} className="camera-col">
                        <div className="camera-wrapper">
                            <div className="select-wrapper">
                                <div className="title">{t('setting.camera.list.title')}</div>
                                <Select className="select" value={cameraDeviceId} onChange={(value) => this.cameraOnChange(value, cameraDefinitionId)}>
                                    {cameras.map((device) => <Select.Option key={device.deviceId} value={device.deviceId}>{device.label}</Select.Option>)}
                                </Select>
                                <div className="tips">{t('setting.camera.list.tips')}</div>
                                
                                <div className="title">{t('setting.camera.resolution.title')}</div>
                                <Select className="select" value={cameraDefinitionId} onChange={(value) => this.cameraOnChange(cameraDeviceId, value)}>
                                    {options.map((opt) => <Select.Option key={opt.value} value={opt.value}>{opt.description + (cameraDefinitionDefaultId === opt.value ? `（${t('setting.camera.resolution.default')}）` : "")}</Select.Option>)}
                                </Select>
                                <div className="tips">{t('setting.camera.resolution.tips')}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="tips-wrapper">
                    <Descriptions className="descriptions" column={1}>
                        <Descriptions.Item className={delayClassName} label={t('setting.camera.test.delay')}>{delay}</Descriptions.Item>
                    </Descriptions>
                    {cameraDetailComponent}
                </div>
                <div>
                    <span className={"tip-error " + (cameraPreviewError ? "show" : "hidden")}>{cameraPreviewError}</span>
                </div>
            </div>
        } else {
            component = <Result
                className="not-find"
                status="error"
                title={t('setting.camera.empty.title')}
                subTitle={t('setting.camera.empty.sub_title')}
            />
        }

        return component;
    }

    mkMicrophone(style) {
        let { microphoneTestStart, microphoneVolume, microphoneDeviceName, microphonePreviewError, microphoneTestGetMediaTime, microphoneTestStartTime } = this.state;
        let { t, width, microphoneDeviceId, microphones } = this.props;
         
        let component = undefined;
        if (microphones.length > 0) {
            let delay = microphoneTestGetMediaTime - microphoneTestStartTime;
            let delayClassName = "";
            let extra = "";
            if (delay <= 0) {
                delay = t('setting.microphone.test.nostart');
            } else {
                if (0 < delay && delay <= 2000) {
                    delayClassName = "good";
                    extra = t('setting.microphone.test.result.good');
                } else if (2000 < delay && delay <= 5000) {
                    delayClassName = "just-so-so";
                    extra = t('setting.microphone.test.result.just_so_so');
                } else {
                    delayClassName = "bad";
                    extra = t('setting.microphone.test.result.bad');
                }
                delay += t('common.millisecond') + `(${extra})`;
            }

            let microphoneDetailComponent = undefined;
            if (this.microphoneStream) {
                let track = this.microphoneStream.getTracks()[0];
                let settings = track?.getSettings() || {};
                microphoneDetailComponent = <Descriptions className="descriptions" column={1}>
                    <Descriptions.Item label={t('setting.microphone.test.sample_rate')}>{settings.sampleRate}{t('common.hz')}</Descriptions.Item>
                    <Descriptions.Item label={t('setting.microphone.test.sample_size')}>{settings.sampleSize}{t('common.bit')}</Descriptions.Item>
                    <Descriptions.Item label={t('setting.microphone.test.channel_count')}>{settings.channelCount}</Descriptions.Item>
                    <Descriptions.Item className={settings.autoGainControl ? "enable" : "disable"} label={t('setting.microphone.test.auto_gain_control')}>{settings.autoGainControl ? t('common.enable') : t('common.disable')}</Descriptions.Item>
                    <Descriptions.Item className={settings.echoCancellation ? "enable" : "disable"} label={t('setting.microphone.test.echo_cancellation')}>{settings.echoCancellation ? t('common.enable') : t('common.disable')}</Descriptions.Item>
                    <Descriptions.Item className={settings.noiseSuppression ? "enable" : "disable"} label={t('setting.microphone.test.noise_suppression')}>{settings.noiseSuppression ? t('common.enable') : t('common.disable')}</Descriptions.Item>
                </Descriptions>
            }

            let customWave = "";
            if (width >= 1680) {
                customWave = " custom-1680 ";
            }  
            
            component = <div className="tabpane-content tabpane-content-microphone" style={style}>
                <Row justify="space-between" align="top" >
                    <Col span={13} className="icon-col">
                        <span className="name">{microphoneDeviceName}</span>
                        <div className="icon-wrapper">
                            <AudioFilled className="icon"/>
                            <div className={"wave " + customWave}>
                                <div className={"rectangle-1 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-2 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-3 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-4 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-5 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-6 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                            </div>
                        </div>
                    </Col>
                    <Col span={1}/>
                    <Col span={10} className="microphone-col">
                        <div className="microphone-wrapper">
                            <div className="select-wrapper">
                                <div className="title">{t('setting.microphone.list.title')}</div>
                                <Select className="select" value={microphoneDeviceId} disabled={microphoneTestStart} onChange={(value) => this.microphoneOnChange(value)}>
                                    {microphones.map((device) => <Select.Option key={device.deviceId} value={device.deviceId}>{device.label}</Select.Option>)}
                                </Select>
                            </div>
                            <div className="tips">{t('setting.microphone.list.tips')}</div>

                            <div className="btn-wrapper">
                                <Button className="btn" type="primary" icon={!microphoneTestStart ? <CaretRightOutlined /> : <PauseOutlined />} onClick={this.microphoneTestOnClick}>{t('setting.microphone.test.btn')}</Button>
                            </div>
                            <div className="tips">{microphoneTestStart ? t('setting.microphone.test.start_tips') : undefined}</div>
                        </div>
                    </Col>
                </Row>
                <div className="progress-volume">
                    <div>{t('setting.microphone.volume.title')}</div>
                    <Progress percent={microphoneVolume} status="normal" showInfo={false} strokeColor={{'0%': '#73d13d', '100%': '#ff4d4f'}}/>
                </div>
                <div className="tips-wrapper">
                    <Descriptions className="descriptions" column={1}>
                        <Descriptions.Item className={delayClassName} label={t('setting.microphone.test.delay')}>{delay}</Descriptions.Item>
                    </Descriptions>
                    {microphoneDetailComponent}
                </div>
                <div>
                    <span className={"tip-error " + (microphonePreviewError ? "show" : "hidden")}>{microphonePreviewError}</span>
                </div>
            </div>
        } else {
            component = <Result
                className="not-find"
                status="error"
                title={t('setting.microphone.empty.title')}
                subTitle={t('setting.microphone.empty.sub_title')}
            />
        }
        
        return component;
    }

    mkLoudspeaker(style) {
        let { loudspeakerTestStart, loudspeakerDeviceName } = this.state;
        let { t, width, loudspeakerDeviceId, loudspeakers } = this.props;

        let customWave = "";
        if (width >= 1680) {
            customWave = " custom-1680 ";
        }

        let component = undefined;
        if (loudspeakers.length > 0) {
            component = <div className="tabpane-content tabpane-content-loudspeaker" style={style}>
                <Row justify="space-between" align="top" >
                    <Col span={13} className="icon-col">
                        <span className="name">{loudspeakerDeviceName}</span>
                        <div className="icon-wrapper">
                            <FilterFilled className="icon" rotate={90}/>
                            <div className={"wave " + customWave}>
                                <div className={"rectangle-1 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-2 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-3 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-4 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-5 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-6 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                            </div>
                        </div>
                        <audio id="dom_a_setting" src='./mp3/test.mp3' loop autoPlay={false} />
                    </Col>
                    <Col span={1}/>
                    <Col span={10} className="loudspeaker-col">
                        <div className="loudspeaker-wrapper">
                            <div className="select-wrapper">
                                <div className="title">{t('setting.loudspeaker.list.title')}</div>
                                <Select className="select" value={loudspeakerDeviceId} disabled={loudspeakerTestStart} onChange={(value) => this.loudspeakerOnChange(value)}>
                                    {loudspeakers.map((device) => <Select.Option key={device.deviceId} value={device.deviceId}>{device.label}</Select.Option>)}
                                </Select>
                            </div>
                            <div className="tips">{t('setting.loudspeaker.list.tips')}</div>

                            <div className="btn-wrapper">
                                <Button className="btn" type="primary" icon={!loudspeakerTestStart ? <CaretRightOutlined /> : <PauseOutlined />} onClick={this.loudspeakerTestOnClick}>{t('setting.loudspeaker.test.btn')}</Button>
                            </div>
                            <div className="tips">{loudspeakerTestStart ? t('setting.loudspeaker.test.start_tips') : undefined}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        } else {
            component = <Result
                className="not-find"
                status="error"
                title={t('setting.loudspeaker.empty.title')}
                subTitle={t('setting.loudspeaker.empty.sub_title')}
            />
        }
        return component;
    }

    mkLog(style) {
        let that = this;
        let { uploading } = this.state;
        let { t, login, profile } = this.props;

        let handler = Log.getFileHandler();
        let lines = handler?.lines || [];

        let component = <div className="tabpane-content tabpane-content-log" style={style}>
            <div className="output-row" style={{maxHeight: style.maxHeight * 0.7}}>
                {
                    lines.length > 0 ? lines.map((line, index) => {
                        return <div key={index} className={"line" + line.level}>{line.content}</div>
                    }) : <div>{t(uploading ? 'setting.log.output.uploading' : 'setting.log.output.empty')}</div>
                }
            </div>
            <Button className="upload" type="primary" loading={uploading}  onClick={(e) => {
                e.stopPropagation();
                let { name, info } = uploadInfo(profile, login, version, adapter.explorerInfo, mediaDevice)
                that.setState({uploading: true});
                Log.uploadFile(name, info, null, 'log', 
                    (result) => {
                        that.uploadLogTimer = setTimeout(() => {
                            message.success(t('setting.log.upload.message.success'))
                            that.setState({uploading: false});
                        }, 1000)
                    }, (error) => {
                        that.uploadLogTimer = setTimeout(() => {
                            message.success(t('setting.log.upload.message.failed'))
                            that.setState({uploading: false, uploadError: error});
                        }, 1000)
                    }
                );
            }}>{t('setting.log.upload.btn')}</Button>
        </div>

        return component;
    }

    componentDidMount() {
        let { fsm } = this.props;
        if (!fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
            this.refreshTimer = setTimeout(() => {
                this.deviceOnChange();
            }, 1000);
        }

        mediaDevice.addListener(this.deviceOnChange);

        if (!version.isProduction()) {
            window.setting = this;
        }
    }

    componentWillUnmount() {
        let { stream } = this.props;
        mediaDevice.removeListener(this.deviceOnChange);
        stream.stop(this.microphoneStream, 'microphone');
        stream.stop(this.cameraStream, 'camera');
        if (this.audioCtx) {
            this.audioCtx.close();
            this.audioCtx = null;
        }
    }

    render() {
        let { t, height, fsm } = this.props;
        let style = {maxHeight: height * 0.7, minHeight: height * 0.6};
        let fsmState = !fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE);
        let component = <Tabs className="tabs" tabPosition="left" onChange={this.tabOnChange}> 
            <Tabs.TabPane 
                tab={<span className="tabpane" onDoubleClick={this.debugOnClick}>
                        <AppstoreFilled className="icon"/>
                        <span className="title">{t('setting.tab.title.base')}</span>
                    </span>}
                key="base"
            >
                {this.mkBase(style)}
            </Tabs.TabPane>
            {
                fsmState ? <Tabs.TabPane
                    tab={<span className="tabpane">
                        <ToolFilled className="icon" />
                        <span className="title">{t('setting.tab.title.global')}</span>
                    </span>}
                    key="global"
                >
                    {this.mkGlobal(style)}
                </Tabs.TabPane> : undefined
            }
            { 
                fsmState ? <Tabs.TabPane
                    tab={<span className="tabpane">
                            <IconFont className="icon" type="icon-camera-2"/>
                            {/* <CameraOutlined className="icon"/> */}
                            <span className="title">{t('setting.tab.title.camera')}</span>
                        </span>}
                    key="camera"
                >
                    {this.mkCamera(style)}
                </Tabs.TabPane> : undefined
            }
            { 
                fsmState ? <Tabs.TabPane
                    tab={<span className="tabpane">
                            <AudioFilled className="icon"/>
                            <span className="title">{t('setting.tab.title.microphone')}</span>
                        </span>}
                    key="microphone"
                >
                    {this.mkMicrophone(style)}
                </Tabs.TabPane> : undefined
            }
            { 
                fsmState ? <Tabs.TabPane
                    tab={<span className="tabpane">
                            <FilterFilled className="icon" rotate={90}/>
                            <span className="title">{t('setting.tab.title.loudspeaker')}</span>
                        </span>}
                    key="loudspeaker"
                >
                    {this.mkLoudspeaker(style)}
                </Tabs.TabPane> : undefined
            }
            <Tabs.TabPane
                tab={<span className="tabpane">
                        <BugFilled className="icon"/>
                        <span className="title">{t('setting.tab.title.log')}</span>
                    </span>}
                key="log"
            >
                {this.mkLog(style)}
            </Tabs.TabPane>
        </Tabs>

        return <div className="content">
            {component}
        </div>
    }
}


let mapState = (state) => ({

});

export default connect(
    mapState, 
    null,
    null,
)(Setting);
