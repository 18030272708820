
import { adapter } from "../adapter";
import { Log } from "../../util/log";
import { config } from "../../util/version";

export var Kind = {
    NONE: 'none',
    AUDIO: 'a',
    VIDEO: 'v',
    ALL: 'all',
}

export var Dir = {
    NONE: 'none',
    SEND: 's',
    RECV: 'r',
    ALL: 'all',
}

export var ReportState = {
    STOP: 0,
    START: 1,
}

class Preview {
    constructor(localDomId = "dom_v_local", remoteDomId = "dom_v_remote", localPreviewDomId = "dom_v_local_preview", This) {
        this.This = This;

        this.__localDomId = localDomId;
        this.__remoteDomId = remoteDomId;
        this.__localPreviewDomId = localPreviewDomId;

        this.__stream = null;
        this.__getMediaSuccessFun = null;
        this.__getMediaErrorFun = null;

        this.status = true;
        this.startDoing = false;
    }

    get stream() {
        return this.__stream;
    }

    set onGetMediaSuccess (fun) {
        this.__getMediaSuccessFun = fun;
    }

    set onGetMediaError (fun) {
        this.__getMediaErrorFun = fun;
    }

    localStart(stream) {
        let element1 = document.getElementById(this.__localDomId);
        element1 && window.attachMediaStream(element1, this.__stream)
    }

    remoteStart(stream) {
        let element1 = document.getElementById(this.__remoteDomId);
        element1 && window.attachMediaStream(element1, stream)
    }

    start(deviceId) {
        if (!deviceId) {
            return false;
        }

        Log.info(this.This.sipSessionInfo(), `video preview start. device: ${deviceId} with ${config.preview.definition.width}*${config.preview.definition.height}`);

        if ((!this.__stream || !this.__stream.active) && (!this.startDoing || this.startDoing !== deviceId)) {
            this.stop();
            this.startDoing = deviceId;
            adapter.getUserMedia({ 
                video: {
                    width: config.preview.definition.width, 
                    height: config.preview.definition.height,
                    deviceId: { 
                        exact: deviceId,
                    }
                }, 
            }, (stream) => {
                // 成功的回调
                this.startDoing = false;
                let element1 = document.getElementById(this.__localDomId);
                let element2 = document.getElementById(this.__localPreviewDomId);
                Log.debug(this.This.sipSessionInfo(), `video preview success. attach DOM<${element1.id}> and DOM<${element2.id}> to media stream<${stream.id}> of device<${deviceId}>`);
                element1 && window.attachMediaStream(element1, stream)
                element2 && window.attachMediaStream(element2, stream)
                
                this.__stream = stream;
                this.status = true;
                if (this.__getMediaSuccessFun) {
                    this.__getMediaSuccessFun(stream);
                }
            }, (error) => {
                this.startDoing = false;
                // 失败的回调
                Log.warn(this.This.sipSessionInfo(), `video preview error. details: ${error.toString()}`);
                this.status = false;
                if (this.__getMediaErrorFun) {
                    this.__getMediaErrorFun(error);
                }
            })
        }

        return true;
    }

    stop() {
        if (this.__stream) {
            if(this.__stream.stop) {
                this.__stream.stop();
                Log.debug(this.This.sipSessionInfo(), `stream ${this.__stream.id} stopped`);
            } else {
                let tracks = this.__stream.getTracks() || [];
                tracks.forEach(track => {
                    if (track.stop){
                        track.stop();
                        Log.debug(this.This.sipSessionInfo(), `video preview stream stopped. ${this.__stream.id} ${track.kind} track`);
                    }
                });
            }
        }
        this.__stream = null;
    }
}

class Report {

    constructor(name, This) {
        this.This = This;
        this.__name = name;

        this.__peerConnection = null;
        this.__streams = [];
        this.__reports = {};

        this.__state = ReportState.STOP;
    }
    
    set peerConnection(pc) {
        this.__peerConnection = pc;
        return this;
    }

    set streams(streams) {
        this.__streams = streams;
        return this;
    }

    set interval(interval) {
        this.__interval = interval;
        return this;
    }

    set reports(reps) {
        this.__reports = reps;
        return this;
    }

    testState(state) {
        return this.__state === state;
    }

    start() {
        this.stop();
        this.__timer = setInterval(() => this.collect(), this.__interval * 1000)
        this.__state = ReportState.START;
        Log.info(this.This.sipSessionInfo(), `start interval timer(${this.__timer} per ${this.__interval}s) for ${this.__name} report from ${this.__streams.length} streams`)
    }

    stop() {
        this.__state = ReportState.STOP;
        if (this.__timer) {
            clearInterval(this.__timer);
            Log.info(this.This.sipSessionInfo(), `stop interval timer(${this.__timer}) for ${this.__name} report`)
            this.__timer = null;
        }
    }

    collect() {
        let peerConnection = this.__peerConnection;
        try {
            this.__streams.forEach((stream, i) => {
                let tracks = stream.getTracks() || [];
                tracks.forEach((item, j) => {
                    try {
                        peerConnection.getStats(item)
                            .then(stats => {
                                let key = `${this.__name}Reports`;
                                let index = String.fromCharCode(i + 65) + ((j + 1) < 10 ? ("0" + (j + 1)) : (j + 1));
                                let track = this.__reports[key];
                                if (!track) {
                                    track = this.__reports[key] = {}
                                }
                                if (!track[index]) {
                                    track[index] = {};
                                }
        
                                stats.forEach(report => {
                                    switch(report.type) {
                                        case "codec":
                                        case "track":
                                        case "transport":
                                        case "local-candidate":
                                        case "remote-candidate": {
                                            if (report.type === "track") {
                                                report.msid = stream.id;
                                                report.enabled = item.enabled;
                                            }
                                            track[index][report.type] = report;
                                            // console.log(key, report);
                                            break;
                                        }

                                        case "outbound-rtp": {
                                            // 计算发送字节数
                                            let lastBytesSend = (track[index][report.type] && (track[index][report.type].bytesSent + track[index][report.type].headerBytesSent)) || 0
                                            let tmpBitRate = parseInt((report.bytesSent + report.headerBytesSent - lastBytesSend) * 8 / this.__interval)
                                            report.outgoingBitRate = tmpBitRate < 0 ? track[index][report.type].outgoingBitRate : tmpBitRate;
                                            // 计算编码帧率
                                            let lastFramesEncoded = track[index][report.type]?.framesEncoded || 0
                                            let  tmpEncodedRate = parseInt((report.framesEncoded - lastFramesEncoded) / this.__interval)
                                            report.framesEncodedRate = tmpEncodedRate < 0 ? track[index][report.type].framesEncodedRate : tmpEncodedRate
                                            
                                            track[index][report.type] = report;
                                            // console.log(key, report)
                                            break;
                                        }

                                        case "inbound-rtp": {
                                            // 计算接收字节数
                                            let lastBytesRecv = (track[index][report.type] && (track[index][report.type].bytesReceived + track[index][report.type].headerBytesReceived)) || 0
                                            let tmpBitRate = parseInt((report.bytesReceived + report.headerBytesReceived - lastBytesRecv) * 8 / this.__interval)
                                            report.incomingBitRate = tmpBitRate < 0 ? track[index][report.type].incomingBitRate : tmpBitRate
                                            // 计算解码帧率
                                            let lastFramesDecoded = (track[index][report.type]?.framesDecoded) || 0
                                            let tmpDecodedRate = parseInt((report.framesDecoded - lastFramesDecoded) / this.__interval)
                                            report.framesDecodedRate = tmpDecodedRate < 0 ? track[index][report.type].framesDecodedRate : tmpDecodedRate
                                            
                                            track[index][report.type] = report;
                                            // console.log(key, report)
                                            break;
                                        }

                                        default: 
                                            break;
                                    }
                                    // console.log(key, report);
                                })
                            })
                            .catch((error) => {
                                // Log.error(error)
                            })
                    } catch (error) {
                        // Log.error(error)
                    }
                })
            });
        } catch (error) {
            // Log.error(error)
        }
    }
}

class ReportManager {
    constructor(This) {
        this.This = This;
        
        this.__peerConnection = null;
        this.__streamGroups = {};
        this.__reportStore = {};

        [
            `${Kind.VIDEO}${Dir.RECV}`, 
            `${Kind.VIDEO}${Dir.SEND}`, 
            `${Kind.AUDIO}${Dir.RECV}`, 
            `${Kind.AUDIO}${Dir.SEND}`,
        ].forEach((name) => {
            this.__streamGroups[name] = {
                report: new Report(name, This),
                streams: [],
            }
        })
    }

    set peerConnection(pc) {
        this.__peerConnection = pc;
        return this;
    }

    getStreams(kind = Kind.NONE, dir = Dir.NONE) {
        let groups = this.__streamGroups[`${kind}${dir}`] || {};
        return groups.streams || [];
    }

    add(stream, kind, dir) {
        let key = `${kind}${dir}`;
        Log.debug(this.This.sipSessionInfo(), `add ${key} stream`);
        let group = this.__streamGroups[key];
        if (group) {
            if (!group.streams.find((streamTmp) => streamTmp.id === stream.id)) {
                group.streams.push(stream);
            } else {
                Log.warn(this.This.sipSessionInfo(), `do not add a duplicate ${key} stream: ${stream.id}`);
            }
        } else {
            Log.error(this.This.sipSessionInfo(), `add ${key} stream failed`);
        }
    }

    replace(stream, kind, dir, index) {
        let key = `${kind}${dir}`;
        Log.debug(this.This.sipSessionInfo(), `replace ${key}#${index} stream`);
        let group = this.__streamGroups[key];
        if (group) {
            group.streams[index] = stream;
            group.report.streams = group.streams;
        }
    }

    remove(stream) {
        for (let key in this.__streamGroups) {
            let group = this.__streamGroups[key];
            let index = group.streams.findIndex((item) => item.id === stream.id);
            if (index !== -1) {
                group.streams.splice(index, 1)
                group.report.streams = group.streams;
                break;
            }
        }
    }

    clear() {
        for (let key in this.__streamGroups) {
            this.__streamGroups[key].streams = [];
        }
        this.__peerConnection = null;
    }

    start(fun, interval = 1, delay = 3) {
        if (this.debounce1) {
            clearTimeout(this.debounce1);
        }

        this.debounce1 = setTimeout(() => {
            for (let key in this.__streamGroups) {
                let group = this.__streamGroups[key];
                group.report.peerConnection = this.__peerConnection;
                group.report.streams = group.streams;
                group.report.interval = interval;
                group.report.reports = this.__reportStore;
                group.report.start();
            }
            this.__timer = setInterval(() => fun(this.__reportStore), interval * 1000)
            this.debounce1 = null;
        }, delay * 1000)
    }

    stop(kind = Kind.NONE, dir = Dir.NONE, delay = 1) {
        if (this.debounce2) {
            clearTimeout(this.debounce2);
        }

        this.debounce2 = setTimeout(() => {
            let vrReport = this.__streamGroups[`${Kind.VIDEO}${Dir.RECV}`].report;
            let vsReport = this.__streamGroups[`${Kind.VIDEO}${Dir.SEND}`].report;
            let arReport = this.__streamGroups[`${Kind.AUDIO}${Dir.RECV}`].report;
            let asReport = this.__streamGroups[`${Kind.AUDIO}${Dir.SEND}`].report;
            if ((kind === Kind.VIDEO || kind === Kind.ALL) && (dir === Dir.RECV || dir === Dir.ALL)) {
                vrReport.stop();
            }

            if ((kind === Kind.VIDEO || kind === Kind.ALL) && (dir === Dir.SEND || dir === Dir.ALL)) {
                vsReport.stop();
            }
            
            if ((kind === Kind.AUDIO || kind === Kind.ALL) && (dir === Dir.RECV || dir === Dir.ALL)) {
                arReport.stop();
            }
            
            if ((kind === Kind.AUDIO || kind === Kind.ALL) && (dir === Dir.SEND || dir === Dir.ALL)) {
                asReport.stop();
            }

            if (vrReport.testState(ReportState.STOP) && vsReport.testState(ReportState.STOP)
                && arReport.testState(ReportState.STOP) && asReport.testState(ReportState.STOP)) {
                if (this.__timer) {
                    clearInterval(this.__timer);
                    this.__timer = null;
                }
                this.clear();
            }
            this.debounce2 = null;
        }, delay * 1000);
    }
}

export class Stream {

    constructor(This) {
        this.This = This;
        this.__peerConnection = null;
        this.__attach_tasks = {};

        this.__preview = new Preview("dom_v_local", "dom_v_remote", "dom_v_local_preview", This);
        this.__reportManager = new ReportManager(This);
        
    }

    __getInfo(stream) {
        let sp = stream.id.split("_");
        if (sp.length === 3) {
            return {
                kind: sp[0],
                index: sp[1],
                id: sp[2],
            }
        } else {
            return {};
        }
    }

    set peerConnection(pc) {
        this.__peerConnection = pc;
        if (this.__reportManager) {
            this.__reportManager.peerConnection = pc;
        }
        return this;
    }

    get preview() {
        return this.__preview;
    }

    get report() {
        return this.__reportManager;
    }

    /********************************* 音/视频通用操作相关 *********************************/
    stop(stream, classify = '') {
        if (stream) {
            try {
                if (stream.stop) {
                    stream.stop();
                    Log.debug(`stop ${classify} stream sccess`);
                } else {
                    let tracks = stream.getTracks() || [];
                    tracks.forEach(track => {
                        if (track.stop){
                            track.stop();
                            Log.debug(`stop ${classify} ${track.kind} track of stream(${stream.id}) success`);
                        }
                    });
                }
            } catch (error) {
                
            }
        }
    }

    deattchAll() {
        let vStreams = this.report.getStreams(Kind.VIDEO, Dir.RECV);
        vStreams.forEach((stream) => {
            let info = this.__getInfo(stream);
            this.attach(`dom_v_remote${info.index}`, null);
        })

        let aStreams = this.report.getStreams(Kind.AUDIO, Dir.RECV);
        aStreams.forEach((stream) => {
            let info = this.__getInfo(stream);
            this.attach(`dom_a_remote${info.index}`, null);
        })
    }

    attach(domId, stream, retry = 2) {
        let that = this;
        let element = document.getElementById(domId);
        if (this.__attach_tasks[domId]) {
            clearTimeout(this.__attach_tasks[domId]);
            this.__attach_tasks[domId]= 0;
        }
        if (element && stream) {
            Log.debug(this.This.sipSessionInfo(), `attach DOM<${domId}> to media stream<${stream.id}>`);
            window.attachMediaStream(element, stream);
        } else if (element && !stream) {
            Log.debug(this.This.sipSessionInfo(), `deattach DOM<${element.id}>`);
            window.attachMediaStream(element, null);
        } else if (!element) {
            if (retry > 0) {
                this.__attach_tasks[domId] = setTimeout(() => {
                    that.attach(domId, stream, retry - 1);
                }, 1000);
            } else {
                Log.warn(this.This.sipSessionInfo(), `attach DOM<${domId}> to media stream<${stream?.id || null}> failed`);
            }
        } 
    }
    
    /********************************* 音频相关 *********************************/
    reattachAudioRecv(stream) {
        let info = this.__getInfo(stream);
        this.attach(`dom_a_remote${info.index}`, null);
        this.attach(`dom_a_remote${info.index}`, stream);
    }

    setSinkId(domId, deviceId, retry = 2) {
        let that = this;
        let element = document.getElementById(domId);
        if (element) {
            try {
                element.setSinkId(deviceId);
                Log.debug(this.This.sipSessionInfo(), `attach DOM<${element.id}> to device ${deviceId}`)
            } catch (error) {
                Log.error(this.This.sipSessionInfo(), error);
            }
        } else {
            setTimeout(() => {
                that.setSinkId(domId, deviceId, retry - 1);
            }, 1000);
        }
    }

    audioPause(enable = true) {
        Array.from({ length: 4 }).forEach((_, index) => {
            let dom = document.getElementById(`dom_a_remote${index}`)
            if (dom?.srcObject) {
                // dom.volume = enable ? 1 : 0;
                dom.srcObject.getTracks().forEach(t => {
                    t.enabled = !enable
                });
            }
        })
    }

    /********************************* 视频相关 *********************************/
    getVideoRecvStreamById(id) {
        let element = document.getElementById(`dom_v_remote${id}`);
        return element?.srcObject;
    }

    reattachVideoRecv(stream) {
        let info = this.__getInfo(stream);
        this.attach(`dom_v_remote${info.index}`, null);
        this.attach(`dom_v_remote${info.index}`, stream);
    }

    reattachVideoRecvById(id, timeout = 1000) {
        setTimeout(() => {
            let streams = this.report.getStreams(Kind.VIDEO, Dir.RECV);
            streams.forEach((stream) => {
                let info = this.__getInfo(stream);
                if (parseInt(id) === parseInt(info.index)) {
                    this.attach(`dom_v_remote${info.index}`, null);
                    this.attach(`dom_v_remote${info.index}`, stream);
                }
            })
        }, timeout);
    }

    deattachVideoRecvById(id, timeout = 0) {
        let that = this;
        function fun() {
            let streams = that.report.getStreams(Kind.VIDEO, Dir.RECV);
            if (streams) {
                streams.forEach((stream) => {
                    let info = that.__getInfo(stream);
                    if (parseInt(id) === parseInt(info.index)) {
                        that.attach(`dom_v_remote${info.index}`, null);
                    }
                })
            }
        }
        if (timeout) {
            setTimeout(() => {
                fun();
            }, timeout);
        } else {
            fun();
        }
        
    }

    reattachAllVideoRecv() {
        setTimeout(() => {
            let streams = this.report.getStreams(Kind.VIDEO, Dir.RECV);
            if (streams) {
                streams.forEach((stream) => {
                    this.reattachVideoRecv(stream);
                })
            }
        }, 1000);
    }
}