export class DevicePreference {

    constructor(itemKey = "vmeeting::devicePreference") {
        this.__itemKey = itemKey;
    }

    key(classify) {
        switch(classify){
            case "camera":
            case "microphone":
            case "loudspeaker":
                break;
            default:
                throw TypeError("not support classify: " + classify)
        }
        
        return this.__itemKey + "::" + classify;
    }

    get(classify, mediaDevice) {
        let localStorageKey = this.key(classify);
        let devices = [];
        switch(classify){
            case "camera":
                devices = mediaDevice.cameras
                break;
            case "microphone":
                devices = mediaDevice.microphones
                break;
            case "loudspeaker":
                devices = mediaDevice.loudspeakers
                break;
            default:
                throw TypeError("not support classify: " + classify)
        }
        let deviceId = window.localStorage.getItem(localStorageKey);
        let findRet = null;
        if (deviceId) {
            findRet = devices.find((device) => device.deviceId === deviceId);
        }
        // 没找到默认取第一个
        if (!findRet) {
            findRet = devices[0];
        }

        return findRet?.deviceId || null;
    }

    set(classify, deviceId) {
        window.localStorage.setItem(this.key(classify), deviceId);
    }
}