/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from "react-redux"
import { Modal, Tabs } from "antd";
import { withTranslation } from 'react-i18next'
import { FilterOutlined, AudioOutlined, CameraOutlined, DesktopOutlined} from "@ant-design/icons";
import { G2, Chart, Geom, Axis, Tooltip as BZTooltip, Coord, Label, Legend, View, Guide, Shape, Facet, Util,} from "bizcharts";
import { IconFont } from "../../util/iconFont";
import { getNetWorkDesc } from '../../util/logic';

import "./rtc.css"
import "../common/paragraph.css";

@withTranslation('translation', {withRef: true})
class Rtc extends Component {
    constructor(props){
        super(props);

        this.state = {
        };
    }

    audioSenderInfo(report, key) {
        let { t, data, iceDescription } = this.props;
        let graphData = data.map((d) => {
            return {
                time: d.time,
                bitrate: d.report.asReports?.[key]?.["outbound-rtp"]?.outgoingBitRate || 0,
            }
        })
        let codec = report["codec"];
        let track = report["track"];
        let localExternalCandidate = report["local-candidate"];
        let localCandidate = iceDescription?.audio_rtp;
        let remoteCandidate = report["remote-candidate"];
        let outboundRtp = report["outbound-rtp"];
        return <div className="content">
            <div className="paragraph">
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    localCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{localCandidate.relatedAddress}:{localCandidate.relatedPort} {localCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    localExternalCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr_external')}</span>
                            <span className="value">{localExternalCandidate.ip}:{localExternalCandidate.port} {localExternalCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remoteCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr_external')}</span>
                            <span className="value">{remoteCandidate.ip}:{remoteCandidate.port} {remoteCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.enabled')}</span>
                            <span className="value">{t(track.enabled ? 'common.enable' : 'common.disable')}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    outboundRtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{outboundRtp.ssrc}(0x{outboundRtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.timestamp')}</span>
                            <span className="value">{outboundRtp.timestamp / 1000}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packets_sent')}</span>
                            <span className="value">{outboundRtp.packetsSent}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_sent')}</span>
                            <span className="value">{((outboundRtp.bytesSent + outboundRtp.headerBytesSent) / (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.outgoing_bitrate')}</span>
                            <span className="value">{getNetWorkDesc(outboundRtp.outgoingBitRate)}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="graph">
                {this.mkGraph(graphData, false)}
            </div>
        </div>;
    }

    audioReceiverInfo(report, key) {
        let { t, data, iceDescription } = this.props;
        let graphData = data.map((d) => {
            return {
                time: d.time,
                bitrate: d.report.arReports?.[key]?.["inbound-rtp"]?.incomingBitRate || 0,
            }
        })
        let codec = report["codec"];
        let track = report["track"];
        let localExternalCandidate = report["local-candidate"];
        let localCandidate = iceDescription?.audio_rtp;
        let remoteCandidate = report["remote-candidate"];
        let inboundRtp = report["inbound-rtp"];
        return <div className="content">
            <div className="paragraph">
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    localCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{localCandidate.relatedAddress}:{localCandidate.relatedPort} {localCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    localExternalCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr_external')}</span>
                            <span className="value">{localExternalCandidate.ip}:{localExternalCandidate.port} {localExternalCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remoteCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr_external')}</span>
                            <span className="value">{remoteCandidate.ip}:{remoteCandidate.port} {remoteCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.enabled')}</span>
                            <span className="value">{t(track.enabled ? 'common.enable' : 'common.disable')}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    inboundRtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{inboundRtp.ssrc}(0x{inboundRtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.timestamp')}</span>
                            <span className="value">{inboundRtp.lastPacketReceivedTimestamp}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.fec')}</span>
                            <span className="value">{inboundRtp.fecPacketsReceived}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packet_lost')}</span>
                            <span className="value">{Math.abs(parseInt(inboundRtp.packetsLost))}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packets_recv')}</span>
                            <span className="value">{inboundRtp.packetsReceived}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_recv')}</span>
                            <span className="value">{((inboundRtp.bytesReceived + inboundRtp.headerBytesReceived)/ (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.incoming_bitrate')}</span>
                            <span className="value">{getNetWorkDesc(inboundRtp.incomingBitRate)}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="graph">
                {this.mkGraph(graphData, false)}
            </div>
        </div>;
    }

    videoSenderInfo(report, key) {
        let { t, data, iceDescription } = this.props;
        let graphData = data.map((d) => {
            return {
                time: d.time,
                frame: d.report.vsReports?.[key]?.["outbound-rtp"]?.framesEncodedRate || 0,
                bitrate: d.report.vsReports?.[key]?.["outbound-rtp"]?.outgoingBitRate || 0,
            }
        })
        let codec = report["codec"];
        let track = report["track"];
        let localExternalCandidate = report["local-candidate"];
        let localCandidate = iceDescription?.video_rtp;
        let remoteCandidate = report["remote-candidate"];
        let outboundRtp = report["outbound-rtp"];
        return <div className="content">
            <div className="paragraph">
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    track?.frameWidth !== undefined ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.definition')}</span>
                            <span className="value">{track.frameWidth} × {track.frameHeight}</span>
                        </div>
                    </div> : undefined
                }
                 {
                    localCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{localCandidate.relatedAddress}:{localCandidate.relatedPort} {localCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    localExternalCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr_external')}</span>
                            <span className="value">{localExternalCandidate.ip}:{localExternalCandidate.port} {localExternalCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remoteCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr_external')}</span>
                            <span className="value">{remoteCandidate.ip}:{remoteCandidate.port} {remoteCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.enabled')}</span>
                            <span className="value">{t(track.enabled ? 'common.enable' : 'common.disable')}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    outboundRtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{outboundRtp.ssrc}(0x{outboundRtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.timestamp')}</span>
                            <span className="value">{outboundRtp.timestamp / 1000}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.frames_encoded_rate')}</span>
                            <span className="value">{outboundRtp.framesEncodedRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.fir')}</span>
                            <span className="value">{outboundRtp.firCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.nack')}</span>
                            <span className="value">{outboundRtp.nackCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pli')}</span>
                            <span className="value">{outboundRtp.pliCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packets_sent')}</span>
                            <span className="value">{outboundRtp.packetsSent}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_sent')}</span>
                            <span className="value">{((outboundRtp.bytesSent + outboundRtp.headerBytesSent) / (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.outgoing_bitrate')}</span>
                            <span className="value">{getNetWorkDesc(outboundRtp.outgoingBitRate)}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="graph">
                {this.mkGraph(graphData, true)}
            </div>
        </div>
    }

    videoReceiverInfo(report, key) {
        let { t, data, iceDescription } = this.props;
        let graphData = data.map((d) => {
            return {
                time: d.time,
                frame: d.report.vrReports?.[key]?.["inbound-rtp"]?.framesDecodedRate || 0,
                bitrate: d.report.vrReports?.[key]?.["inbound-rtp"]?.incomingBitRate || 0,
            }
        })
        let codec = report["codec"];
        let track = report["track"];
        let localExternalCandidate = report["local-candidate"];
        let localCandidate = iceDescription?.video_rtp;
        let remoteCandidate = report["remote-candidate"];
        let inboundRtp = report["inbound-rtp"];
        return <div className="content">
            <div className="paragraph">
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    track?.frameWidth !== undefined ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.definition')}</span>
                            <span className="value">{track.frameWidth} × {track.frameHeight}</span>
                        </div>
                    </div> : undefined
                }
                {
                    localCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{localCandidate.relatedAddress}:{localCandidate.relatedPort} {localCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    localExternalCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr_external')}</span>
                            <span className="value">{localExternalCandidate.ip}:{localExternalCandidate.port} {localExternalCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remoteCandidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr_external')}</span>
                            <span className="value">{remoteCandidate.ip}:{remoteCandidate.port} {remoteCandidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.enabled')}</span>
                            <span className="value">{t(track.enabled ? 'common.enable' : 'common.disable')}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    inboundRtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{inboundRtp.ssrc}(0x{inboundRtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.timestamp')}</span>
                            <span className="value">{inboundRtp.lastPacketReceivedTimestamp}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.frames_decoded_rate')}</span>
                            <span className="value">{inboundRtp.framesDecodedRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.fir')}</span>
                            <span className="value">{inboundRtp.firCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.nack')}</span>
                            <span className="value">{inboundRtp.nackCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pli')}</span>
                            <span className="value">{inboundRtp.pliCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packet_lost')}</span>
                            <span className="value">{Math.abs(parseInt(inboundRtp.packetsLost))}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packets_recv')}</span>
                            <span className="value">{inboundRtp.packetsReceived}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_recv')}</span>
                            <span className="value">{((inboundRtp.bytesReceived + inboundRtp.headerBytesReceived) / (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.incoming_bitrate')}</span>
                            <span className="value">{getNetWorkDesc(inboundRtp.incomingBitRate)}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="graph">
                {this.mkGraph(graphData, true)}
            </div>
        </div>
    }

    mkGraph(data, isVideo = true) {
        let that = this;
        let { height } = this.props;
        let scale = {
            time: {
                type: 'time',
                alias: '时间',
                tickCount: 10,
                mask: 'mm:ss',
            },
            bitrate: {
                type: 'linear',
                ticks: Array.from({length: 5}, (v,k) => 1024 * 64 * k),
                alias: '速率',
            },
        }
        if (isVideo) {
            scale.frame = {
                type: 'linear',
                ticks: Array.from({length: 4}, (v,k) => 10 * k),
                alias: '帧率',
            };
            scale.bitrate.ticks = Array.from({length: 9}, (v,k) => 1024 * 256 * k);
        }

        let legendItems = [
            {value: "bitrate", marker: { symbol: "hyphen", stroke: "#ffae6b", radius: 5, lineWidth: 3 }}
        ]
        if (isVideo) {
            legendItems.unshift({value: "frame", marker: { symbol: "hyphen", stroke: "#3182bd", radius: 5, lineWidth: 3 }})
        }

        let padding = [48, 24, 48, 72];
        if (isVideo) {
            padding = [48, 72, 48, 36];
        }

        return <Chart scale={scale} height={height <= 800 ? 180 : 230} data={data} forceFit padding={padding}>
            <Axis name="time" title={false} label={{offset: 25, formatter: (val) => val}}/>
            {isVideo ? <Axis name="frame" grid={null} label={{formatter: (val) => `${val} 帧`, textStyle: { fill: "#3182bd" } }}/> : undefined}
            <Axis name="bitrate" label={{
                formatter: (val) => {
                    let kbps = val / 1024;
                    if (kbps >= 1024) {
                        return `${kbps / 1024} Mbps`;
                    }
                    return `${kbps} Kbps`
                }, textStyle: { fill: "#ffae6b" }
            }} />
            <Legend
                custom={true}
                position="top-right"
                offsetY={-10}
                allowAllCanceled={true}
                itemFormatter={val => {
                    switch(val) {
                        case 'frame': return "帧率";
                        case 'bitrate': return "速率";
                        default: return "";
                    }
                }}
                items={legendItems}
            />
            <BZTooltip showTitle={true}/>
            {isVideo ? <Geom type="line" position="time*frame" color="#3182bd" size={1} shape="dotSmooth" /> : undefined}
            <Geom type="line" position="time*bitrate" color="#ffae6b" size={1} shape="dotSmooth"
                tooltip={["time*bitrate", (time, bitrate) => {
                    return {name: "速率", value: getNetWorkDesc(bitrate)};
                }]}
            />
        </Chart>
    }

    mkMain() {
        let { t, visible, asReports, arReports, vsReports, vrReports, onClose, height } = this.props;
        let tabPanes = [];
        let index = 0;
        if (asReports) {
            index = 0;
            for (let key in asReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <AudioOutlined className="left audio"/>
                            </span>
                            <span className="description">{t('rtc.tab.audio.send', {index: index})}</span>
                        </span>}
                    key={`audio_sender_${key}`}
                    >
                    {this.audioSenderInfo(asReports[key], key)}
                </Tabs.TabPane>)
            }
        }

        if (arReports) {
            index = 0;
            for (let key in arReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <FilterOutlined className="left audio" rotate={90}/>
                            </span>
                            <span className="description">{t('rtc.tab.audio.recv', {index: index})}</span>
                        </span>}
                    key={`audio_receiver_${key}`}
                    >
                    {this.audioReceiverInfo(arReports[key], key)}
                </Tabs.TabPane>)
            }
        }

        if (vsReports) {
            index = 0;
            for (let key in vsReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <CameraOutlined className="left video"/>
                            </span>
                            <span className="description">{t('rtc.tab.video.send', {index: index})}</span>
                        </span>}
                    key={`video_sender_${key}`}
                    >
                    {this.videoSenderInfo(vsReports[key], key)}
                </Tabs.TabPane>)
            }
        }

        if (vrReports) {
            index = 0;
            for (let key in vrReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <DesktopOutlined className="left video"/>
                            </span>
                            <span className="description">{t('rtc.tab.video.recv', {index: index})}</span>
                        </span>}
                    key={`video_receiver_${key}`}
                    >
                    {this.videoReceiverInfo(vrReports[key], key)}
                </Tabs.TabPane>)
            }
        }

        let component = undefined;
        if (tabPanes.length > 0) {
            component = (
                <Tabs 
                    className="tab" 
                    style={{maxHeight: height * 0.8}}
                    tabPosition="left">
                        {tabPanes}
                </Tabs>)
        } 

        return <Modal
            className="rtc"
            title={<span className="title">
                <IconFont className="icon" type="icon-rtc-dark"/>
                <span>{t('rtc.title')}</span>
            </span>}
            width={"50%"}
            closable={true}
            maskClosable={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                {component}
            </div>
        </Modal>
    }

    componentDidMount() {

    }

    render() {
        return this.mkMain();
    }
}



let mapState = (state) => ({
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Rtc);