/*
序号	显示模式代码	水平象素×垂直象素	比例
1	QQCIF	88×72	11:9
2	SUB-QCIF	128×96	4:3
3	QQVGA	160×120	4:3
4	QCIF	176×144	11:9
5	Sub-QVGA-	208×176	13:11
6	Sub-QVGA	220×176	5:4
7	HQVGA	240×160	3:2
8	Sub-QVGA+	240×176	15:11
9	CGA	320×200	16:10
10	QVGA	320×240	4:3
11	CIF	352×288	11:9
12	WQVGA	360×240	3:2
13	WQVGA	384×240	16:10
14	WQVGA	400×240	5:3
15	WQVGA	400×320	5:4
16	FWQVGA	432×240	16:9
17	WQVGA	480×240	2:1
18	WQVGA	480×272	16:9
19	HVGA	480×320	3:2
20	EGA	640×350	64:35
21	nHD	640×360	16:9
22	VGA	640×480	4:3
23	VGA+	720×480	3:2
24	WVGA	768×480	16:10
25	PAL	768×576	4:3
26	WVGA	800×480	5:3
27	FWVGA	854×480	16:9
28	SVGA	800×600	4:3
29	qHD	960×540	16:9
30	DVGA	960×640	3:2
31	WSVGA	1024×576	16:9
32	WSVGA	1024×600	128:75
33	XGA	1024×768	4:3
34	WXGA	1152×768	3:2
35	XGA+	1152×864	4:3
36	HD/WXGA	1280×720	16:9
37	WXGA	1280×768	15:9
38	WXGA	1280×800	16:10
39	SXGA-/UVGA	1280×960	4:3
40	SXGA	1280×1024	25:16
41	FWXGA	1366×768	16:9
42	SXGA+	1400×1050	4:3
43	FWXGA+	1440×960	3:2
44	WXGA+	1440×900	16:10
45	HD+	1600×900	16:9
46	WSXGA	1600×1024	25:16
47	WSXGA+	1600×1050	32:21
48	USVGA/UXGA/UGA	1600×1200	4:3
49	WSXGA+	1680×1050	16:10
50	UXGA	1900×1200	19:12
51	WSUVGA+(WSUGA/HDTV)	1920×1080	16:9
52	WUXGA	1920×1200	16:10
53	WUSXGA	1920×1440	4:3
54	DCI 2K	2048×1280	16:10
55	SUVGA(QXGA)	2048×1536	4:3
56	QWXGA	2048×1152	16:9
57	FHD+	2160×1440	15:10
58	QHD/WQHD	2560×1440	16:9
59	UWXGA	2560×1600	16:10
60	USXGA	2560×2048	5:4
61	QWXGA+	2880×1800	16:10
62	WQXGA+	3200×1800	16:9
63	WQSXGA	3200×2048	25:16
64	QUXGA	3200×2400	4:3
65	UWQHD	3440×1440	43:18
66	UW4K	3840×1600	12:5
67	UHD	3840×2160	16:9
68	WQUXGA	3840×2400	16:10
69	DCI 4K	4096×2160	19:10
70	HXGA	4096×3072	4:3
71	UHD+	5120×2880	16:9
72	WHXGA	5120×3200	16:10
73	HSXGA	5120×4096	5:4
74	WHSXGA	6400×4096	16:10
75	HUXGA	6400×4800	4:3
76	8K UHD	7680×4320	16:9
77	WHUXGA	7680×4800	16:10
78	DCI 8K	8192x4320	19:10
79	UHXGA	8192×6144	4:3
80	UHD+	9600×5400	16:9
81	WUHXGA	9600×6000	16:10
82	HUXGA+	9600×7200	4:3
83	8K UHD+	10240×5760	16:9
84	WHXGA+	10240×6400	16:10
85	WHVXGA	10240×8192	5:4
86	12K UHD	11520×6480	16:9
87	WUHXGA+	11520×7200	16:10
88	DCI 12K	12288×6480	19:10
89	WHUXGA	12288×7200	4:3
90	WUHXGA+	13440×7560	16:9
91	WHUXGA++	14550×8670	4:3
92	16K UHD	15360×8640	16:9
93	WHVXGA+	15360×9600	16:10
94	DCI 16K	16384×8640	19:10
95	WUHXGA+++	17280×9720	16:9
96	WHVUXGA	17495×9840	19:10
97	WHQVUXGA	18390×10344	16:9
98	WHQUVXGA+	19200×10800	19:10
*/
// 分辨率
export var Definition = {
    HDTV: 7, // 超清 1920×1080
    HD: 4, // 高清 1280×720(必须为4，Freeswitch定义)
    STANDARD: 3, // 标清 960×540
    NHD: 5, // 流畅 640×360
    VGA: 2, // 流畅 640×480
    QVGA: 6, // 低清 320×240
    LOW: 1, // 低清 320×180(必须为1，Freeswitch定义)

    toString: function(value) {
        switch(value) {
            case Definition.HDTV: return "1920 × 1080";
            case Definition.HD: return "1280 × 720";
            case Definition.STANDARD: return "960 × 540";
            case Definition.NHD: return "640 × 360";
            case Definition.VGA: return "640 × 480";
            case Definition.QVGA: return "320 × 240";
            case Definition.LOW: return "320 × 180";
            default: return `unknown(${value})`;
        }
    },

    get: function(value) {
        switch(value) {
            case Definition.HDTV: return {width: 1920, height: 1080};
            case Definition.HD: return {width: 1280, height: 720};
            case Definition.STANDARD: return {width: 960, height: 540};
            case Definition.NHD: return {width: 640, height: 360};
            case Definition.VGA: return {width: 640, height: 480};
            case Definition.QVGA: return {width: 320, height: 240};
            case Definition.LOW: return {width: 320, height: 180};
            default: return {};
        }
    },
}

// 高宽比
export var AspectRatio = {
    AR4_3: 4 / 3,
    AR16_9: 16 / 9,
    AR16_10: 16 / 10,

    toString: function(value) {
        switch(value) {
            case AspectRatio.AR4_3: return "4 : 3";
            case AspectRatio.AR16_9: return "16 : 9";
            case AspectRatio.AR16_10: return "16 : 10";
            default: return `${value}`;
        }
    },

    get: function(value) {
        switch(value) {
            case AspectRatio.AR4_3: return {width: 4, height: 3};
            case AspectRatio.AR16_9: return {width: 16, height: 9};
            case AspectRatio.AR16_10: return {width: 16, height: 10};
            default: return {};
        }
    },
}

// 布局模式
export var LayoutMode = {
    OVERLAP: 1, // 画中画(浮动)
    SPLIT_4: 2, // 四分屏
    SPLIT_9: 3, // 九分屏
}

/**
 * 选流时的用户View类
 * @class View
 * @constructor
 */
export class View {
    /**
     * 构造函数
     * @method constructor
     * @for View
     * @param {String} sipNum SIP号码
     * @param {Number} streamId 流ID
     * @param {Definition} definition 分辨率
     * @param {String} nickName 昵称 
     * @return {View} 实例对象
     */
    constructor(sipNum, streamId, definition, nickName) {
        this.__sipNum = sipNum;
        this.__streamId = streamId;
        this.__definition = definition;
        this.__nickName = nickName;
    }
    get definition() {
        return this.__definition;
    }
    /**
     * 打包选流时的用户View信息，供接口使用
     * @method package
     * @for View
     * @return {Map} 打包好的用户View信息
     */
    package() {
        return {
            SipNum: this.__sipNum,
            StreamId: this.__streamId,
            Definition: this.__definition,
        };
    }
    /**
     * 比较streamId是否一致
     * @method cmpStreamId
     * @for View
     * @param {View} other 另外一个View
     * @return {Boolean} 比较本View和其他View的流ID是否一致
     */
    cmpStreamId(other) {
        return this.__streamId === other.__streamId;
    }
    /**
     * 获取View的昵称
     * @method getNickName
     * @for View
     * @return {String} View的昵称
     */
    getNickName() {
        return this.__nickName;
    }
}

export class Views {
    constructor(layoutMode) {
        this.__views = [];
        this.__layoutMode = layoutMode;
    }
    /**
     * 设置布局模式
     * @method setLayoutMode
     * @for Views
     * @param {LayoutMode} layoutMode 布局模式
     * @return {Void} 无
     */
    setLayoutMode(layoutMode) {
        this.__layoutMode = layoutMode;
    }
    /**
     * 获取View list的长度
     * @method length
     * @for Views
     * @return {Number} View list的长度
     */
    length() {
        return this.__views.length;
    }
    /**
     * 获取View list是否为空
     * @method isEmpty
     * @for Views
     * @return {Boolean} View list为空则返回true，否则返回false
     */
    isEmpty() {
        return !this.length();
    }
    /**
     * 在View list中找到满足streamId条件的View
     * @method findByStreamId
     * @for Views
     * @param {Number} streamId 流ID
     * @return {View} 找到了返回那个View，没找到返回undefined
     */
    findByStreamId(streamId) {
        return this.__views.find((v) => v.__streamId === streamId);
    }
    /**
     * 在View list中找到满足sipNum条件的View
     * @method findBySipNum
     * @for Views
     * @param {String} sipNum sip号码
     * @return {View} 找到了返回那个View，没找到返回undefined
     */
    findBySipNum(sipNum) {
        return this.__views.find((v) => v.__sipNum === sipNum);
    }
    /**
     * 在View list中移除满足streamId条件的View
     * @method removeByStreamId
     * @for Views
     * @param {Number} streamId 流ID
     * @return {View} 移除成功被移出的View，没有变化返回undefined
     */
    removeByStreamId(streamId) {
        let view = undefined;
        this.__views.forEach((v, index, arr) => {
            if (v.__streamId === streamId) {
                view = v;
                arr.splice(index, 1);
            }
        });
        return view;
    }
    /**
     * 在View list中移除满足sipNum条件的View
     * @method removeBySipNum
     * @for Views
     * @param {String} sipNum 流ID
     * @return {View} 移除成功被移出的View，没有变化返回undefined
     */
    removeBySipNum(sipNum) {
        let view = undefined;
        this.__views.forEach((v, index, arr) => {
            if (v.__sipNum === sipNum) {
                view = v;
                arr.splice(index, 1);
            }
        });

        // console.log("remove view by sipNum: " + sipNum + " view: " + view);
        return view;
    }
    /**
     * 在View list中移除所有View
     * @method removeAll
     * @for Views
     * @return {Boolean} 移除成功返回true，没有变化返回false
     */
    removeAll() {
        let oldLen = this.length();
        this.__views = [];
        return oldLen !== 0;
    }
    /**
     * 在View中设置某路流为高清
     * @method setToHight
     * @for Views
     * @return {Void} 无
     */
    setToHight(streamId) {
        if (this.__layoutMode === LayoutMode.OVERLAP) {
            this.__views.forEach((view, index) => {
                view.__definition = view.__streamId === streamId ? Definition.HD : Definition.LOW;
            });
        }
    }
    /**
     * 在View list中增加一个View
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.OVERLAP}：
     *  增加的view是第一个，则强制设置的分辨率为HD
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.SPLIT_4}或者{LayoutMode.SPLIT_9}：
     *  增加的view则需要满足最大个数的限制。LayoutMode.SPLIT_4为3个，LayoutMode.SPLIT_9为8个
     * 若streamId为null，则会自动在View list找一个空闲的streamId赋值给此view。
     * 若streamId不为null，并且streamId与现在View list中的View的streamId重复，则移除View list中的那个，再把此View新增进去
     * @method add
     * @for Views
     * @param {View} view View对象
     * @return {Boolean} 增加成功返回true，没有变化返回false
     */
    add(view) {
        let len = this.length();
        let ret = this.findBySipNum(view.__sipNum);
        let addFlag = false;
        if (ret) {
            // console.warn("find same sip number in views. ", view, this.__views)
            return addFlag;
        }
        else {
            // console.log("add view before. ", this.__views, view)
        }
        switch (this.__layoutMode) {
            case LayoutMode.OVERLAP: {
                if (len === 0) {
                    if (view.__streamId === null) {
                        view.__streamId = 0;
                    }
                    // 第一个强制修改为高清
                    view.__definition = Definition.HD;
                    this.__views.push(view);
                    addFlag = true;
                }
                else {
                    if (view.__streamId === null) {
                        // 找一个空位置
                        Array.from({ length: 9 }).forEach((item, index) => {
                            // console.log("find null pos ", index, view.__streamId)
                            if (view.__streamId === null) {
                                let ret = this.findByStreamId(index);
                                if (!ret) {
                                    view.__streamId = index;
                                    this.__views.push(view);
                                    addFlag = true;
                                }
                            }
                        });
                    }
                    else {
                        this.removeByStreamId(view.__streamId);
                        this.__views.push(view);
                        addFlag = true;
                    }
                }
                break;
            }
            case LayoutMode.SPLIT_4:
            case LayoutMode.SPLIT_9: {
                let limit = this.__layoutMode === LayoutMode.SPLIT_4 ? 4 : 9;
                if (len <= limit) {
                    if (view.__streamId === null) {
                        // 找一个空位置
                        Array.from({ length: limit }).forEach((item, index) => {
                            if (view.__streamId === null) {
                                let ret = this.findByStreamId(index);
                                if (!ret) {
                                    view.__streamId = index;
                                    this.__views.push(view);
                                    addFlag = true;
                                }
                            }
                        });
                    }
                    else {
                        this.removeByStreamId(view.__streamId);
                        this.__views.push(view);
                        addFlag = true;
                    }
                }
                break;
            }
            default: {
                throw TypeError("not supported layout mode: ", this.__layoutMode);
            }
        }
        // console.log("add view after. ", this.__views);
        return addFlag;
    }
    /**
     * 打包View为api要求的格式
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.OVERLAP}：
     * 则只会有一路View的 definition为 @link {Definition.HD}，其余全低清
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.SPLIT_4}或者{LayoutMode.SPLIT_9}：
     * 所有路View的definition均为@link {Definition.LOW}
     * @method package
     * @for Views
     * @return {Array} 满足api格式要求的view数组
     */
    package() {
        let rets = [];
        switch (this.__layoutMode) {
            case LayoutMode.OVERLAP: {
                this.__views.forEach((view, index) => {
                    rets.push(view.package());
                });
                break;
            }
            case LayoutMode.SPLIT_4:
            case LayoutMode.SPLIT_9: {
                // 全低清
                this.__views.forEach((view, index) => {
                    view.__definition = Definition.LOW;
                    rets.push(view.package());
                });
                break;
            }
            default:
                break;
        }
        return rets;
    }
}










