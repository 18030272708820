import React, { Component } from "react";
import { connect } from "react-redux"
import { Result, Button, Space } from "antd";
import { withTranslation } from 'react-i18next'
import { adapter } from "../../component/adapter";

import "./version.css"

@withTranslation('translation', {withRef: true})
class VersionError extends Component {
    constructor(props){
        super(props);
        
        this.state = {

        }
    }

    componentDidMount() {
        let { t } = this.props;
        this.setState({
            reason: t('error_page.reason.version_not_match'),
            tips: t('error_page.tips.version_not_match'),
        });
        adapter.addPageResizeListener.bind(this)();
    }

    render() {
        let { reason, tips, height, width } = this.state;
        let { t } = this.props;

        return <div className="version-error-wrapper" style={{height: height, width: width}}>
            <Result
                className="version-error"
                status="500"
                title={reason}
                subTitle={tips}
                extra={<Space>
                    <Button type="primary" danger onClick={(e) => {
                        window.open('','_parent','');  
                        window.close();  
                    }}>{t('error_page.btn.close')}</Button>
                </Space>}
            />
        </div>
    }
}

export default connect(
    null, 
    null
)(VersionError);