/* eslint-disable no-unused-vars */
import { netRequestThunk2, netRequestThunk3 } from '../util/netReqRD';
import { noAuth } from "./login";
import { config } from "../util/version";

export function apiConferenceFind(props, code, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = '/conferences/search/findByAccessCode?accessCode=' + code;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }

    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "GET", successFun, errorFun, noAuth));
}

export function apiConferenceGet(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = '/conferences';
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "GET", successFun, errorFun, noAuth));
}

export function apiConferenceCreate(props, title, mediaServers, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = '/conferences';
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "subject": title,
        "deviceId": login?.token,
        "mediaServers": mediaServers,
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "POST", successFun, errorFun, noAuth));
}

export function apiConferenceDel(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "DELETE", successFun, errorFun, noAuth));
}

export function apiConferenceExit(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "DELETE", successFun, errorFun, noAuth));
}

export function apiConferenceJoin(props, number, mediaServers, mute=false, beSeen=true, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${number}/participants`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "deviceId": login?.token,
        "mediaServers": mediaServers,
        "mute": mute,
        "beSeen": beSeen,
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "POST", successFun, errorFun, noAuth));
}

export function apiConferenceScreen(props, _id, members, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}/screen`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "mode":"FULLSCREEN",
        "media": members,
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "PUT", successFun, errorFun, noAuth));
}

export function apiConferenceScreenShareStart(props, _id, force = false, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}/status`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "desktopShare": "start",
        "force": force,
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "PUT", successFun, errorFun, noAuth));
}

export function apiConferenceScreenShareStop(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}/status`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "desktopShare": "end",
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "PUT", successFun, errorFun, noAuth));
}

export function apiConferenceUnmute(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}/status`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "microphone": "on",
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "PUT", successFun, errorFun, noAuth));
}

export function apiConferenceMute(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}/status`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "microphone": "off",
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "PUT", successFun, errorFun, noAuth));
}

export function apiConferenceCameraOn(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}/status`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "camera": "on",
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "PUT", successFun, errorFun, noAuth));
}

export function apiConferenceCameraOff(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/conferences/${_id}/participants/${login?.owner}/status`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        "camera": "off",
    }
    dispatch(netRequestThunk3(path, body, header, sessionInfo, "PUT", successFun, errorFun, noAuth));
}

export function apiConferenceBoardStart(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/whiteboards/${_id}?active=true`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "GET", successFun, errorFun, noAuth));
}

export function apiConferenceBoardStop(props, _id, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/whiteboards/${_id}?active=false`;
    let header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "GET", successFun, errorFun, noAuth));
}

export function apiMediaServerList(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, user, sessionInfo } = props;
    const path = '/cs/v1/app/mcu/ms/detect/list';
    let header = {
        Token: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
    }
    dispatch(netRequestThunk2(path, body, header, sessionInfo, successFun, errorFun, noAuth, config.host.cs));
    
}

export function apiMediaServerDetect(props, server, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = '/cs/v1/app/mcu/ms/detect/locale';
    let header = {
        Token: login?.token,
        // Authorization: 'Basic ' + Base64.stringify(Utf8.parse(server?.UserName + ":" + server?.Password))
        RefreshToken: login?.refreshToken,
    }
    // 接口是不需要的，但是为了返回时候获得请求的数据，这里必须加上
    let body = {
        ...server
    }
    dispatch(netRequestThunk2(path, body, header, sessionInfo, successFun, errorFun, noAuth, server?.ServerHttpAddr));
}


export function apiConferenceReportApply(props, screenShareCode, sipNum, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = '/cs/v1/app/reallyClient/cast/apply';
    let header = {
        Token: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        CastCode: screenShareCode,
        SipNum: sipNum,
    }
    dispatch(netRequestThunk2(path, body, header, sessionInfo, successFun, errorFun, noAuth, config.host.cs));
}


export function apiConferenceReportCancel(props, sessionId, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = '/cs/v1/app/reallyClient/cast/cancel';
    let header = {
        Token: login?.token,
        RefreshToken: login?.refreshToken,
    }
    let body = {
        SessionId: sessionId,
    }
    dispatch(netRequestThunk2(path, body, header, sessionInfo, successFun, errorFun, noAuth, config.host.cs));
}