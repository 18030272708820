import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { reducer } from './reducer';
import { version } from "../util/version";
    
// 关闭redux的日志
const logger = createLogger({
    logger:undefined
});

const middleware = [ thunk ];
if (!version.isProduction()) {
    middleware.push(logger);
}

export function configStore(initSate=undefined){
    const store = createStore(
        reducer, 
        initSate, 
        composeWithDevTools(applyMiddleware(...middleware))
    )
    return store;
}

export const loadStoreState = () => {
    try {
        const binState = localStorage.getItem('pc_app_web');
        if (binState === null){
            return undefined;
        }
        return {login: JSON.parse(binState)};
    }catch (err){
        return undefined;
    }
};

export const saveStoreState = (state) => {
    try {
        const binState = JSON.stringify(state);
        localStorage.setItem('pc_app_web', binState);
    }catch (err){
        // console.error('save store state failed! err: ' + err);
    }

};

