/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Slider from "react-slick";
import { Row, Col, } from "antd";
import { withTranslation } from 'react-i18next'
import { IconFont } from "../../../util/iconFont";
import { Log } from "../../../util/log";
import { LayoutMode } from "../../../api/structure";
import { getLoginData, getUserData, } from "../../../redux/reducers/login"
import { BoardShareState, BackgroudViewType, } from "../../../component/common/common";
import { LeftCircleFilled, RightCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { message } from "../../../util/message";
import { State as FsmState } from "../../fsm/fsm";

import "./videoLayout.less";

@withTranslation('translation', {withRef: true})
class VideoLayout extends Component {
    constructor(props){
        super(props);

        this.state = {
            slideIndex: 0,
            operationDisable: false,
        }
    }

    mkRemoteMenu(remote, nickName, style = undefined) {
        let { t, zoomInOnClick, videoSwitchOnClick } = this.props;

        return <div className="menu" style={style}>
            {/* 操作 */}
            <div className="operation" style={style}>
                <div className="col" onClick={(e) => zoomInOnClick(e, remote)}>
                    {/* 放大的图标，点击将会使视频放大 */}
                    <IconFont type="icon-zoom-in" className="icon"/>
                    <div className="description">{t('video_layout.menu.operation.zoom_in')}</div> 
                </div>
                <div className="col" onClick={(e) => videoSwitchOnClick(e, remote)}>
                    {/* 切换的图标，点击将会切换视频源 */}
                    <IconFont type="icon-switch3" className="icon" />
                    <div className="description">{t('video_layout.menu.operation.switch')}</div> 
                </div>
            </div>
            {/* 显示该视频框的所属名称 */}
            <div className={"nickname"}>{nickName}</div> 
        </div>
    }

    mkRemotes(style, remotesComponent, backgroudViewType) {
        let { slideIndex, operationDisable } = this.state;
        let { t, views, stream, barVisible, backgroudViewIndex, remotesEnable, remotesCloseOnClick } = this.props;
        let remotesStyle = {opacity: 1, width: style.width - 16, height: 240 + 16}
        if (!remotesEnable) {
            remotesStyle = {opacity: 0, width: style.width - 16, height: 0}
        }
        // 菜单栏显示出来，则位置固定到底部64px位置，没有菜单栏则靠近底部8px位置
        remotesStyle.bottom = barVisible ? 64 : 8;
        let items = remotesComponent.map((item, index) => {
            let view = views.findByStreamId(index);
            let style = {opacity: 1, height: 240, width: 320 };

            if (!view) {
                style = {opacity: 0, height: 0, width: 0 };
            } else {
                // 浮动布局（video为底）需要去掉当前最大化的那个video
                if (backgroudViewType === BackgroudViewType.VIDEO && index === backgroudViewIndex) {
                    style = {opacity: 0, height: 0, width: 0 };
                }
            }
            
            let remote = `remote${index}`
            return <div key={remote} className={remote} style={style}>
                {/* 没有视频数据(没有选流)时的封面图 */}
                <img style={{height: style.height}} className={"poster " + (!view ? "show" : "hidden")} alt="" src="./images/video-poster.png"/>
                {/* 没有视频数据(没有选流)时的蒙层 */}
                <div style={{height: style.height, width: style.width}} className={"obscuration " + (!view ? "show" : "hidden")}/>
                {/* 菜单 */}
                {view ? this.mkRemoteMenu(remote, view.getNickName(), {height: style.height, width: style.width}) : undefined}
                {/* video标签 */}
                {item}
            </div>
        })

        let operations = undefined;
        if (operationDisable) {
            operations = <div>
                <LoadingOutlined className="icon"/>
                <div className="description">{t('video_layout.remotes.loading')}</div>
            </div>;
        } else {
            operations = <div>
                {/* 向前移动 */}
                <div>
                <LeftCircleFilled className="icon" onClick={(e) => {
                    e.stopPropagation();
                    if (operationDisable) {
                        message.info(t('video_layout.message.loading'));
                        return;
                    }
                    if (backgroudViewType === BackgroudViewType.BOARD) {
                        backgroudViewIndex = undefined;
                    }
                    // console.log(`${slideIndex} - 2 ==? ${backgroudViewIndex}`);
                    // Slider填充了width为0的video框（此video被选为了最大的那个高清图，放在了底部），移动时候若遇到了被选中那个框，需要跳过那个位置
                    if (this.sliderDOM && slideIndex !== 0) {
                        this.sliderDOM.slickGoTo(slideIndex - 2 === backgroudViewIndex ? slideIndex - 2 : slideIndex - 1);
                    } else {
                        // message.info("已经到头了");
                    }
                }}/>
                </div>
                {/* 向后移动 */}
                <div>
                <RightCircleFilled className="icon" onClick={(e) => {
                    e.stopPropagation();
                    if (operationDisable) {
                        message.info(t('video_layout.message.loading'));
                        return;
                    }
                    if (backgroudViewType === BackgroudViewType.BOARD) {
                        backgroudViewIndex = undefined;
                    }
                    let sliderWidth = document.getElementById("slider").clientWidth;
                    let max = parseInt(sliderWidth / 320);
                    let total = views.length();
                    // console.log(`${sliderWidth} / 320 = ${max} + ${slideIndex} < ${total} ? | ${slideIndex} ==? ${backgroudViewIndex}`);
                    // 计算出当前的Slider滑窗视图是否足够容纳现在的video框们
                    if (max + slideIndex < total) {
                        // Slider填充了width为0的video框（此video被选为了最大的那个高清图，放在了底部），移动时候若遇到了被选中那个框，需要跳过那个位置
                        this.sliderDOM.slickGoTo(slideIndex === backgroudViewIndex ? slideIndex + 2 : slideIndex + 1);
                    } else {
                        // message.info("已经到头了");
                    }
                }}/>
                </div>
            </div>
        }
        return <div className="remotes-warpper" style={remotesStyle} onClick={(e) => {e.stopPropagation();}}>
            <div className="remotes">
                <Row>
                    <Col span={1} className="left">
                        {operations}
                    </Col>
                    <Col span={22} className="slider" id="slider">
                        {/* 无限列表 */}
                        <Slider
                            ref={(element) => {this.sliderDOM = element}}
                            dots={false}
                            infinite={false}
                            variableWidth={true}
                            speed={250}
                            slidesToShow={1}
                            slidesToScroll={1}
                            // 采用懒加载的模式，一个dom构造好就去绑定video和stream
                            lazyLoad="progressive"
                            onLazyLoad={(indexs) => {
                                indexs.forEach((index) => {
                                    if (index >= backgroudViewIndex) {
                                        index += 1;
                                    }
                                    stream.reattachVideoRecvById(index);
                                })
                                this.setState({
                                    operationDisable: true,
                                })
                                if (this.sliderTimer) {
                                    clearTimeout(this.sliderTimer);
                                }
                                this.sliderTimer = setTimeout(() => {
                                    this.setState({
                                        operationDisable: false,
                                    })
                                    this.sliderTimer = null;
                                }, 2000);
                            }}
                            afterChange={(index) => {
                                this.setState({
                                    slideIndex: index,
                                })
                            }}
                        >
                            {items}
                        </Slider>
                    </Col>
                    <Col span={1} className="right">
                        <CloseCircleFilled className="icon" onClick={remotesCloseOnClick}/>
                    </Col>
                </Row>
            </div>
        </div>
    }

    mkOverlapBoard(style, remotesComponent, boardComponent) {
        let { t, fsm, boardStopOnClick, zoomOutOnClick } = this.props;
        let boardStyle = { height: style.height - 128, width: style.width};
        let remotes = undefined;
        if (fsm.test(FsmState.Call.STABLE)) {
            // remotes = this.mkRemotes(style, remotesComponent, BackgroudViewType.BOARD);
        } 

        return <div>
            <div className="board-overlap" style={boardStyle}>
                <div className="operation">
                    <div className="col" onClick={(e) => zoomOutOnClick(e, "board")}>
                        <IconFont type="icon-board-zoom-out-iframe" className="icon"/>
                        <div className="description">{t('video_layout.menu.operation.zoom_out')}</div> 
                    </div>
                    <div className="col" onClick={(e) => boardStopOnClick(e)}>
                        <IconFont type="icon-board-close-iframe" className="icon"/>
                        <div className="description">{t('video_layout.menu.operation.stop')}</div> 
                    </div>
                </div>
                {boardComponent}
            </div>
            {remotes}
        </div>
    }

    mkOverlapRemote(style, remotesComponent, boardComponent) {
        let { t, fsm, login, viewSelf, screen, detail, barVisible, boardEnable, callType, screenSharerId, 
            backgroudViewIndex, boardCloseOnClick, boardStopOnClick, zoomInOnClick } = this.props;
        let boardStyle = { opacity: 0, height: 0, width: 0};
        let remoteStyle = { opacity: 0, height: 0, width: 0};
        let remotes = undefined;

        if (boardEnable && fsm.test(FsmState.Call.STABLE)) {
            boardStyle = { opacity: 1, height: "240px", width: "320px"};
        } 
        boardStyle.top = barVisible ? 64 : 8;

        if (fsm.test(FsmState.Call.STABLE)) {
            remoteStyle = { opacity: 1, height: style.height, width: style.width};
            // remotes = this.mkRemotes(style, remotesComponent, BackgroudViewType.VIDEO);
        }
        
        let curViewMember = detail?.participants?.find(part => part?.user?.id === screen?.media?.[0] && screen?.media?.[0])
        let noStreamShow = curViewMember && !curViewMember.beSeen && !viewSelf && (!screenSharerId && screenSharerId !== login?.owner)

        return <div>
            <div className="video-overlap">
                <div className={`remote${backgroudViewIndex}`} style={remoteStyle}>
                    {/* 显示没有视频流 */}
                    <div className={"no-stream-tips " + (viewSelf ? "show" : "hidden")} >
                        <div className="icons">
                            <IconFont type="icon-forbidden" className="icon-forbidden"/>
                            <IconFont type="icon-camera-off-1" className="icon-camera-off"/>
                        </div>
                        <span className="description">{t('video_layout.no_stream')}</span>
                    </div>
                    {/* 显示对端已经关闭了摄像头 */}
                    <div className={"no-stream-tips " + (noStreamShow ? "show" : "hidden")}>
                        <div className="icons">
                            <IconFont type="icon-forbidden" className="icon-forbidden"/>
                            <IconFont type="icon-camera-2" className="icon-camera-off"/>
                        </div>
                        <div className="description">{t('video_layout.camera_off')}</div>
                    </div>
                    {/* 显示正在投屏 */}
                    <div className={"no-stream-tips " + ((callType === "screenshare") ? "show" : "hidden")}>
                        <div className="icons">
                            <LoadingOutlined className="icon-forbidden" style={{ color: "#ffffff"}}/>
                            <IconFont type="icon-screen-share3" className="icon-camera-off"/>
                        </div>
                        <div className="description">{t('video_layout.screen_share')}</div>
                    </div>
                    {remotesComponent[backgroudViewIndex]}
                </div>
            </div>
            <div
                className="board float-window"
                style={boardStyle}
                onClick={(e) => { e.stopPropagation(); }}
                onDoubleClick={(e) => zoomInOnClick(e, "board")}
            >
                <div className="menu">
                    <div className="operation">
                        <div className="col" onClick={boardCloseOnClick}>
                            <IconFont type="icon-close" className="icon"/>
                            <div className="description">{t('video_layout.menu.operation.close_preview')}</div> 
                        </div>
                        <div className="col" onClick={(e) => zoomInOnClick(e, "board")}>
                            <IconFont type="icon-zoom-in" className="icon"/>
                            <div className="description">{t('video_layout.menu.operation.zoom_in')}</div> 
                        </div>
                        <div className="col" onClick={(e) => boardStopOnClick(e)}>
                            <IconFont type="icon-board-close" className="icon"/>
                            <div className="description">{t('video_layout.menu.operation.stop')}</div> 
                        </div>
                    </div>
                </div>  
                {boardComponent}
            </div>
            {remotes}
        </div>
    }

    mkSplit(style, remotesComponent, boardComponent, layoutMode) {
        let { views, boardShare, zoomInOnClick } = this.props;
        let mainClassName = undefined;
        let remoteStyle = {}
        let boardStyle = {};

        let hiddenStyle = { opacity: 0, height: 0, width: 0 };
        let split4Style = { opacity: 1, height: style.height / 2, width: style.width / 2 };
        let split9Style = { opacity: 1, height: style.height / 3, width: style.width / 3 };
        switch(layoutMode) {
            case LayoutMode.SPLIT_4: {
                mainClassName = "video-split-4";
                remoteStyle.remote0 = remoteStyle.remote1 = remoteStyle.remote2 
                    = remoteStyle.remote3
                    = split4Style;
                boardStyle = remoteStyle.remote4 = remoteStyle.remote5
                    = remoteStyle.remote6 = remoteStyle.remote7 = remoteStyle.remote8
                    = hiddenStyle;
                if (boardShare === BoardShareState.START) {
                    boardStyle = split4Style;
                    remoteStyle.remote3 = hiddenStyle;
                }
                break;
            }
            case LayoutMode.SPLIT_9: {
                mainClassName = "video-split-9";
                remoteStyle.remote0 = remoteStyle.remote1 = remoteStyle.remote2 = remoteStyle.remote3 
                    = remoteStyle.remote4 = remoteStyle.remote5 = remoteStyle.remote6 = remoteStyle.remote7
                    = remoteStyle.remote8 
                    = split9Style;
                boardStyle = hiddenStyle;

                if (boardShare === BoardShareState.START) {
                    boardStyle = split9Style;
                    remoteStyle.remote8 = hiddenStyle;
                }
                break;
            }
            default: {
                Log.error("unsupported layout mode: " + layoutMode);
                return undefined;
            }
        }

        let remotes = remotesComponent.map((item, index) => {
            let remote = `remote${index}`
            let style = remoteStyle[remote];
            let view = views.findByStreamId(index);
            return <div key={remote} className={remote} style={style}>
                {/* 没有视频数据(没有选流)时的封面图 */}
                <img style={{height: style.height}} className={"poster " + (!view ? "show" : "hidden")} alt="" src="./images/video-poster.png"/>
                {/* 没有视频数据(没有选流)时的蒙层 */}
                <div style={{height: style.height, width: style.width}} className={"obscuration " + (!view ? "show" : "hidden")}/>
                {/* 菜单 */}
                {view ? this.mkRemoteMenu(remote, view.getNickName()) : undefined}
                {/* video标签 */}
                {item}
            </div>
        })

        return <div className={mainClassName}>
            {remotes}
            <div className="board" style={boardStyle} onDoubleClick={(e) => zoomInOnClick(e, "board")}>
                <img alt="" style={{height: boardStyle.height, opacity: boardStyle.opacity}} className={"poster " + (boardShare === BoardShareState.STOP ? "show" : "hidden")} src="./images/board-poster.png"></img>
                <div alt="" style={{height: boardStyle.height, width: boardStyle.width, opacity: boardStyle.opacity}} className={"obscuration " + (boardShare === BoardShareState.STOP ? "show" : "hidden")}/>
                {boardComponent}
            </div>
        </div>
    }

    mkMain(style) {
        let { t, login, user, fsm, barVisible, detail, localEnable, remoteEnable, boardEnable, videoEnable,
            layoutMode, backgroudViewType, localCloseOnClick, remoteCloseOnClick, screen, viewSelf, 
            reportMod, wizzardMod
        } = this.props;
        let localStyle = { opacity: 0, height: 0, width: 0};
        let localPreStyle = { opacity: 0, height: 0, width: 0 };
        let remoteStyle = { opacity: 0, height: 0, width: 0};
        
        if (!fsm.test(FsmState.Call.STABLE, FsmState.Call.PROCESS) && !reportMod && !wizzardMod) {
            localPreStyle = { opacity: 1, height: style.height, width: style.width };
        } 

        if (localEnable && fsm.test(FsmState.Call.STABLE)) {
            localStyle = { opacity: 1, height: 240, width: 320};
        } 
        // 菜单栏显示出来，则位置固定到顶部64px位置，没有菜单栏则靠近顶部8px位置
        localStyle.top = barVisible ? 64 : 8;

        if (remoteEnable && fsm.test(FsmState.Call.STABLE)) {
            remoteStyle = { opacity: 1, height: 240, width: 320};
        } 
        // 菜单栏显示出来，则位置固定到顶部64px位置，没有菜单栏则靠近顶部8px位置
        remoteStyle.top = barVisible ? 64 : 8;

        let curViewMember = detail?.participants?.find(part => part?.user?.id === screen?.media?.[0] && screen?.media?.[0])


        let src = ""
        if ((boardEnable || backgroudViewType === BackgroudViewType.BOARD) && detail?.profile?.whiteBoard?.active) {
            // 有url地址则选择url地址
            src = detail.profile.whiteBoard.url;
        } else if (user && login) {
            // 没有url地址则用token登录预加载
            // src = user.BoardLoadUrlHttps + JSON.stringify({"Token": login.Token});
        }
        // 电子白板组件
        let boardComponent = <iframe 
            id="dom_b_remote0"
            className="iframe"
            title="board"
            style={{width:"100%", height: "100%", overflow:"visible"}}
            scrolling="no" 
            frameBorder="0"
            src={src}
            />

        // 视频组件
        let remotesComponent = Array.from({length: 1}).map((item, index) => {
            return <video className="video" height="100%" width="100%" id={`dom_v_remote${index}`} autoPlay muted/>
        })
        
        // 布局组件负责浮动布局、四分屏布局、九分屏布局构造
        let layoutComponent = undefined;
        switch(layoutMode) {
            case LayoutMode.OVERLAP: { 
                // 浮动布局
                if (backgroudViewType === BackgroudViewType.BOARD) {
                    // 浮动布局时，电子白板作为最大屏展示，其余浮在其上面
                    layoutComponent = this.mkOverlapBoard(style, remotesComponent, boardComponent);
                } else if (backgroudViewType === BackgroudViewType.VIDEO) {
                    // 浮动布局时，远端视频终端作为最大屏展示，其余浮在其上面
                    layoutComponent = this.mkOverlapRemote(style, remotesComponent, boardComponent);
                }
                break;
            }
            case LayoutMode.SPLIT_4:
            case LayoutMode.SPLIT_9: {
                // 四分屏、九分屏
                layoutComponent = this.mkSplit(style, remotesComponent, boardComponent, layoutMode);
                break;
            }
            default:
                Log.error("unsupported layout mode. ", layoutMode);
                break;
        }

        return <div>
            {/* 浮动布局 or 四分屏布局 or 九分屏布局 */}
            {layoutComponent}
            {/* 投屏关闭摄像头 */}
            <div className={"report-tips " + (reportMod ? "show" : "hidden")}>
                <div className="icons">
                    <IconFont type="icon-forbidden" className="icon-forbidden"/>
                    <IconFont type="icon-camera-2" className="icon-camera-off"/>
                </div>
                <div className="description">{t('video_layout.screen_share_camera_off')}</div>
            </div>
            {/* 本端预览、远端大流视频 */}
            <div className="local-preview" style={localPreStyle}>
                <video className="video" width="100%" height="100%" id="dom_v_local_preview" autoPlay muted />
            </div>
            {/* 本端浮动窗口 */}
            <div className="local float-window" style={localStyle} onClick={(e) => { e.stopPropagation(); }}>
                <div className={"local-no-stream-tips " + (!videoEnable ? "show1" : "hidden1")}>
                    <div className="icons">
                        <IconFont type="icon-forbidden" className="icon-forbidden"/>
                        <IconFont type="icon-camera-2" className="icon-camera-off"/>
                    </div>
                    <div className="description">{t('video_layout.local_camera_off')}</div>
                </div>
                <div className="menu">
                    <div className="operation">
                        <IconFont type="icon-close" className="icon" onClick={localCloseOnClick}/>
                        <div className="description">{t('video_layout.menu.operation.close_preview')}</div> 
                    </div>
                </div>                
                <video className="video" style={localStyle} id="dom_v_local" autoPlay muted />
            </div>
            {/* 远端浮动窗口 */}
            <div className="remote float-window" style={remoteStyle} onClick={(e) => { e.stopPropagation(); }}>
                <div className={"remote-no-stream-tips " + ((curViewMember && !curViewMember.beSeen && !viewSelf) ? "show1" : "hidden1")}>
                    <div className="icons">
                        <IconFont type="icon-forbidden" className="icon-forbidden"/>
                        <IconFont type="icon-camera-2" className="icon-camera-off"/>
                    </div>
                    <div className="description">{t('video_layout.camera_off')}</div>
                </div>
                <div className="menu">
                    <div className="operation">
                        <IconFont type="icon-close" className="icon" onClick={remoteCloseOnClick}/>
                        <div className="description">{t('video_layout.menu.operation.close_preview')}</div> 
                    </div>
                </div>
                <video className="video" style={remoteStyle} id="dom_v_remote" autoPlay muted />
            </div>
            {/* 远端音频 */}
            <div className="audio-list">
                <audio id="dom_a_remote0" autoPlay />
                {/* <audio id="dom_a_remote1" autoPlay />
                <audio id="dom_a_remote2" autoPlay />
                <audio id="dom_a_remote3" autoPlay /> */}
            </div>
        </div>;
    }

    componentDidMount() {
        
    }

    render() {
        let {barVisibleOnClick, style} = this.props;
        return <div
            className="meet-content"
            style={style} 
            onClick={barVisibleOnClick}
        >
            {this.mkMain(style)}
        </div>
    }
}

let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(VideoLayout);