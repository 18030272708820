/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux"
import { Result, Button, Space, Row } from "antd";
import { withTranslation } from 'react-i18next'
import { getUrlParam } from "../../util/logic";
import { adapter } from "../../component/adapter";

import "./nomatch.css"

@withTranslation('translation', {withRef: true})
class NoMatch extends Component {

    constructor(props){
        super(props);
        
        this.state = {

        }
    }
    
    componentDidMount() {
        let { t } = this.props;
        this.setState({
            reason: t('error_page.reason.page_not_find'),
            tips: t('error_page.tips.page_not_find'),
            wechatCode: getUrlParam("code", window.location.search),
            state: getUrlParam("state", window.location.search) || "",
        });
        adapter.addPageResizeListener.bind(this)();
    }

    render() {
        let { reason, tips, wechatCode, state, height, width } = this.state;
        let { t } = this.props;

        return <div className="not-find-wrapper" style={{height: height, width: width}}>
            <Result
                className="not-find"
                status="404"
                title={reason}
                subTitle={tips}
                extra={<div className="extra">
                    <Row>
                        <Space direction="vertical">
                            <Space>
                                <Button type="primary" onClick={(e) => {
                                    window.location.href = window.location.protocol + "//" + window.location.host + `?code=${wechatCode}&state=${state}`
                                }}>{t('error_page.btn.back_home')}</Button>
                                <Button type="primary" danger onClick={(e) => {
                                    window.open('','_parent','');  
                                    window.close();  
                                }}>{t('error_page.btn.close')}</Button>
                                {
                                    wechatCode ? <Fragment>
                                        <Button type="primary" onClick={(e) => {
                                            window.location.href = `https://vmeetingtest.ludiqiao.com?code=${wechatCode}&state=${state}`
                                        }}>
                                            {t('error_page.btn.goto_test')}
                                        </Button>
                                        <Button type="primary" onClick={(e) => {
                                            window.location.href = `https://vmeetingshow.ludiqiao.com?code=${wechatCode}&state=${state}`
                                        }}>
                                            {t('error_page.btn.goto_show')}
                                        </Button>
                                    </Fragment> : undefined
                                }
                            </Space>
                            <Space>
                            {
                                wechatCode ? <Button className="btn-development" type="dashed" block ghost onClick={(e) => {
                                    window.location.href = `http://localhost:9004?code=${wechatCode}&state=${state}`
                                }}>{t('error_page.btn.goto_development')}</Button> : undefined
                            }
                            </Space>
                        </Space>
                    </Row>
                </div>}
            />
        </div>
    }
}

export default connect(
    null, 
    null
)(NoMatch);