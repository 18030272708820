
import React, { Component } from 'react';
import moment from 'moment';

class Timer extends Component {
    constructor(props){
        super(props);

        this.state = {
            
        };
    }

    addZero(value) {
        return ("0" + value).slice(-2);
    }

    format() {
        let { now } = this.state;
        let { start } = this.props;
        
        if (start) {
            let delta = now - start;
            if (delta > 0) {
                let hour = parseInt(delta / 3600);
                let min = parseInt((delta - hour * 3600) / 60);
                let second = delta % 60;
                if (hour === 0) {
                    return this.addZero(min) + ":" + this.addZero(second);
                } else {
                    return this.addZero(hour) + ":" + this.addZero(min) + ":" + this.addZero(second);
                }
            }
        }
        return undefined;
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({
                now: moment().unix()
            });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    render() {
        let { className, style } = this.props;
        return <div className={className} style={style}>
            <span>{this.format()}</span>
        </div>
    }

}

export default Timer;