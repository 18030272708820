
import { saveLoginData } from '../redux/reducers/login';
import { netRequestThunk3 } from '../util/netReqRD';

// 重新认证
export function noAuth(dispatch, path, msgBody, header, sessionInfo, method, successHd, errorHd) {
    dispatch(netRequestThunk3('/cs/v1/app/user/refresh/token', {RefreshToken: header?.RefreshToken}, {}, sessionInfo, "POST",
        (dispatch, rsp, req) => {
            dispatch(saveLoginData(rsp));
            dispatch(netRequestThunk3(path, msgBody, {...header, DeviceToken: rsp?.token, Token: rsp?.token}, sessionInfo, method, successHd, errorHd));
            return true;
        },
        errorHd,
    ));

    return true;
}

export function apiToken(props, code, ver, successFun = undefined, errorFun = undefined) {
    const { dispatch, sessionInfo } = props;
    const path = `/wechat/weblogin?code=${code}&type=web&sn=0&version=${ver}`;
    dispatch(netRequestThunk3(path, undefined, {}, sessionInfo, "GET", successFun, errorFun));
}

export function apiRefreshToken(props, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/token`;
    const header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "GET", successFun, errorFun));
}

export function apiProfile(props, login, successFun = undefined, errorFun = undefined) {
    const { dispatch, sessionInfo } = props;
    const path = '/web/profile'
    const header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, undefined, header, sessionInfo, "GET", successFun, errorFun, noAuth));
}

export function apiAccountToken(props, username, password, successFun = undefined, errorFun = undefined) {
    const { dispatch, sessionInfo } = props;
    const path = `/devices/account/token?username=${username}&password=${password}&deviceType=WEB&userType=WEB`
    dispatch(netRequestThunk3(path, undefined, {}, sessionInfo, "GET", successFun, errorFun));
}

