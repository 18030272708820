/* eslint-disable no-useless-escape */
import moment from "moment";

export function distinct(arr, key) {
    let result = []
    let obj = {}

    for (let i of arr) {
        if (!obj[i[key]]) {
            result.push(i)
            obj[i[key]] = 1
        }
    }

    return result
}

export function invalidTime(value) {
    return value === "2200-01-01 00:00:00" || value === "1970-01-01 00:00:00"
}

export function getDateDiff(diff, now) {
    let result = moment(now * 1000).format("YYYY-MM-DD HH:mm:ss") + " ";
    if (diff >= 0) {
        const monthC = diff / ( 7 * 60 * 60 * 24 * 30 );
        const weekC = diff / ( 7 * 60 * 60 * 24 );
        const dayC = diff / ( 60 * 60 * 24 );
        const hourC = diff / ( 60 * 60 );
        const minC = diff / 60;
        
        if (monthC >= 1) {
            result += "(" + parseInt(monthC) + "个月前)";
        }else if (weekC >= 1) {
            result += "(" + parseInt(weekC) + "周前)";
        }else if (dayC >= 1) {
            result += "(" + parseInt(dayC) + "天前)";
        }else if (hourC >= 1) {
            result += "(" + parseInt(hourC) + "小时前)";
        }else if (minC >= 1) {
            result += "(" + parseInt(minC) + "分钟前)";
        }else{
            result += "(刚刚)";
        } 
    }
     
    return result;
}

export function getTimeDesc(value, base = "second", limit = "hour") {
    let day = undefined;
    let hour = undefined;
    let minute = undefined;
    let second = undefined;
    switch(base){
        case "second":
            day = Math.floor(value / (3600 * 24));
            hour = Math.floor((value - day * 3600 * 24) / 3600);
            minute = Math.floor((value - day * 3600 * 24 - hour * 3600) / 60);
            second = value % 60;
            break;
        case "minute":
            day = Math.floor(value / (60 * 24));
            hour = Math.floor((value - day * 60 * 24) / 60);
            minute = Math.floor(value - day * 60 * 24 - hour * 60);
            break;
        default:
            break;
    }
    
    const content = (day ? `${day}天` : "") + (hour ? `${hour}小时` : "") + (minute ? `${minute}分` : "") + (second ? `${second}秒` : "")
    return content || "0秒";
}

export function getTimeDesc2(value, t) {
    let day = Math.floor(value / (3600 * 24));
    let hour = Math.floor((value - day * 3600 * 24) / 3600);
    let minute = Math.floor((value - day * 3600 * 24 - hour * 3600) / 60);
    let second = value % 60;
    const content = (day ? `${day}${t('common.day')}` : "") + (hour ? `${hour}${t('common.hour')}` : "") + (minute ? `${minute}${t(second ? 'common.minute' : 'common.minutes')}` : "") + (second ? `${second}${t('common.second')}` : "")
    return content || `0${t('common.second')}`;
}

export function getNetWorkDesc(value, unit="bps") {
    let gbps = value / (1024 * 1024 * 1024)
    let mbps = value / (1024 * 1024)
    let kbps = value / (1024)
    if (gbps >= 1) {
        return `${gbps.toFixed(2)} G${unit}`
    } else if (mbps >= 1) {
        return `${mbps.toFixed(2)} M${unit}`
    } else if (kbps >= 1) {
        return `${kbps.toFixed(2)} K${unit}`
    } else {
        return `${value} ${unit}`
    }
}


export function getUrlParam(name, str) {
    // console.group()
    // console.log("name", name);
    // console.log("str", str);
    try {
        const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
        const r = str.substr(1).match(reg);
        // console.log("r", r)
        // console.groupEnd();
        if (r != null) return decodeURIComponent(r[2]); return null;
    } catch {

    }
    return null;
}

// 工具方法
export function formatJson(json, options) {
    var reg = null,
        formatted = '',
        pad = 0,
        PADDING = '    '; // one can also use '\t' or a different number of spaces
    // optional settings
    options = options || {};
    // remove newline where '{' or '[' follows ':'
    options.newlineAfterColonIfBeforeBraceOrBracket = !!options.newlineAfterColonIfBeforeBraceOrBracket;
    // use a space after a colon
    options.spaceAfterColon = !!options.spaceAfterColon;
    PADDING = (options.padding === undefined && '    ') || options.padding;
    // begin formatting...

    // make sure we start with the JSON as a string
    if (typeof json !== 'string') {
        json = JSON.stringify(json);
    }
    // parse and stringify in order to remove extra whitespace
    try {
        json = JSON.parse(json);
        json = JSON.stringify(json);
    } catch (error) {
        return '';
    }
    

    // add newline before and after curly braces
    reg = /([\{\}])/g;
    json = json.replace(reg, '\r\n$1\r\n');

    // add newline before and after square brackets
    reg = /([\[\]])/g;
    json = json.replace(reg, '\r\n$1\r\n');

    // add newline after comma
    reg = /(\,)/g;
    json = json.replace(reg, '$1\r\n');

    // remove multiple newlines
    reg = /(\r\n\r\n)/g;
    json = json.replace(reg, '\r\n');

    // remove newlines before commas
    reg = /\r\n\,/g;
    json = json.replace(reg, ',');

    // optional formatting...
    if (!options.newlineAfterColonIfBeforeBraceOrBracket) {
        reg = /\:\r\n\{/g;
        json = json.replace(reg, ':{');
        reg = /\:\r\n\[/g;
        json = json.replace(reg, ':[');
    }
    if (options.spaceAfterColon) {
        reg = /\:/g;
        json = json.replace(reg, ': ');
    }

    json.split('\r\n').forEach(function(node, index) {
        var i = 0,
            indent = 0,
            padding = '';

        if (node.match(/\{$/) || node.match(/\[$/)) {
            indent = 1;
        } else if (node.match(/\}/) || node.match(/\]/)) {
            if (pad !== 0) {
                pad -= 1;
            }
        } else {
            indent = 0;
        }

        for (i = 0; i < pad; i++) {
            padding += PADDING;
        }
        formatted += padding + node + '\r\n';
        pad += indent;
    });
    return formatted;
};

export function printObject(o, sp = '\r\n', root = '', indent = 0) {
    return formatJson(o);
}

export function uploadInfo(profile, login, version, explorerInfo, mediaDevice) {
    let name = `${profile.name}(${profile.phone})`
    let newProfile = Object.assign({}, profile)
    newProfile.name = encodeURI(newProfile.name);
    let info = "" 
        + `[Profile]${printObject(profile)}\r\n` 
        + `[Login]${printObject(login)}\r\n` 
        + `[Version]${printObject(version)}\r\n`
        + `[Explorer]${printObject(explorerInfo)}\r\n`
        + `[Device:Camera]\r\npermission: ${mediaDevice.cameraPerm}\r\ncameras:${printObject(mediaDevice.cameras)}\r\n`
        + `[Device:Microphone]\r\npermission: ${mediaDevice.microphonePerm}\r\nmicrophones:${printObject(mediaDevice.microphones)}\r\n`
        + `[Device:Loudspeaker]\r\npermission: ${mediaDevice.loudspeakerPerm}\r\nloudspeakers:${printObject(mediaDevice.loudspeakers)}\r\n`

    return {
        name: name,
        info: info,
    }
}

/**
 * @function codeFormatter
 * @description 
 * 1. 自动补位会议号为9位，不足9位前面自动补0 
 * 2. 按照每3位一个空格进行格式化
 * @param code 需要格式化的会议号
 * @return 已经格式化的会议号
 */
export function codeFormatter(code) {
    return `${code ? ("00000000" + `${code}`.replace(/\D/g, '')).slice(-9) : ""}`.replace(/\D/g, '').replace(/(\d{3})(?=(\d)+(?!\d))/g, '$1 ')
}

/**
 * @function codeFix
 * @description 自动补位会议号为9位，不足9位前面自动补0
 * @param code 需要补位的会议号
 * @return 已经补位的会议号
 */
export function codeFix(code) {
    return code ? ("00000000" + `${code}`.replace(/\D/g, '')).slice(-9) : ""
}

// 简单加密
export function compile(code) {
    var c=String.fromCharCode(code.charCodeAt(0)+code.length);
    for(var i=1;i<code.length;i++){
        c+=String.fromCharCode(code.charCodeAt(i)+code.charCodeAt(i-1));
    }
    return(escape(c));
}

// 解密
export function uncompile(code) {
    let c = ''
    if (code?.length) {
        code = unescape(code);
        c = String.fromCharCode(code.charCodeAt(0) - code.length);
        for(var i=1;i<code.length;i++){
            c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
        }
    }
    return c;
}