import React, { Component } from 'react';
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller';
import { List, Tooltip, Tag, Space, Button} from 'antd';
import { withTranslation } from 'react-i18next'
import { IconFont } from '../../util/iconFont';
import { Log } from "../../util/log";
import { getLoginData, getUserData, } from '../../redux/reducers/login'

import './memberList.css'

@withTranslation('translation', {withRef: true})
class MemberList extends Component {
    constructor(props){
        super(props);
        
        this.state = {

        };
    }

    componentDidMount() {
    }

    render() {
        let { t, conferenceInfo, login, screen, screenSharerId, memberViewOnClick } = this.props;

        let members = conferenceInfo?.participants || [];
        let newMembers = [];

        let meIndex = members.findIndex((m) => login?.owner === m?.user?.id)
        let creatorIndex = members.findIndex((m) => conferenceInfo?.conference?.creatorId === m?.user?.id)

        // 排序
        members.forEach((m, i) => {
            if (i !== meIndex && i !== creatorIndex) {
                if (m?.status === "ONLINE") {
                    // 在线的放前面
                    newMembers.unshift(m);
                } else {
                    // 不在线的放后面
                    newMembers.push(m);
                }
            }
        });

        if (creatorIndex !== meIndex) {
            // 创建者放前面
            newMembers.unshift(members[creatorIndex]);
        }
        // 自己放最前面
        newMembers.unshift(members[meIndex]);

        return (
            <div className="infinite" 
                style={{height: this.props.height - 64 - 43}}
                >
                <InfiniteScroll
                    initialLoad={true}
                    pageStart={0}
                    loadMore={() => {}}
                    hasMore={false}
                    useWindow={false}
                    >
                    <List
                        className="list"
                        dataSource={newMembers}
                        renderItem={(item, index) => {
                            if (!item || item.rcExpandScreen) {
                                return undefined;
                            }
                            let iconMe = undefined;
                            let iconCreator = undefined;
                            let iconBoardOwner = undefined;
                            let iconView = undefined;
                            let iconMute = undefined;
                            let iconCameraOff = undefined;
                            let iconScreenShare = undefined;
                            let iconDefinition = undefined;
                            let offline = item.status !== "ONLINE";
                            // console.log(item)
                            // 是否是自己
                            if (login.owner === item.user?.id) {
                                iconMe = <Tooltip placement="bottom" title={t('member_list.me')}>
                                    <Tag className="icon" color={'#597ef7'}>{t('member_list.me')}</Tag>
                                </Tooltip>;
                            }

                            // 是否是创建者
                            if (conferenceInfo?.conference?.creatorId === item.user?.id) {
                                iconCreator = <Tooltip placement="bottom" title={t('member_list.creator')}>
                                    <Tag className="icon" color={'#ffc53d'}>{t('member_list.creator')}</Tag>
                                </Tooltip>;
                            }

                            // 是否是白板拥有者
                            if (conferenceInfo?.profile?.whiteBoard?.controllerId && conferenceInfo?.profile?.whiteBoard?.controllerId === item.device?.id && !offline) {
                                iconBoardOwner = <Tooltip placement="bottomLeft" title={t('member_list.board_owner')}>
                                    <IconFont className="icon" type="icon-board-flag"/>
                                </Tooltip>;
                            }

                            // 是否静音
                            if (item.mute && !offline) {
                                iconMute = <Tooltip placement="bottomLeft" title={t('member_list.mute')}>
                                    <IconFont className="icon" type="icon-audio-lock"/>
                                </Tooltip>;
                            }

                            // 是否关闭摄像头
                            if (!item.beSeen && !offline) {
                                iconCameraOff = <Tooltip placement="bottomLeft" title={t('member_list.camera_off')}>
                                    <IconFont className="icon" type="icon-video-camera-off"/>
                                </Tooltip>;
                            }

                            // 是否是屏幕共享
                            if (screenSharerId && screenSharerId === item.device?.id && !offline) {
                                iconScreenShare = <Tooltip placement="bottomLeft" title={t('member_list.screen_share')}>
                                    <IconFont className="icon" type="icon-screen-share2"/>
                                </Tooltip>;
                            }

                            // 分辨率大小
                            let findMediaIndex = screen?.media?.findIndex((media) => media === item.user?.id);
                            if (findMediaIndex !== -1 && !offline) {
                                iconView = <Tooltip placement="bottomLeft" title={t('member_list.preview')}>
                                    <IconFont className="icon" type="icon-view"/>
                                </Tooltip>;
                                let title = undefined;
                                let tag = undefined;
                                let color = undefined;
                                switch(findMediaIndex) {
                                    case 0:
                                        // 默认第一路就是高清
                                        title = 'member_list.definition.high';
                                        tag = 'HD';
                                        color = '#9254de';
                                        break;
                                    default:
                                        // 其余路是低清
                                        title = 'member_list.definition.low';
                                        tag = 'LD';
                                        color = '#36cfc9';
                                        break;
                                }
                                iconDefinition = <Tooltip placement="bottomLeft" title={t(title)}>
                                    <Tag className="icon" color={color}>{tag}</Tag>
                                </Tooltip>;
                            } 

                            // 是否使能操作菜单
                            let operationEnable = findMediaIndex !== 0 && login.owner !== item.user?.id && !offline;

                            // 头像
                            let avatarUrl = item.user?.avatarUrl.replace(/http:/, 'https:');

                            // 组件
                            return <List.Item className="item" key={item.user?.id}>
                                <div className="content">
                                    <div className="title">
                                        <img className={"img "+ (offline ? "disable" : "enable")} alt="" src={avatarUrl}/>
                                        <span className={"name " + (offline ? "disable" : "enable")}>
                                            {item.user?.nickName}
                                        </span>
                                        <span className="tags">
                                            { iconMe }
                                            { iconCreator }
                                        </span>
                                    </div>
                                    <div className="icons">
                                        <Space>
                                            { iconDefinition }
                                            { iconView }
                                            { iconMute }
                                            { iconCameraOff }
                                            { iconScreenShare }
                                            { iconBoardOwner }
                                            <Tooltip placement="bottomLeft" title={t(!offline ? "member_list.status.joined" : "member_list.status.not_joined")}>
                                                <IconFont className="icon" type={!offline ? "icon-online" : "icon-offline"}/>
                                            </Tooltip>
                                        </Space>
                                    </div>
                                </div>
                                <div className="operation">
                                    <Button icon={<IconFont className="icon" type={operationEnable ? "icon-switch-video-enable" : "icon-switch-video-disable"} />}
                                        className="button" type="link" disabled={!operationEnable} onClick={(e) => {
                                        Log.info(this.props.This.sipSessionInfo(), `ui event: switch video onclick`);
                                        if (memberViewOnClick) {
                                            memberViewOnClick([item.user])
                                        }
                                    }}>
                                        {t('member_list.operation.switch_video')}
                                    </Button>
                                </div>
                            </List.Item>
                        }}
                    />
                </InfiniteScroll>
            </div>
        );
    }
}

let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
)(MemberList);