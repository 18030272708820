import React, { Component } from 'react';
import TweenOne from 'rc-tween-one';
import SvgMorphPlugin from 'rc-tween-one/lib/plugin/SvgMorphPlugin';

import "./tweenOneIcon.css";

TweenOne.plugins.push(SvgMorphPlugin);

class TweenOneIcon extends Component {

    componentDidMount() {
        this.from = this.props.from;
        this.to = this.props.to;
        this.animation = {
            repeat: 0, 
            ease: 'easeInCubic',
            ...this.from,
        };
    }

    render() {
        let { enable, className } = this.props;
        return (
            <div className={className}>
                <svg className="icon-svg" viewBox="0 0 1024 1024">
                    <TweenOne
                        animation={this.animation}
                        paused={false}
                        reverse={!enable}
                        moment={null}
                        component="path"
                        attr="attr"
                        {...this.to}
                    />
                </svg>
            </div>
        );
    }
}

export default TweenOneIcon;