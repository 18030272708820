/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux"
import moment from "moment";
import { Layout, Typography, Button, Row, Col, Input, Tag, Popconfirm, Drawer, Modal, Tooltip, Menu, Dropdown, List, Space, Collapse, Descriptions, Tabs, Carousel, Checkbox, Slider } from "antd";
import { CaretRightOutlined, InfoCircleOutlined, ExclamationCircleOutlined, LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { withTranslation } from 'react-i18next'
import { IconFont } from "../../util/iconFont";
import { getLoginData, getUserData, } from "../../redux/reducers/login"
import { apiConferenceBoardStart, apiConferenceBoardStop, 
    apiConferenceCreate, apiConferenceFind, apiConferenceJoin, apiConferenceGet, apiConferenceDel, apiConferenceExit,
    apiConferenceScreen, apiConferenceUnmute, apiConferenceMute, apiConferenceCameraOn, apiConferenceCameraOff,
    apiConferenceReportApply, apiConferenceReportCancel
} from "../../api/conference";
import Login from "../../component/login/login";
import MemberList from "../../component/memberList/memberList";
import Timer from "../../component/timer/timer";
import Detail from "../../component/detail/detail";
import Setting from "../../component/setting/setting";
import Rtc from "../../component/rtc/rtc";
import VideoLayout from "../../component/meet/videoLayout/videoLayout";
import EnDisable from '../../component/tweenOneIcon/enDisable';
import EnDisable2 from "../../component/tweenOneIcon/enDisable2";
import { Log } from "../../util/log";
import { LayoutMode, Views } from "../../api/structure";
import { Fsm, State as FsmState } from "../../component/fsm/fsm";
import { Stream, Kind, Dir, } from "../../component/media/stream";
import { device as mediaDevice } from "../../component/media/device";
import { adapter } from '../../component/adapter';
import { DialHistory } from "../../component/localStorage/dialHistory";
import { DevicePreference } from "../../component/localStorage/devicePreference";
import { getTimeDesc, getDateDiff, getUrlParam, formatJson, printObject, uploadInfo, codeFormatter, codeFix} from "../../util/logic";
import { version, config, } from "../../util/version";
import { MenuState, BoardShareState, ScreenShareState, LiveState, BackgroudViewType, } from "../../component/common/common";
import { message } from "../../util/message";
import { sipInit, sipConfig, sipRegister, sipUnregister, sipCall, sipHangup, sipMute, sipSwitchVideo, sipInitCallSession, sipInitRegisterSession, sipInitStack, sipCallSessionOnTerminated, sipSessionInfo} from "../../component/sip/sip";
import { notification } from "../../component/media/notification";

import "./main.less"
import "./drawer.less"

let QRCode = require('qrcode.react');

@withTranslation('translation', {withRef: true})
class Main extends Component {
    constructor(props){
        super(props);

        this.wechatOnLogin = this.wechatOnLogin.bind(this);

        this.wsOnOpen = this.wsOnOpen.bind(this);
        this.wsOnMessage = this.wsOnMessage.bind(this);
        this.wsOnClose = this.wsOnClose.bind(this);

        this.deviceLoopPermission = this.deviceLoopPermission.bind(this);
        
        this.mediaPreviewOnSuccess = this.mediaPreviewOnSuccess.bind(this);
        this.mediaPreviewOnError = this.mediaPreviewOnError.bind(this);

        this.pageVisible = this.pageVisible.bind(this);
        this.pageInvisible = this.pageInvisible.bind(this);

        this.sipSessionInfo = sipSessionInfo.bind(this);
        this.sipInit = sipInit.bind(this);
        this.sipConfig = sipConfig.bind(this);
        this.sipRegister = sipRegister.bind(this);
        this.sipUnregister = sipUnregister.bind(this);
        this.sipCall = sipCall.bind(this);
        this.sipHangup = sipHangup.bind(this);
        this.sipMute = sipMute.bind(this);
        this.sipSwitchVideo = sipSwitchVideo.bind(this);
        this.sipInitStack = sipInitStack.bind(this);
        this.sipInitCallSession = sipInitCallSession.bind(this);
        this.sipInitRegisterSession = sipInitRegisterSession.bind(this);

        this.meetJoinOnClick = this.meetJoinOnClick.bind(this);
        this.meetJoinAgainOnClick = this.meetJoinAgainOnClick.bind(this);
        this.meetCreateAndJoinOnClick = this.meetCreateAndJoinOnClick.bind(this);
        this.meetDelOnClick = this.meetDelOnClick.bind(this);
        this.meetReportOnClick = this.meetReportOnClick.bind(this);

        this.iconOnToggle = this.iconOnToggle.bind(this);
        this.iconCopyOnClick = this.iconCopyOnClick.bind(this);
        this.barVisibleOnClick = this.barVisibleOnClick.bind(this);

        this.zoomInOnClick = this.zoomInOnClick.bind(this);
        this.zoomOutOnClick = this.zoomOutOnClick.bind(this);
        this.videoSwitchOnClick = this.videoSwitchOnClick.bind(this);
        this.videoDivOnMouseMove = this.videoDivOnMouseMove.bind(this);

        this.menuItemOnChange = this.menuItemOnChange.bind(this);
        this.menuItemBoardShareOnChange = this.menuItemBoardShareOnChange.bind(this);
        this.menuItemScreenShareOnChange = this.menuItemScreenShareOnChange.bind(this);

        let dialHistory = new DialHistory();

        let stream = new Stream(this);
        stream.preview.onGetMediaSuccess = this.mediaPreviewOnSuccess;
        stream.preview.onGetMediaError = this.mediaPreviewOnError;

        this.state = {
            fsm: new Fsm(this),
            stream: stream,

            dialHistory: dialHistory,
            devicePreference: new DevicePreference(),

            ...this.initModal(),

            permission: true, // 权限是否已经获取
            loginStatus: 'disconnected', // 登录状态
            websocketStatus: 'disconnected', // websocket连接状态
            websocketRestartCnt: 0,

            avInfoInterval: 5, // 单位（秒）
            barVisibleInterval: 10, //单位（秒）

            code: dialHistory.getFirst('code'), // 当前拨号的号码

            ...this.sipInitStack(),
            ...this.initCallSession(),
        };
    }

    initModal() {
        return {
            ...this.pageInvisible(),
            barVisible: true,
            memberVisible: false,
            detailVisible: false,
            rtcInfoVisible: false,
            hangupVisible: false,
            settingVisible: false,
            permissionHelpVisible: false,
            lastPageVisiable: undefined,
        }
    }

    initCallSession() {
        this.fsmTimeout = {};
        // 是否是主动入会（界面点击 or 被服务器SIP呼入）
        this.manual = false;
        this.mediaStreamCtrlSeq = 0;
        this.previewLocal = false;
        this.previewRemote = false;
        this.previewRemoteCnt = 0;

        return { 

            ...this.sipInitCallSession(),

            // 按钮
            videoEnable: true,
            audioEnable: true,

            localEnable: true,
            boardEnable: false, 
            remotesEnable: false,

            optionMicrophoneEnable: window.localStorage && window.localStorage.getItem("optionMicrophoneEnable") !== "false",
            optionCameraEnable: window.localStorage && window.localStorage.getItem("optionCameraEnable") !== "false",
            // optionLoudspeakerEnable: window.localStorage && window.localStorage.getItem("optionLoudspeakerEnable") !== "false",
            optionLoudspeakerEnable: true,

            viewSelf: false,

            // 布局模式
            layoutMode: LayoutMode.OVERLAP,

            // 浮动模式下底是白板还是会场画面
            backgroudViewType: BackgroudViewType.VIDEO,
            backgroudViewIndex: 0,

            // 菜单锁定
            menuLock: MenuState.UNLOCK,

            // 白板共享
            boardShare: BoardShareState.STOP,

            // 屏幕共享
            screenShare: ScreenShareState.STOP,
            screenSharerId: undefined,
            
            // 直播状态
            liveState: LiveState.STOP,

            // 局域网 or 云端 会议
            isInternalMs: undefined,

            // 会议主题
            theme: undefined,

            // 会议接入码
            code: undefined,

            // 会议SIP号
            number: undefined,

            // 会议详情
            beforeDetail: undefined,
            detail: undefined,

            // 已存在的会议
            exist: undefined,

            // 创建者
            creator: undefined,

            // 选流信息
            views: new Views(LayoutMode.OVERLAP),
            screen: undefined,

            // ice状态
            iceStatus: undefined,
            iceDescription: undefined,

            // RTC报告
            asReports: undefined,
            arReports: undefined, 
            vsReports: undefined, 
            vrReports: undefined,
        }
    }

    // 向导、创建会议、加入会议、远程投屏框为互斥框
    pageVisible(page = undefined) {
        let menu;
        page = page || this.state.lastPageVisiable || 'wizzard'

        let { stream, devicePreference } = this.state;
        switch (page) {
            case 'create':
                menu = {
                    conferenceCreateVisible: true,
                    conferenceJoinVisible: false,
                    conferenceReportVisible: false,
                    conferenceWizzardVisible: false,
                    lastPageVisiable: page,
                }
                stream.preview.start(devicePreference.get('camera', mediaDevice));
                break;
            
            case 'report':
                menu = {
                    conferenceCreateVisible: false,
                    conferenceJoinVisible: false,
                    conferenceReportVisible: true,
                    conferenceWizzardVisible: false,
                    lastPageVisiable: page,
                }
                stream.preview.stop()
                break;
            
            case 'join':
                menu = {
                    conferenceCreateVisible: false,
                    conferenceJoinVisible: true,
                    conferenceReportVisible: false,
                    conferenceWizzardVisible: false,
                    lastPageVisiable: page,
                }
                stream.preview.start(devicePreference.get('camera', mediaDevice));
                break;
            
            case 'wizzard':
            default:
                menu = {
                    conferenceCreateVisible: false,
                    conferenceJoinVisible: false,
                    conferenceReportVisible: false,
                    conferenceWizzardVisible: true,
                    lastPageVisiable: page,
                }
                stream.preview.stop()
                break;
        }

        return menu;
    }

    pageInvisible(last = undefined) {
        let page = {
            conferenceCreateVisible: false,
            conferenceJoinVisible: false,
            conferenceReportVisible: false,
            conferenceWizzardVisible: false,
        }
        if (last) {
            Object.assign(page, {
                lastPageVisiable: last,
            })
        }
        return page;
    }

    // 退出逻辑
    exit(isUnregister = true, immediate = false) {
        Log.info(this.sipSessionInfo(), `exit and jump to login page. unregister: ${isUnregister} immediate: ${immediate}`)
        let that = this;
        
        // 呼叫中需要挂断呼叫
        let { number, fsm } = this.state;
        if (fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
            this.meetHangupOnClick({}, number);
        }

        // 注销
        if (isUnregister) {
            this.sipUnregister();
        }
        
        function exitFun() {
            let { websocket, stream, fsm } = that.state;
            // websocket关闭
            if (websocket) {
                if (that.wsHbTimer) {
                    clearInterval(that.wsHbTimer);
                    that.wsHbTimer = null;
                }
                websocket.onopen = null;
                websocket.onmessage = null;
                websocket.onerror = null;
                websocket.onclose = null;
                websocket.close();
            }
            // 停止所有流
            if (stream) {
                stream.report.stop(Kind.ALL, Dir.ALL);
                stream.preview.stop();
            }
            // 状态机转移到初始状态
            if (fsm) {
                fsm.transfer(FsmState.Login.INIT);
            }
            // 销毁所有对话框
            message.destroy();
            
            // 销毁通知框
            if (that.notify) {
                that.notify.close();
            }

            // reload页面
            if (window.location.pathname !== "/version_error") {
                window.location.replace("http://" + window.location.host)
            }
        }

        if (this.exitTimer) {
            clearTimeout(this.exitTimer);
            this.exitTimer = null;
        }
        if (immediate) {
            exitFun();
        } else {
            let seconds = 3;
            this.exitTimer = setTimeout(() => {
                that.exitTimer = null;
                exitFun();
            }, seconds * 1000);
            Log.debug(this.sipSessionInfo(), `start timer(${this.exitTimer} after ${seconds}s) to make sure exit process is ok`);
        }
    }

    viewSelect(members, isMessage = true) {
        let that = this;
        let { screenSharerId, number, stream } = this.state;
        let { t } = this.props;
        Log.info(this.sipSessionInfo(), `select view to member: ${printObject(members)}`)
        
        if (screenSharerId?.length > 0) {
            message.error(t('view.message.screen.desktop_share_disable'));
            return;
        }

        // 选流之前先清掉主屏的流
        stream.deattachVideoRecvById(0);
        that.selectViewTimer = setTimeout(() => {
            stream.reattachAllVideoRecv();
            that.selectViewTimer = undefined;
        }, 3000);
        apiConferenceScreen(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), number, members.map((member) => member.id), (dispatch, rsp, req) => {
            if (isMessage) {
                message.success(t('view.message.api.screen.success'));
            }
        }, (dispatch, rsp, req) => {
            if (that.selectViewTimer) {
                clearTimeout(that.selectViewTimer)
                that.selectViewTimer = undefined;
            }
            stream.reattachVideoRecvById(0, 0);
            if (isMessage) {
                message.error(t('view.message.api.screen.error'));
            }
        })
    }

    mediaStreamProcess(content) {
        let { detail, stream, videoEnable, screenShare } = this.state;
        let { login } = this.props;

        if (!content) {
            content = {
                confId: detail?.conference?.id,
                sipNum: login?.owner,
                sendBig: 1,
                sendMiddle: 1,
                sendSmall: 1,
                seq: 0,
            }
        }

        this.mediaStreamCtrl = content;
        if (detail?.conference?.id === content.confId && login?.owner === content.sipNum && this.mediaStreamCtrlSeq <= content.seq) {
            this.mediaStreamCtrlSeq = content.seq;
            clearTimeout(this.mediaStreamCtrlTimer)
            
            let loop = () => {
                let streams = stream.report.getStreams(Kind.VIDEO, Dir.SEND)
                if (streams?.length === 0) {
                    this.mediaStreamCtrlTimer = setTimeout(() => {
                        this.mediaStreamCtrlTimer = 0;
                        loop()
                    }, 500);
                } else {
                    streams.forEach((stream) => {
                        if (stream.type && window.tmedia_cmp(stream.type, window.tmedia_type_e.HIGH_VIDEO)) {
                            if (screenShare === ScreenShareState.START) {
                                Log.info(this.sipSessionInfo(), "switch stream", stream.type?.s_name, true, "on account of screen share", screenShare);
                                stream.getTracks().forEach(t => t.enabled = true);
                            } else if (videoEnable) {
                                Log.info(this.sipSessionInfo(), "switch stream", stream.type?.s_name, !!content.sendBig, "on account of server's indication", content.sendBig);
                                stream.getTracks().forEach(t => t.enabled = !!content.sendBig);
                            } else {
                                Log.info(this.sipSessionInfo(), "switch stream", stream.type?.s_name, false, "on account of user's operation", videoEnable);
                                stream.getTracks().forEach(t => t.enabled = false);
                            }
                        } else if (stream.type && window.tmedia_cmp(stream.type, window.tmedia_type_e.LOW_VIDEO)) {
                            if (videoEnable) {
                                Log.info(this.sipSessionInfo(), "switch stream", stream.type?.s_name, !!content.sendSmall, "on account of server's indication", content.sendSmall);
                                stream.getTracks().forEach(t => t.enabled = !!content.sendSmall);
                            } else {
                                Log.info(this.sipSessionInfo(), "switch stream", stream.type?.s_name, false, "on account of user's operation", videoEnable);
                                stream.getTracks().forEach(t => t.enabled = false);
                            }
                        }
                    })
                }
            }

            loop()
        } else {
            Log.warn(this.sipSessionInfo(), "switch stream do nothing", detail?.conference?.id, login?.owner, this.mediaStreamCtrlSeq)
        }
    }

    conferenceAddr(detail) {
        return window.location.protocol + "//" + window.location.host + window.location.pathname + "?conference=" + (detail?.conference?.accessCode || "");
    }

    liveAddr(detail) {
        return window.location.protocol + "//" + window.location.host + "/vlive?conference=" + (detail?.conference?.accessCode || "");
    }

    uiPreproc(fun) {
        let { websocketStatus, sipStackStatus } = this.state;
        let { t } = this.props;

        let cnt = 3;
        let interval = 1;
        function loop() {
            if (websocketStatus !== 'success' || sipStackStatus !== 'started') {
                if (cnt-- > 0) {
                    this.statusErrorUiProcTimer = setTimeout(() => {
                        loop();
                    }, interval * 1000);
                    Log.warn(this.sipSessionInfo(), `server status is not ok, just do function later. sipStackStatus: ${sipStackStatus}, websocketStatus: ${websocketStatus}`)
                } else {
                    message.error(t('websocket.message.check'))
                }
            } else if (fun) {
                fun.bind(this)();
            }
        }
        loop.bind(this)();
    }

    deviceOnChange(event) {
        // Log.info(this.sipSessionInfo(), `device on change`)

        let { fsm, stream } = this.state;
        let { t } = this.props;
        if (fsm.test(FsmState.Call.INIT)) {
            if (!stream.preview.status || mediaDevice.cameraPerm !== mediaDevice.permission.allow || mediaDevice.microphonePerm !== mediaDevice.permission.allow ) {
                this.deviceLoopPermission();
            }
        }

        this.commonOnChange();
    }

    commonOnChange(e) {
        this.forceUpdate();
    }

    deviceLoopPermission() {
        let that = this;
        let { t } = this.props;
        let { devicePreference, stream } = this.state;

        let ret = true;
        let interval = 1000;
        let times = 10;
        let cnt = 1;
        function loop() {
            // ret = stream.preview.start(devicePreference.get('camera', mediaDevice));
            if (!ret || !stream.preview.status || mediaDevice.microphonePerm !== mediaDevice.permission.allow || mediaDevice.cameraPerm !== mediaDevice.permission.allow) {
                if (!that.getPermissionTimer) {
                    that.getPermissionTimer = setTimeout(() => {
                        mediaDevice.getPermission();
                        if (cnt % times === 0) {
                            message.error(t('login.message.preview.timeout'))
                        }
                        that.getPermissionTimer = 0;
                        loop();
                    }, interval);
                }
            } else {
                that.pageVisible();
                that.getPermissionTimer = 0;
                that.sipConfig({
                    video_input_device_id: devicePreference.get('camera', mediaDevice),
                    audio_input_device_id: devicePreference.get('microphone', mediaDevice),
                    audio_output_device_id: devicePreference.get('loudspeaker', mediaDevice),
                })
            }
        }
        if (!that.getPermissionTimer) {
            Log.debug(that.sipSessionInfo(), `start a loop to start preview per ${interval/ 1000}s`)
            loop();
        }
    }

    // 打开本地预览成功
    mediaPreviewOnSuccess(stream) {
        Log.info(this.sipSessionInfo(), `start the local preview success. stream(${stream.id})`)
        this.setState({
            permission: true,
        })
    }

    // 打开本地预览失败
    mediaPreviewOnError(error) {
        let { t } = this.props;
        Log.error(this.sipSessionInfo(), `start the local preview error: ${error.toString()}`)
        message.error(t('preview.message.error'));
        this.setState({
            permission: false,
        })
        setTimeout(() => {
            this.deviceLoopPermission();
        }, 5000);
    }
    
    // 菜单栏自动隐藏功能开启
    timerBarVisibleStart() {
        this.timerBarVisibleStop();
        let { barVisibleInterval } = this.state;
        this.barTimer = setInterval(() => {
            let { menuLock } = this.state;
            // 鼠标没动静则把bar隐藏起来
            if (!this.mouseCurX && !this.mouseCurY){
                if (menuLock === MenuState.UNLOCK) {
                    this.setState({
                        barVisible: false,
                    })
                }
            } else {
                this.mouseCurX = undefined;
                this.mouseCurY = undefined;
            }
        }, barVisibleInterval * 1000)
        Log.debug(this.sipSessionInfo(), `start timer(${this.barTimer} per ${barVisibleInterval}s) to make the bar invisible`);
    }

    // 菜单栏自动隐藏功能停止
    timerBarVisibleStop() {
        if (this.barTimer) {
            Log.debug(this.sipSessionInfo(), `stop timer(${this.barTimer}), do not check the bar visible/invisible now`);
            clearTimeout(this.barTimer);
            this.barTimer = null;
        }
    }

    // 解析websocket消息
    wsParse(msg) {
        let jsonStr = "{}";
        let content = {};
        let type = undefined;
        if (msg.startsWith("profile{")) {
            type = "profile";
            jsonStr = msg.substr("profile".length);
        } else if (msg.startsWith("Conference{")) {
            type = "conference";
            jsonStr = msg.substr("Conference".length);
        } else if (msg.startsWith("Screen{")) {
            type = "screen";
            jsonStr = msg.substr("Screen".length);
        } else if (msg.startsWith("conferenceSendVideo{")) {
            type = "conferenceSendVideo";
            jsonStr = msg.substr("conferenceSendVideo".length);
        } else if (msg.startsWith("ScreenShare{")) {
            type = "screenShare";
            jsonStr = msg.substr("ScreenShare".length);
        } else if (msg.startsWith("CallEnd{")) {
            type = "callEnd";
            jsonStr = msg.substr("CallEnd".length);
        } else if (msg.startsWith("CallCreate{")) {
            type = "callCreate";
            jsonStr = msg.substr("CallCreate".length);
        } else if (msg.startsWith("CallJoin{")) {
            type = "callJoin";
            jsonStr = msg.substr("CallJoin".length);
        } else if (msg.startsWith("DesktopShare{")) {
            type = "desktopShare";
            jsonStr = msg.substr("DesktopShare".length);
        } else if (msg.startsWith("DeviceConflict")) {
            type = "deviceConflict";
        } else if (msg.startsWith("ShowOrHideSmallWindows{")) {
            type = "showOrHideSmallWindows";
        } else if (msg.startsWith("WhiteBoard{")) {
            type = "whiteBoard";
            jsonStr = msg.substr("WhiteBoard".length);
        } else if (msg.startsWith("UploadLog{")) {
            type = "uploadLog";
        } else if (msg.startsWith("PerScreenShare{")) {
            type = "perScreenShare";
            jsonStr = msg.substr("PerScreenShare".length);
        } else if (msg.startsWith("conferenceMaxMember{")) {
            type = "conferenceMaxMember";
            jsonStr = msg.substr("conferenceMaxMember".length);
        } else if (msg.startsWith("version{")) {
            type = "version";
            jsonStr = msg.substr("version".length);
        } else {
            Log.error(this.sipSessionInfo(), `unsupported message. msg: ${msg}`);
        }
        
        try {
            content = jsonStr.length > 0 ? JSON.parse(jsonStr) : {};
        } catch (error) {
            Log.error(this.sipSessionInfo(), `parse msg failed. detail: ${error.toString()} msg: ${jsonStr}`);
        }
        return {
            type: type,
            content: content,
        };
    }

    // websocket打开通知
    wsOnOpen(e) {
        let that = this;
        this.wsHbTimer = setInterval(() => {
            let {websocketStatus, websocket} = that.state;
            if (websocketStatus === 'success' && websocket) {
                websocket.send('Hearbeat{}');
            }
        }, config.websocket.heartbeatInterval * 1000);

        Log.info(this.sipSessionInfo(), `websocket event open and start timer(${this.wsHbTimer} per ${config.websocket.heartbeatInterval}s) send heartBeat to keep alive)`);

        this.setState({
            websocketStatus: 'success',
            websocketRestartCnt: 0,
        })
    }

    // websocket消息通知
    wsOnMessage(e) {
        let { fsm, sipCallSession, sipConfig } = this.state;
        let { type, content } = this.wsParse(e.data);
        Log.info(this.sipSessionInfo(), `websocket event message: ${type}`);
        Log.debug(this.sipSessionInfo(), `websocket event message: ${type} content: ${printObject(content)}`);

        switch(type) {
            case "profile": {
                let { stream, devicePreference, profile } = this.state;
                let newProfile = {
                    name: content.name,
                    phone: content.phone,
                    password: content.password,
                    sipServer: profile.websocketServer, // 通知过来的websocketServer是指的sipServer
                    sipDomain: profile.sipDomain,
                    wsUrl: profile.wsUrl,
                    avatarUrl: profile.avatarUrl,
                };
                if (fsm.test(FsmState.Login.INIT)) {
                    let deviceId = devicePreference.get('loudspeaker', mediaDevice)
                    stream.setSinkId('dom_a_remote0', deviceId);
                    stream.setSinkId('dom_a_remote1', deviceId);
                    stream.setSinkId('dom_a_remote2', deviceId);
                    stream.setSinkId('dom_a_remote3', deviceId);
                    this.sipRegister(
                        newProfile.sipDomain, // 'ludiqiao.com',
                        newProfile.phone,
                        newProfile.password,
                        newProfile.sipServer, // 'wss://websocket.ludiqiao.com:7443',
                    )
                }
                this.setState({
                    profile: newProfile,
                    loginStatus: 'success',
                })
                 break;
            }
            case "callEnd": {
                this.sipHangup(content?.conference?.valid ? "call end" : "call destroy");
                break;
            }
            case "callCreate": {
                let { profile } = this.state;
                let { t } = this.props;
                this.callType = "audiovideo";
                // 更新配置
                let config = Object.assign(sipConfig, {
                    s_call_type: this.callType,
                })
                this.sipConfig(config);
                this.meetCreateAndJoinOnClick(null, content?.conference?.subject || t('call.message.create.subject', {creator: profile.name}))
                break;
            }
            case "callJoin": {
                let { t } = this.props;
                if (content?.conference?.extra?.Action === "rcCast") {
                    // 投屏呼叫
                    this.callType = "screenshare";
                    Log.debug(this.sipSessionInfo(), `screen share to call`)
                    
                    function start() {
                        if (this.mainStream.active) {
                            let config = Object.assign(sipConfig, {
                                s_call_type: this.callType,
                                o_main_stream: this.mainStream,
                            })
                            this.sipConfig(config);
                            this.meetJoinOnClick(null, content?.conference?.confCode)
                        } else {

                        }
                    }
                    
                    function loop(cnt) {
                        if (cnt > 0) {
                            adapter.getDisplayMedia({
                                video: {
                                    cursor: "always",
                                    frameRate: 16,
                                    width: 1920,
                                    height: 1080,
                                },
                            }, (stream) => {
                                // 更新配置
                                this.mainStream = stream;
                                start.bind(this)()
                            }, (error) => {
                                Log.error(this.sipSessionInfo(), `getDisplayMedia error`, error);
                                message.error(t("screen_share.message.notify.failed"), 5, "screen_share");
                                loop.bind(this)(cnt - 1);
                            })
                        }
                    }

                    if (this.mainStream) {
                        start.bind(this)();
                    } else {
                        loop.bind(this)(1);
                    }

                } else {
                    // 一般呼叫
                    this.callType = "audiovideo";
                    let config = Object.assign(sipConfig, {
                        s_call_type: this.callType,
                    })
                    this.sipConfig(config);
                    this.meetJoinOnClick(null, content?.conference?.confCode)
                }
                
                break;
            }
            case "conference": {
                // 计算出变化的入会和离会成员
                let { beforeDetail, detail } = this.state;
                let { t, login } = this.props;

                if (!fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
                    Log.warn(this.sipSessionInfo(), `status is error: ${fsm.curState()}`);
                    break;
                }

                let offlines = [];
                let onlines = [];
                if (detail?.participants?.length > 0 && content?.participants?.length > 0) {
                    // 离会成员（变化的）
                    detail.participants?.forEach(m1 => {
                        // 上次在线
                        if (m1?.status === "ONLINE") {
                            let m2 = content.participants?.find(m => m1?.user?.id === m?.user?.id);
                            // 现在找不到这个人，或者他的状态是不是ONLINE则认为是离线
                            if (!m2 || m2?.status !== "ONLINE") {
                                offlines.push(m1);
                            }
                        }
                    })

                    // 入会成员（变化的）
                    content.participants?.forEach(m1 => {
                        // 现在在线
                        if (m1?.status === "ONLINE") {
                            let m2 = detail?.participants?.find(m => m1?.user?.id === m?.user?.id);
                            let m3 = beforeDetail?.participants?.find(m => m1?.user?.id === m?.user?.id);
                            if (m2?.status === "ONLINE") {
                                // 上次也是ONLINE，则不理会
                            } else if (m3?.status === "ONLINE") {
                                // 上上次也是ONLINE，则不理会
                            } else {
                                onlines.push(m1);
                            }
                        }
                    })

                    // 离会成员toast
                    if (offlines.length > 0) {
                        let member = offlines.map(m => m.user.nickName).join(', ');
                        Log.debug(this.sipSessionInfo(), `leave conference member: ${member}`);
                        message.info(t('member.message.notify.leave', {member: member}), 3, 'member_leave');
                    }

                    // 入会成员toast
                    if (onlines.length > 0) {
                        let member = onlines.map(m => m.user.nickName).join(', ');
                        Log.debug(this.sipSessionInfo(), `join conference member: ${member}`);
                        message.info(t('member.message.notify.join', {member: member}), 3, 'member_join');
                    }
                }

                // 计算出自己状态是否发生变化
                let oldInfo = detail?.participants?.find(item => login?.owner === item?.user?.id);
                let newInfo = content?.participants?.find(item => login?.owner === item?.user?.id);
                if (oldInfo && newInfo) {
                    if (!!oldInfo.mute && !newInfo.mute) {
                        // 静音解除
                        message.success(t('profile.message.status.unmute'), 3, 'microphone')
                        this.setState({
                            audioEnable: true,
                        })
                    } else if (!oldInfo.mute && !!newInfo.mute) {
                        // 被静音
                        message.success(t('profile.message.status.mute'), 3, 'microphone')
                        this.setState({
                            audioEnable: false,
                        })
                    }

                    if (!!oldInfo.beSeen && !newInfo.beSeen) {
                        // 关闭摄像头
                        if (this.callType === "screenshare") {
                            message.success(t('profile.message.status.screen_share_off'), 3, 'camera')
                        } else {
                            message.success(t('profile.message.status.camera_off'), 3, 'camera')
                        }
                        this.setState({
                            videoEnable: false,
                        }, () => {
                            this.mediaStreamProcess(this.mediaStreamCtrl)
                        })
                    } else if (!oldInfo.beSeen && !!newInfo.beSeen) {
                        // 打开摄像头
                        if (this.callType === "screenshare") {
                            message.success(t('profile.message.status.screen_share_on'), 3, 'camera')
                        } else {
                            message.success(t('profile.message.status.camera_on'), 3, 'camera')
                        }
                        this.setState({
                            videoEnable: true,
                        }, () => {
                            this.mediaStreamProcess(this.mediaStreamCtrl)
                        })
                    }
                } else if (newInfo){
                    this.setState({
                        audioEnable: !newInfo.mute,
                        videoEnable: newInfo.beSeen,
                    })
                }

                if (content?.profile?.whiteBoard?.active) {
                    if (content.profile.whiteBoard.controllerId === login.owner) {
                        content.profile.whiteBoard.url = `https://vboard.ludiqiao.com/confBoard/ludiqiao/master/${content.conference.id}`
                    } else {
                        content.profile.whiteBoard.url = `https://vboard.ludiqiao.com/confBoard/ludiqiao/assist/${content.conference.id}`
                    }
                }

                if (!beforeDetail) {
                    this.setState({
                        code: content.conference?.accessCode,
                        theme: content.conference?.subject,
                        number: content.conference?.id,
                        beforeDetail: detail || content,
                        detail: content,
                        screenSharerId: content?.conference.desktopSharerDeviceId,
                        isInternalMs: newInfo?.isInternalMs || 0,
                        boardShare: content?.profile?.whiteBoard?.active ? BoardShareState.START : BoardShareState.STOP,
                        boardEnable: content?.profile?.whiteBoard?.active,
                    })
                } else {
                    this.setState({
                        code: content.conference?.accessCode,
                        theme: content.conference?.subject,
                        number: content.conference?.id,
                        beforeDetail: detail || content,
                        screenSharerId: content?.conference.desktopSharerDeviceId,
                        isInternalMs: newInfo?.isInternalMs || 0,
                        detail: content,
                    })
                }

                // 会议已经被销毁了
                if (!content.conference.valid) {
                    this.sipHangup('server destory');
                }

                break;
            }
            case "screen": {
                let { screen, stream } = this.state;
                let { login } = this.props;
                let viewSelf = false;
                if (!fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
                    Log.warn(this.sipSessionInfo(), `status is error: ${fsm.curState()}`);
                    break;
                }

                // 主动选流时的定时器清除
                if (this.selectViewTimer) {
                    clearTimeout(this.selectViewTimer)
                    this.selectViewTimer = undefined;
                }
                
                // 如果选流选到了自己，则不出画面
                if (login.owner === content?.media?.[0]) {
                    stream.deattachVideoRecvById(0);
                    viewSelf = true;
                } else if (screen) {
                    viewSelf = false;
                    content?.media?.forEach((sipNum, index) => {
                        let oldSipNum = screen.media?.[index];
                        if (oldSipNum) {
                            if (oldSipNum !== sipNum) {
                                stream.reattachVideoRecvById(index);
                            }
                        }
                    })
                }

                this.setState({
                    viewSelf: viewSelf,
                    screen: content,
                })
                break;
            }
            case "conferenceSendVideo":
                this.mediaStreamProcess(content)
                break;
            case 'perScreenShare': {
                this.meetReportOnClick(null, content?.CastCode);
                break;
            }
            
            case 'conferenceMaxMember': {
                let { t, login } = this.props;
                let { fsm, number } = this.state;
                if (number === content?.confId) {
                    if (content?.joinSipNum === login.owner) {
                        message.error(t('member.message.notify.me.join_limit'), 5);
                        if (fsm.test(FsmState.Call.PROCESS)) {
                            this.meetHangup('join limit')
                        }
                    } else {
                        message.error(t('member.message.notify.other_member.join_limit', {member: content?.nickName}), 5)
                    }
                }
                
                break;
            }
                
            case 'screenShare': {
                let { lastPageVisiable, stream } = this.state;
                let { t } = this.props;

                switch (content?.action) {
                    case "castApplying":
                        message.loading(t("screen_share.message.notify.applying"), 40, "screen_share");
                        this.screenShareSessionId = content?.sessionId;
                        this.screenShareApplyingTimer = setTimeout(() => {
                            this.screenShareSessionId = undefined;
                        }, 40 * 1000)
                        break;
                    
                    case "castConfirmed":
                        message.success(t("screen_share.message.notify.agree"), 3, "screen_share");
                        this.screenShareSessionId = undefined;
                        this.screenShareApplyingTimer && clearTimeout(this.screenShareApplyingTimer)
                        break;
                    
                    case "castCanceled": 
                        message.error(t("screen_share.message.notify.cancel"), 3, "screen_share");
                        this.screenShareSessionId = undefined;
                        this.screenShareApplyingTimer && clearTimeout(this.screenShareApplyingTimer)
                        stream.stop(this.mainStream, 'display');
                        if (lastPageVisiable === 'report') {
                            this.setState({
                                ...this.pageVisible()
                            })
                        }
                        break;
                    
                    case "castRefused":
                        message.error(t("screen_share.message.notify.disagree"), 3, "screen_share");
                        this.screenShareSessionId = undefined;
                        this.screenShareApplyingTimer && clearTimeout(this.screenShareApplyingTimer)
                        stream.stop(this.mainStream, 'display');
                        if (lastPageVisiable === 'report') {
                            this.setState({
                                ...this.pageVisible()
                            })
                        }
                        break;
                    
                    default:
                        break;
                }
                
                
                break;
            }
            case 'whiteBoard': {
                if (!fsm.test(FsmState.Call.STABLE)) {
                    Log.error(this.sipSessionInfo(), `status is not right, ${fsm.curState()} != ${FsmState.Call.STABLE}`)
                    return;
                }

                let { detail, stream } = this.state;
                let { t, login } = this.props;

                if (content.active) {
                    // 激活了白板
                    if (detail?.profile?.whiteBoard?.controllerId === login.owner) {
                        message.info(t('board.message.notify.start.success'))
                        
                        if (this.boardStartTimeout) {
                            // 先收到200OK，再收到mqtt的通知
                            clearTimeout(this.boardStartTimeout);
                            this.boardStartTimeout = null;
                            this.boardStartNotify = false;
                        } else {
                            // 先收到mqtt的通知，再收到200OK
                            this.boardStartNotify = true;
                        }
                    } else {
                        let member = detail?.participants?.find(item => content?.controllerId === item?.user?.id);
                        message.info(t('board.message.notify.start.doing', {member: member?.user?.nickName || ""}))
                    }
                    
                    detail.profile = {
                        ...detail.profile,
                        whiteBoard: {
                            ...content,
                        }
                    }
    
                    this.setState({
                        detail: detail,
                        boardShare: BoardShareState.START,
                        boardEnable: true,
                    })
                } else {
                    // 关闭了白板
                    if (detail?.profile?.whiteBoard?.controllerId === login.owner) {
                        message.info(t('board.message.notify.stop.success'))
                        if (this.boardStopTimeout) {
                            // 先收到200OK，再收到mqtt的通知
                            clearTimeout(this.boardStopTimeout);
                            this.boardStopTimeout = null;
                            this.boardStopNotify = false;
                        } else {
                            // 先收到mqtt的通知，再收到200OK
                            this.boardStopNotify = true;
                        }
                    } else {
                        let member = detail?.participants?.find(item => detail?.profile?.whiteBoard?.controllerId === item?.user?.id);
                        message.info(t('board.message.notify.stop.end', {member: member?.user?.nickName || ""}))
                    }

                    detail.profile = {
                        ...detail.profile,
                        whiteBoard: {
                            ...content,
                        }
                    }

                    this.setState({
                        detail: detail,
                        boardShare: BoardShareState.STOP,
                        backgroudViewType: BackgroudViewType.VIDEO,
                        boardEnable: false,
                    })
                    this.stopBoardShareReattachVideoTimer = setTimeout(() => {
                        stream.reattachAllVideoRecv();
                    }, 100)
                }
                
                break;
            }
            case "desktopShare": {
                let { detail, screenShare, sipCallSession } = this.state;
                let { t, login } = this.props;
                if (detail?.conference?.id === content?.conference?.id) {
                    this.setState({
                        screenSharerId: content?.flag === "start" ? content?.desktopShareDeviceId : undefined,
                        remoteEnable: content?.flag === "start" && content?.desktopShareDeviceId !== login?.owner,
                    })

                    // 另外一个人抢占了投屏
                    if (content?.flag === "start" && screenShare === ScreenShareState.START && content?.desktopShareDeviceId !== login.owner) {
                        message.info(t('screen.message.notify.status.killed'));
                        sipCallSession.switch_video('video');
                    }
                }
                break;
            }
            case "deviceConflict": {
                let { fsm } = this.state;
                let { t } = this.props;
                message.error(t('profile.message.login.conflict'), 3, 'login_conflict')
                fsm.transfer(FsmState.Login.FAILED)
                this.exit();
                break;
            }
            case "uploadLog": {
                let { profile } = this.state;
                let { login } = this.props;
                let { name, info } = uploadInfo(profile, login, version, adapter.explorerInfo, mediaDevice)
                Log.uploadFile(name, info, null, 'log');
                break;
            }
            case "showOrHideSmallWindows":
                break;
            case "version":
                break;
            default: {
                Log.error(this.sipSessionInfo(), "unsupported message type: " + type);
                break;
            }
        }
    }

    // websocket关闭通知
    wsOnClose(e) {
        let { fsm } = this.state;
        let { t, login } = this.props;
        let status = fsm.curState();
        Log.info(this.sipSessionInfo(), `websocket event close. detail: ${e.code} ${e.reason} ${e.wasClean}`);

        switch(status) {
            case FsmState.Login.INIT: {
                message.error(t('websocket.message.status.error'))
                this.reconnWebSocketTimer = setTimeout(() => {
                    Log.warn(this.sipSessionInfo(), "try to reconnect websocket...")
                    this.wechatOnLogin(login, null, true);
                }, 1000)
                break;
            }

            case FsmState.Login.OUT: {
                break;
            }

            default: {
                this.reconnWebSocketTimer = setTimeout(() => {
                    Log.warn(this.sipSessionInfo(), "try to reconnect websocket...")
                    this.wechatOnLogin(login, null, true);
                }, 1000)
                break;
            }
        }

        if (this.wsHbTimer) {
            clearInterval(this.wsHbTimer);
        }
  
        this.setState({
            websocketStatus: 'disconnected',
        })
    }

    // 微信登录通知
    wechatOnLogin(login, user, isSuccessed) {
        let { fsm, profile, websocketRestartCnt } = this.state;
        if (isSuccessed) {
            Log.info(this.sipSessionInfo(), `websocket connecting...`);
            let url = config.host.websocket + (version.isInternal() ? "/as" : "") + `/websocket?DeviceToken=${login.token}&DeviceType=WEB&Version=${version.versionNo()}`;
            let ws = new WebSocket(url);
            if (!profile) {
                profile = {};
            }
            profile = Object.assign(profile, login);
            if (user) {
                profile = Object.assign(profile, user);
            }
            profile.wsUrl = url;
            this.setState({
                websocket: ws,
                profile: profile,
                websocketRestartCnt: websocketRestartCnt + 1,
            })

            ws.onopen = this.wsOnOpen;
            ws.onmessage = this.wsOnMessage;
            ws.onclose = this.wsOnClose;
            
        } else {
            this.setState({
                loginStatus: 'disconnected',
            })
        }
    }

    meetJoin(info, cnt = 1) {
        let that = this;
        let { fsm, mediaServers, dialHistory, optionCameraEnable, optionMicrophoneEnable } = this.state;
        let { t } = this.props;

        let creator = info?.participants?.find((part) => part?.user?.id === info?.conference?.creatorId)?.user?.nickName;
        // 修正号码
        info.conference.accessCode = codeFix(info?.conference?.accessCode);
        Log.debug(this.sipSessionInfo(), `join the conference by creator name: ${creator} conference: ${printObject(info?.conference)}`);
        if (this.callType === "screenshare") {
            message.loading(t('call.message.notify.status.screen.doing'))
        } else {
            message.loading(t('call.message.notify.status.doing'))
        }

        // 临时的拨号记录
        let content = {
            code: info.conference.accessCode, 
            number: info.conference.id,
            creator: creator,
            type: this.callType,
            theme: info.conference.subject,
            start: moment().unix(),
            end: undefined,
            status: "process",
        }
        dialHistory.add(0, content)

        fsm.transfer(FsmState.Call.PROCESS);
        fsm.startTimer(FsmState.Call.PROCESS, () => {
            sipCallSessionOnTerminated({session: null}, that, 'fsm call process timeout');
        })
        this.manual = true;
        this.setState({
            creator: creator,
            code: info.conference.accessCode,
            number: info.conference.id,
            ...this.pageInvisible(),
        })

        apiConferenceJoin(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), info.conference.id, mediaServers, !optionMicrophoneEnable, optionCameraEnable, (dispatch, rsp, req) => {
            Log.debug(that.sipSessionInfo(), `join the conference success. details: ${printObject(rsp)}`);
            that.setState({
                theme: info.conference.subject,
            })
        }, (dispatch, rsp, req) => { 
            Log.warn(that.sipSessionInfo(), `join the conference error. details: ${printObject(rsp)}`);
            let reasonCode = rsp.status;
            switch(reasonCode) {
                case 404:
                    message.error(t('call.message.api.info.status.not_exist', {conference: info.conference.accessCode}))
                    break;
                case 409:
                    break;
                default:
                    message.error(t('call.message.api.info.error'))
                    break;
            }

            Log.debug(that.sipSessionInfo(), `the conference dose really exist?`);
            apiConferenceGet(Object.assign({sessionInfo: that.sipSessionInfo}, that.props), (dispatch, rsp, req) => {
                Log.warn(that.sipSessionInfo(), `get the conference success. details: ${printObject(rsp)}`);
                that.setState({
                    exist: rsp,
                })
                let number = rsp.conference?.id;
                if (number) {
                    Log.debug(that.sipSessionInfo(), `exit conference ${number}`)
                    apiConferenceExit(Object.assign({sessionInfo: that.sipSessionInfo}, that.props), number, (dispatch, rsp, req) => {
                        Log.debug(that.sipSessionInfo(), `exit conference success.`)
                        if (cnt && reasonCode === 409) {
                            that.meetJoin(info, cnt - 1);
                        } else if (!cnt && reasonCode === 409) {
                            message.error(t('call.message.api.info.error'))
                        }
                    }, (dispatch, rsp, req) => {
                        message.error(t('call.message.api.info.error'))
                    })
                }
            }, (dispatch, rsp, req) => {
                Log.debug(that.sipSessionInfo(), `get the conference error. details: ${printObject(rsp)}`);
                if (rsp.status === 404) {
                    that.setState({
                        exist: undefined,
                    })
                }
            })
        })
    }

    meetHangup(reason) {
        let that = this;
        if (reason) {
            if (!this.manual || reason === 'join limit') {
                Log.debug(that.sipSessionInfo(), `sip direct hangup call`)
                that.sipHangup(reason);
            } else {
                let timeout = 3;
                this.hangupTimer = setTimeout(() => {
                    Log.debug(that.sipSessionInfo(), `the timer(${that.hangupTimer}) timeout, hangup call`)
                    that.hangupTimer = null;
                    that.sipHangup(reason);
                }, timeout * 1000);
                Log.info(this.sipSessionInfo(), `start timer(${this.hangupTimer} after ${timeout}s) to hangup(by sip) call. reason: ${reason}`)
            }
            
        }
    }

    // 加入会议被点击
    meetJoinOnClick(e, code = undefined) {
        e && e.stopPropagation();

        function fun() {
            let { profile, dialHistory } = this.state;
            let { t } = this.props;
            code = codeFix(code || this.state.code);
            Log.info(this.sipSessionInfo(), `ui event: join conference onclick`)
            Log.debug(this.sipSessionInfo(), `join conference code: ${code}`);
            
            if (!code || code.length <= 0) {
                message.error(t('call.message.input.code.empty'));
                Log.warn(this.sipSessionInfo(), `the conference code must not be empty`);
            } else {
                if (this.callType === "screenshare") {
                    message.loading(t('call.message.notify.status.screen.doing'))
                } else {
                    message.loading(t('call.message.notify.status.doing'))
                }
                apiConferenceFind(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), code, (dispatch, rsp, req) => {
                    Log.debug(this.sipSessionInfo(), `find conference info success, have a good time for join it`);
                    this.meetJoin(rsp);
                }, (dispatch, rsp, req) => {
                    switch(rsp.status) {
                        case 404:
                            message.error(t('call.message.api.info.status.not_exist', {conference: codeFormatter(code)}))
                            Log.debug(this.sipSessionInfo(), `the conference code: ${codeFormatter(code)} dose not exist`);
                            break;
                        default:
                            message.error(t('call.message.api.info.error'))
                            Log.error(this.sipSessionInfo(), `find conference info error: ${printObject(rsp)}`);
                            break;
                    }
                    let content = {
                        code: code, 
                        number: 0,
                        creator: profile.name,
                        type: this.callType,
                        theme: t('common.none'),
                        start: moment().unix(),
                        end: undefined,
                        status: "error",
                        reason: t('call.reason.not_exist'),
                    }
                    dialHistory.add(0, content)
                    this.setState({
                        code: "",
                    })
                    return true;
                })
            }
        }

        this.uiPreproc(fun);
    }

    // 再次加入被点击
    meetJoinAgainOnClick(e, info) {
        let { fsm } = this.state;
        e.stopPropagation();
        Log.info(this.sipSessionInfo(), `ui event: join again conference onclick `)
        this.meetJoin(info);
    }

    // 创建并加入被点击
    meetCreateAndJoinOnClick(e, title=undefined) {
        e && e.stopPropagation();

        function fun() {
            let that = this;
            let { fsm, stream, profile, mediaServers, devicePreference } = this.state;
            title = title || this.state.title;
            let { t } = this.props;
            Log.info(this.sipSessionInfo(), `ui event: create conference onclick `)
            Log.debug(this.sipSessionInfo(), `conference title: ${title}`);

            if (!title || title.length <= 0) {
                Log.warn(this.sipSessionInfo(), `conference title is empty`);
                message.error(t('call.message.input.theme.empty'));
            } else {
                Log.debug(this.sipSessionInfo(), `get conference info create by ${profile.name}`)
                this.manual = true;
                if (!fsm.test(FsmState.Call.INIT)) {
                    Log.error(this.sipSessionInfo(), `status is not right, ${fsm.curState()} != ${FsmState.Call.INIT}`)
                    return;
                }
                apiConferenceGet(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), (dispatch, rsp, req) => {
                    if (!rsp.conference) {
                        Log.error(that.sipSessionInfo(), `the response format error. detail: ${printObject(rsp)}`)
                        return;
                    }
                    Log.debug(that.sipSessionInfo(), `conference dose exists. detail: ${printObject(rsp.conference)}`);
                    // 修正号码
                    rsp.conference.accessCode = codeFix(rsp.conference.accessCode);
                    that.setState({
                        exist: rsp,
                    })
                }, (dispatch, rsp, req) => {
                    if (rsp.status === 404) {
                        Log.debug(that.sipSessionInfo(), `conference dose not exist. status: ${rsp.status}`)
                        fsm.transfer(FsmState.Call.PROCESS);
                        fsm.startTimer(FsmState.Call.PROCESS, () => {
                            sipCallSessionOnTerminated({session: null}, that, 'fsm call process timeout');
                        })
                        that.setState({
                            ...this.pageInvisible('create'),
                            creator: profile.name,
                            exist: undefined,
                        })
                        // stream.preview.stop();
                        if (this.callType === "screenshare") {
                            message.loading(t('call.message.notify.status.screen.doing'))
                        } else {
                            message.loading(t('call.message.notify.status.doing'))
                        }
                        apiConferenceCreate(Object.assign({sessionInfo: that.sipSessionInfo}, that.props), title, mediaServers, (dispatch, rsp, req) => {
                            if (!rsp.conference) {
                                Log.error(that.sipSessionInfo(), `the response format error. detail: ${printObject(rsp)}`)
                                return;
                            }

                            Log.debug(that.sipSessionInfo(), `conference create success. code: ${rsp.conference.accessCode} theme: ${rsp.conference.subject} number: ${rsp.conference.id}`)
                            if (!fsm.test(FsmState.Call.PROCESS)) {
                                Log.error(that.sipSessionInfo(), `status is not right, ${fsm.curState()} != ${FsmState.Call.PROCESS}`)
                                return;
                            }
                            
                            // 修正号码
                            rsp.conference.accessCode = codeFix(rsp.conference.accessCode);
                            that.setState({
                                code: rsp.conference.accessCode,
                                theme: rsp.conference.subject,
                                number: rsp.conference.id,
                            })
                        }, (dispatch, rsp, req) => {
                            stream.preview.start(devicePreference.get('camera', mediaDevice));
                            that.setState({
                                sipCallStartTime: 0,
                                ...this.pageVisible('create')
                            })
                            message.error(t('call.message.api.create.error'));
                            Log.error(that.sipSessionInfo(), `conference create failed. detail: ${printObject(rsp)}`)
                        })
                    } else {
                        Log.error(that.sipSessionInfo(), `get conference info error. status: ${rsp.status}(!= 404) detail: ${printObject(rsp)}`)
                    }
                })
            }
        }

        this.uiPreproc(fun);
    }

    // 结束会议被点击
    meetDelOnClick(e, number, hangupReason = undefined) {
        let { t } = this.props;
        let that = this;

        Log.info(this.sipSessionInfo(), `ui event: delete conference onclick`)
        Log.debug(this.sipSessionInfo(), `conference number: ${number}, hangupReason: ${hangupReason}`)
        let conferenceGet = (callback) => {
            apiConferenceGet(Object.assign({sessionInfo: that.sipSessionInfo}, that.props), (dispatch, rsp, req) => {
                that.setState({
                    // exist: rsp,
                    ...that.pageVisible(),
                    hangupVisible: false,
                })
                // message.error(t('call.message.api.del.error'));
                Log.error(this.sipSessionInfo(), `delete conference and check result. ooooops, it's still there`)
                callback && callback(false)
            }, (dispatch, rsp, req) => {
                if (rsp.status === 404) {
                    // message.success(t('call.message.api.del.success'));
                    Log.debug(this.sipSessionInfo(), `delete conference and check result is ok`)
                    callback && callback(true)
                } else {
                    // message.error(t('call.message.api.del.error'));
                    Log.error(this.sipSessionInfo(), `delete conference and check result error. details: ${printObject(rsp)}`)
                    callback && callback(true)
                }
                that.setState({
                    exist: undefined,
                    ...that.pageVisible(),
                    hangupVisible: false,
                })
            })
        }
        message.loading(t('call.message.api.del.doing'));
        apiConferenceDel(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), number, (dispatch, rsp, req) => {
            Log.debug(this.sipSessionInfo(), `delete conference success`)
            this.delConferenceTimer = setTimeout(() => {
                conferenceGet((ret) => {
                    message.success(t('call.message.api.del.success'));
                });
            }, 1000)
        }, (dispatch, rsp, req) => {
            Log.error(this.sipSessionInfo(), `delete conference error. details: ${rsp}`)
            this.delConferenceTimer = setTimeout(() => {
                conferenceGet((ret) => {
                    message.success(t('call.message.api.del.success'));
                });
            }, 1000)
        })

        this.meetHangup(hangupReason);
    }

    // 挂断被点击
    meetHangupOnClick(e, number, hangupReason = undefined) {
        let that = this;
        let { t } = this.props;
        
        Log.info(this.sipSessionInfo(), `ui event: hangup conference onclick`)
        Log.debug(this.sipSessionInfo(), `conference number: ${number}, hangupReason: ${hangupReason}`)

        apiConferenceExit(Object.assign({ sessionInfo: this.sipSessionInfo }, this.props), number,
            (dispatch, rsp, req) => {
                Log.debug(that.sipSessionInfo(), `exit conference success.`)
                that.setState({
                    hangupVisible: false
                });
            }, (dispatch, rsp, req) => {
                if (!hangupReason) {
                    message.error(t('call.message.api.exit.error'));
                }
            }
        )

        this.meetHangup(hangupReason);
    }

    meetReportOnClick(e, screenCode) {
        e && e.stopPropagation();
        screenCode = (screenCode || this.state.screenCode || "").replace(/\D/g, '');
        let { t, login } = this.props;
        let { stream } = this.state;
        function loop(cnt) {
            this.mainStream = undefined;
            if (cnt > 0) {
                adapter.getDisplayMedia({
                    video: {
                        cursor: "always",
                        frameRate: 16,
                        width: 1920,
                        height: 1080,
                    },
                }, (st) => {
                    // 更新配置
                    this.mainStream = st;
                    apiConferenceReportApply(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), screenCode, login?.owner,
                        () => {
                            this.setState({
                                ...this.pageInvisible('report')
                            })
                        },
                        (_, rsp, req) => {
                            let title = '';
                            switch (rsp.Status) {
                                case 4011: title="modal.report_conference.message.api.busy"; break;
                                case 4012: title="modal.report_conference.message.api.not_found"; break;
                                case 4017: title="modal.report_conference.message.api.device_offline"; break;
                                default: title = "modal.report_conference.message.api.error"; break;
                            }
                            message.error(t(title))
                            stream.stop(st, 'display');
                            this.setState({
                                ...this.pageVisible('report')
                            })
                            return true;
                        }
                    );
                    var track = st.getVideoTracks()[0];
                    track.onended = (event) => {
                        apiConferenceReportCancel(Object.assign({ sessionInfo: this.sipSessionInfo }, this.props), this.screenShareSessionId,
                            () => {
                                
                            },
                            () => {
                                message.error(t("screen_share.message.notify.failed"), 5, "screen_share");
                            }
                        )
                    }
                }, (error) => {
                    Log.error(this.sipSessionInfo(), `getDisplayMedia error`, error);
                    message.error(t("screen_share.message.notify.failed"), 5, "screen_share");
                    loop.bind(this)(cnt - 1);
                })
            } else {
                this.setState({
                    ...this.pageVisible('report')
                })
            }
        }

        this.setState({
            ...this.pageInvisible('report')
        }, () => {
            loop.bind(this)(1);
        })

        
    }

    // 隐藏页面被点击
    barVisibleOnClick(e, param) {
        e.stopPropagation();

        let { fsm, menuLock, barVisible, conferenceWizzardVisible, conferenceCreateVisible, conferenceJoinVisible, conferenceReportVisible, lastPageVisiable,
            memberVisible, detailVisible, rtcInfoVisible, hangupVisible, settingVisible, permissionHelpVisible } = this.state;
        let { t } = this.props;
        
        Log.info(this.sipSessionInfo(), `ui event: page ${lastPageVisiable} visible onclick`);

        if (menuLock === MenuState.LOCK) {
            // 被锁住，不切换菜单显隐
            if (param === 'icon') {
                message.info(t('operation.message.bar_visible.lock'));
            }
        } else if (conferenceWizzardVisible || conferenceCreateVisible || conferenceJoinVisible || conferenceReportVisible
            || memberVisible || detailVisible || rtcInfoVisible 
            || hangupVisible || settingVisible || permissionHelpVisible || this.screenShareSessionId ) {
            // 目前有对话框出现，不切换菜单显隐
        }else {
            // 特殊情况不显隐
            if (fsm.test(FsmState.Call.INIT)) {
                this.setState({
                    ...this.pageVisible()
                })
                return;
            }

            if (this.barClickTimer) {
                clearTimeout(this.barClickTimer);
            }
            this.barClickTimer = setTimeout(() => {
                this.setState({
                    barVisible: !barVisible,
                })
                this.barClickTimer = null;
            }, 250);
        }
    }

    // 分享被点击
    iconCopyOnClick(e, param, content = "") {
        e.stopPropagation();
        let { t } = this.props;
        let input = document.getElementById("dom_copy");
        Log.info(this.sipSessionInfo(), `ui event: copy onclick. param: ${param} content: ${content}`)
        if (input) {
            let title = undefined;
            switch(param){
                case "conference": {
                    title = t('share.message.copy.conference.addr');
                    break;
                }
                case "code": {
                    title = t('share.message.copy.conference.code');
                    break;
                }
                case "live": {
                    title = t('share.message.copy.live.addr');
                    break;
                }
                default: {
                    title = t('share.message.copy.success');
                    break;
                }
            }
            
            // 全选所有字符串，开始复制
            input.setAttribute("value", content);
            input.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy")
                message.success(title);
            } else {
                message.error(t('share.message.copy.error'));
                Log.error(this.sipSessionInfo(), `copy error. param: ${param} content: ${content}`);
            }
        }
    }

    // 放大被点击
    zoomInOnClick(e, param) {
        e.stopPropagation();
        let that = this;
        let { layoutMode, detail, views, stream} = this.state;
        Log.info(this.sipSessionInfo(), `ui event: zoom-in onclick. param: ${param}`);

        switch(param){
            case "remote0":
            case "remote1":
            case "remote2":
            case "remote3":
            case "remote4":
            case "remote5":
            case "remote6":
            case "remote7":
            case "remote8": {
                let index = parseInt(param.substr(6,1));
                // 小窗口点大
                if (layoutMode === LayoutMode.OVERLAP) {
                    views.setToHight(index);
                } 

                views.setLayoutMode(LayoutMode.OVERLAP);
 
                break;
            }
            case "video": {
                that.setState({
                    viewChangeFlag: !that.state.viewChangeFlag,
                    layoutMode: LayoutMode.OVERLAP,
                    backgroudViewType: BackgroudViewType.VIDEO,
                })
                stream.reattachAllVideoRecv();
                break;
            }
            case "board": {
                that.setState({
                    viewChangeFlag: !that.state.viewChangeFlag,
                    layoutMode: LayoutMode.OVERLAP,
                    boardEnable: false,
                    backgroudViewType: BackgroudViewType.BOARD,
                })
                break;
            }
            case "local":
            default:
                Log.error(this.sipSessionInfo(), "unspported param: " + param)
                return;
        }
    }

    // 缩小被点击
    zoomOutOnClick(e, param) {
        e.stopPropagation();
        let that = this;
        let { stream} = this.state;
        Log.info(this.sipSessionInfo(), `ui event: zoom-out onclick. param: ${param}`);

        switch(param){
            case "board": {
                that.setState({
                    viewChangeFlag: !that.state.viewChangeFlag,
                    layoutMode: LayoutMode.OVERLAP,
                    boardEnable: true,
                    backgroudViewType: BackgroudViewType.VIDEO,
                })
                stream.reattachAllVideoRecv();
                break;
            }
            default:
                Log.error(this.sipSessionInfo(), "unspported param: " + param)
                return;
        }
    }

    // 切换被点击
    videoSwitchOnClick(e, param) {
        Log.info(this.sipSessionInfo(), `ui event: video switch onclick. param: ${param}`);
        e.stopPropagation();
    }

    // 鼠标移到底层框附近出现菜单
    videoDivOnMouseMove(e) {
        let {height, width} = this.state;
        if( e.clientY < 8 || height - e.clientY < 8 
            || e.clientX < 8 || width - e.clientX  < 8){
            this.setState({
                barVisible: true,
            })
        } else {
            this.mouseCurX = e.clientX;
            this.mouseCurY = e.clientY;
        }
    }

    // 菜单栏toggle被点击
    iconOnToggle(e, type) {
        e.stopPropagation();

        let { stream, number, sipCallSession, boardShare, detail } = this.state;
        let { t } = this.props;

        Log.info(this.sipSessionInfo(), `ui event: menu icon ontoggle. type: ${type}`);

        switch(type){
            case "audio": {
                let newValue = !this.state.audioEnable;
                Log.info(this.sipSessionInfo(), `audio ${!newValue ? 'mute' : 'unmute'}`);
                // message.loading(t(`audio.message.status.${!newValue ? 'mute' : 'unmute'}.doing`), 3, "microphone")
                // sipCallSession.mute('audio', !newValue);
                let fun = newValue ? apiConferenceUnmute : apiConferenceMute;
                if (newValue && detail?.profile?.mute?.active) {
                    Log.debug(this.sipSessionInfo(), `you have no permission to make microphone unmute`)
                    message.error(t('audio.message.error.nopermission'))
                    return;
                }
                fun(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), number, () => {
                    Log.debug(this.sipSessionInfo(), `conference microphone ${newValue ? 'mute' : 'unmute'} myself success.`)
                }, (dispatch, rsp, req) => {
                    Log.warn(this.sipSessionInfo(), `conference microphone ${newValue ? 'mute' : 'unmute'} myself error.`)
                    if (rsp.status === 409) {
                        message.error(t('audio.message.error.nopermission'), 3, "microphone")
                    } else {
                        message.error(t('audio.message.error.common'), 3, "microphone")
                    }
                })
                break;
            }
            case "video": {
                let newValue = !this.state.videoEnable;
                Log.info(this.sipSessionInfo(), `video ${!newValue ? 'off' : 'on'}`);
                // message.loading(t(`video.message.status.${!newValue ? 'off' : 'on'}.doing`), 3, "camera")

                let fun = newValue ? apiConferenceCameraOn : apiConferenceCameraOff;
                fun(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), number, () => {
                    Log.debug(this.sipSessionInfo(), `conference camera ${newValue ? 'off' : 'on'} myself success.`)
                    // this.setState({
                    //     videoEnable: newValue,
                    // })
                    // sipCallSession.mute('video', !newValue);
                }, (dispatch, rsp, req) => {
                    Log.warn(this.sipSessionInfo(), `conference camera ${newValue ? 'off' : 'on'} myself error.`)
                    if (rsp.status === 409) {
                        message.error(t('video.message.error.nopermission'), 3, "camera")
                    } else {
                        message.error(t('video.message.error.common'), 3, "camera")
                    }
                })

                break;
            }
            case "screen": {
                let newValue = !this.state.screenEnable;
                Log.info(this.sipSessionInfo(), `screen ${!newValue ? 'off' : 'on'}`);

                this.setState({
                    screenEnable: newValue,
                })
                sipCallSession.mute('screen', !newValue);
                break;
            }
            case "local":{
                let newValue = !this.state.localEnable;
                Log.info(this.sipSessionInfo(), `local view ${newValue ? 'on' : 'off'}`);

                this.setState({
                    localEnable: newValue,
                })
                break;
            }
                
            case "remote":{
                let newValue = !this.state.remoteEnable;
                Log.info(this.sipSessionInfo(), `remote view ${newValue ? 'on' : 'off'}`);

                this.setState({
                    remoteEnable: newValue,
                })
                break;
            }
                
            case "board":{
                if (boardShare === BoardShareState.START) {
                    let newValue = !this.state.boardEnable;
                    this.setState({
                        boardEnable: newValue,
                    })
                    Log.info(this.sipSessionInfo(), `board view ${newValue ? 'on' : 'off'}`);
                } else {
                    message.warn(t('board.message.tip.not_started'));
                }
                break;
            }
            case "room":{
                let newValue = !this.state.remotesEnable;
                Log.info(this.sipSessionInfo(), `room view ${newValue ? 'on' : 'off'}`);

                this.setState({
                    remotesEnable: newValue,
                })
                break;
            }
                
            case "loudspeaker": {
                let newValue = !this.state.optionLoudspeakerEnable;
                Log.info(this.sipSessionInfo(), `loudspeaker ${!newValue ? 'off' : 'on'}`);
                stream.audioPause(!newValue);
                this.setState({
                    optionLoudspeakerEnable: newValue,
                })
                
                break;
            }
                
            default:
                break;
        }
    }

    // 菜单栏被点击
    menuItemOnChange(e, param) {
        e.domEvent.stopPropagation();
        let state = this.state;
        let { views, boardShare, stream } = this.state;
        let {t} = this.props;
        let newValue = parseInt(e.key);
        let oldValue = state[param];
        
        switch(param) {
            case "backgroudViewType":{
                Log.info(this.sipSessionInfo(), `ui event: menu item(${param}) onchange: ${BackgroudViewType.toString(oldValue)} -> ${BackgroudViewType.toString(newValue)}`);

                if (newValue !== oldValue) { 
                    if (newValue === BackgroudViewType.BOARD) {
                        if (boardShare === BoardShareState.STOP) {
                            message.error(t('board.message.tip.not_started'));
                            return;
                        }
                    } else if (newValue === BackgroudViewType.VIDEO) {
                        if (boardShare === BoardShareState.START) {
                            state.boardEnable = true;
                        }
                    } else {
                        Log.error(this.sipSessionInfo(), "unsupported key: " + newValue + " on param: ", param);
                        return;
                    }
                    stream.reattachAllVideoRecv();
                } else {
                    return;
                }

                break;
            }
            case "layoutMode": {
                Log.info(this.sipSessionInfo(), `ui event: menu item(${param}) onchange: ${LayoutMode.toString(oldValue)} -> ${LayoutMode.toString(newValue)}`);

                views.setLayoutMode(newValue);
                if (oldValue !== newValue) {
                    if (newValue === LayoutMode.OVERLAP) {
                        state.remotesEnable = true;
                    }
                    // 重新选流
                    // Log.info("reselect view")
                    // TODO：
                    // 浮动布局和4分屏(或9分屏）互切需要重新reattch stream。4分屏切9分屏不需要重新reattch
                    if ((oldValue === LayoutMode.OVERLAP && (newValue === LayoutMode.SPLIT_4 || newValue === LayoutMode.SPLIT_9))
                        || ((oldValue === LayoutMode.SPLIT_4 || oldValue === LayoutMode.SPLIT_9) && newValue === LayoutMode.OVERLAP)) {
                        stream.reattachAllVideoRecv();
                    }
                } else {
                    return;
                }
                break;
            }
            default:
                Log.error(this.sipSessionInfo(), `ui event: menu item(${param}) onchange: ${oldValue} -> ${newValue}`);
                break;
        }

        state[param] = newValue;
        this.setState({
            ...state,
        })
    }

    // 菜单栏共享白板被点击
    menuItemBoardShareOnChange(e) {
        if (e.domEvent) {
            e.domEvent.stopPropagation();
        }

        let that = this;
        let { stream, number, detail, boardShare } = this.state;
        let { t, login } = this.props;
        let key = parseInt(e.key);

        Log.info(this.sipSessionInfo(), `ui event: menu item(board share) onchange: ${BoardShareState.toString(boardShare)} -> ${BoardShareState.toString(key)}`);

        switch(key) {
            case BoardShareState.START:
                if (boardShare === BoardShareState.START) {
                    if (detail?.profile?.whiteBoard?.controllerId === login.owner) {
                        Log.debug(this.sipSessionInfo(), "board has been share by youself, do it again?")
                    } else {
                        message.warn(t('board.message.tip.has_started'));
                    }
                } else {
                    if (this.boardStartTimer || this.boardStopTimer) {
                        message.warn(t('board.message.tip.not_fast_click'));
                        return;
                    }
                    message.loading(t('board.message.tip.doing'));

                    that.boardStartNotify = false;
                    apiConferenceBoardStart(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), number, (dispatch, rsp, req) => {
                        // 开启定时器等通知，没等到通知，也认为成功
                        that.boardStartTimer = setTimeout(() => {
                            if (!that.boardStartNotify) {
                                Log.warn(that.sipSessionInfo(), "board start successful but not recv notify. are you ok?")
                                message.success(t('board.message.notify.start.success'));
                                
                                let { detail } = that.state;
                                detail.profile = {
                                    ...detail.profile,
                                    whiteBoard: rsp,
                                }

                                that.setState({
                                    detail: detail,
                                    boardShare: BoardShareState.START,
                                    boardEnable: true,
                                })
                            }
                            that.boardStartNotify = false;
                            that.boardStartTimer = null;
                        }, 1000);

                    }, (dispatch, rsp, req) => {
                        Log.error(that.sipSessionInfo(), "share board start failed. rsp: ",rsp);
                        message.success(t('board.message.notify.error'));
                    });
                } 
            
                break;
            case BoardShareState.STOP:
                if (boardShare === BoardShareState.START) {
                    if (detail?.profile?.whiteBoard?.controllerId === login.owner) {
                        if (this.boardStartTimer || this.boardStopTimer) {
                            message.warn(t('board.message.tip.not_fast_click'));
                            return;
                        }
                        message.loading(t('board.message.tip.stopping'));
                        this.setState({
                            viewChangeFlag: !that.state.viewChangeFlag,
                            backgroudViewType: BackgroudViewType.VIDEO,
                            boardEnable: false,
                        })
                        this.stopBoardShareReattachVideoTimer = setTimeout(() => {
                            stream.reattachAllVideoRecv();
                        }, 500)
                        that.boardStopNotify = false;
                        apiConferenceBoardStop(Object.assign({sessionInfo: this.sipSessionInfo}, this.props), number, (dispatch, rsp, req) => {
                            // 开启定时器等通知，没等到通知，也认为成功
                            that.boardStopTimer = setTimeout(() => {
                                if (!that.boardStopNotify) {
                                    Log.warn(that.sipSessionInfo(), "board stop successful but not recv notify. are you ok?")
                                    message.success(t('board.message.notify.stop.success'));
                                    
                                    let { detail } = that.state;
                                    detail.profile = {
                                        ...detail.profile,
                                        whiteBoard: {
                                            active: rsp.active,
                                            url: undefined,
                                            controllerId: undefined,
                                        }
                                    }
                
                                    that.setState({
                                        detail: detail,
                                        boardShare: BoardShareState.STOP,
                                    })
                                }
                                that.boardStopNotify = false;
                                that.boardStopTimer = null;
                            }, 1000);
        
                        }, (dispatch, rsp, req) => {
                            Log.error(that.sipSessionInfo(), "share board stop failed. rsp: ",rsp);
                            message.success(t('board.message.notify.stop.error'));
                        });
                    } else {
                        message.error(t('board.message.tip.not_belong'));
                    }
                } else {
                    Log.debug(this.sipSessionInfo(), "board has been stopped, do it again?");
                }
                break;
            default:
                Log.error(this.sipSessionInfo(), `unsupported board share key: ${key}`)
                return;
        }
    }

    // 菜单栏屏幕共享被点击
    menuItemScreenShareOnChange(e) {
        if (e?.domEvent) {
            e.domEvent.stopPropagation();
        }

        let { sipCallSession, screenShare, number } = this.state;
        let {t} = this.props;
        let key = parseInt(e.key);

        Log.info(this.sipSessionInfo(), `ui event: menu item(screen share) onchange: ${ScreenShareState.toString(screenShare)} -> ${ScreenShareState.toString(key)}`);

        switch(key) {
            case ScreenShareState.START: {
                if (screenShare === ScreenShareState.STOP) {
                    message.loading(t('screen.message.status.start.doing'));
                    sipCallSession.switch_video('screen');
                }
                break;
            }

            case ScreenShareState.STOP: {
                if (screenShare === ScreenShareState.START) {
                    message.loading(t('screen.message.status.stop.doing'));
                    sipCallSession.switch_video('video');
                }
                break;
            }

            default: {
                Log.error(this.sipSessionInfo(), `unsupported screen share key: ${key}`)
                return;
            }
        }
    }

    // 会议设置对话框
    mkConferenceSetting() {
        let that = this;
        let { settingVisible, height, width, profile, devicePreference, fsm, stream, mediaServers, lastPageVisiable, conferenceReportVisible } = this.state;
        let { t, login, user } = this.props;
  
        return <Modal
            className="setting"
            width={"50%"}
            title={<span className="title">
                <IconFont className="icon" type="icon-setting-dark"/>
                <span>{t('setting.info')}</span>
            </span>}
            closable={true}
            maskClosable={true}
            visible={settingVisible}
            onCancel={(e) => {
                this.setState({
                    settingVisible: false,
                })
            }}
            footer={null}
            destroyOnClose={true}
        >
            <Setting
                This={this}
                width={width}
                height={height}
                profile={profile}
                login={login}
                user={user}
                fsm={fsm}
                stream={stream}
                mediaServers={mediaServers}
                sessionInfo={this.sipSessionInfo}
                copyOnClick={this.iconCopyOnClick}
                cameraDeviceId={devicePreference.get('camera', mediaDevice)}
                cameras={mediaDevice.cameras}
                microphoneDeviceId={devicePreference.get('microphone', mediaDevice)}
                microphones={mediaDevice.microphones}
                loudspeakerDeviceId={devicePreference.get('loudspeaker', mediaDevice)}
                loudspeakers={mediaDevice.loudspeakers}
                onChange={(classify, deviceId) => {                        
                    let { fsm, stream } = that.state;
                    let oldDeviceId = devicePreference.get(classify, mediaDevice);
                    if (oldDeviceId !== deviceId) {
                        devicePreference.set(classify, deviceId)
                    }
                    if (fsm.test(FsmState.Call.INIT)) {
                        let config = {};
                        if (classify === "camera") {
                            config = {
                                video_input_device_id: deviceId,
                            }
                            if (oldDeviceId !== deviceId) {
                                if (!conferenceReportVisible && (conferenceReportVisible || lastPageVisiable !== 'report')) {
                                    stream.preview.start(deviceId);
                                }
                            }
                        } else if (classify === "microphone") {
                            config = {
                                audio_input_device_id: deviceId,
                            }
                        } else if (classify === "loudspeaker") {
                            config = {
                                audio_output_device_id: deviceId,
                            }
                            stream.setSinkId('dom_a_remote0', deviceId);
                            stream.setSinkId('dom_a_remote1', deviceId);
                            stream.setSinkId('dom_a_remote2', deviceId);
                            stream.setSinkId('dom_a_remote3', deviceId);
                        } else {
                            return;
                        }

                        // 更新配置
                        that.sipConfig(config);
                    } else {
                        Log.error(that.sipSessionInfo(), `status is not right, ${fsm.curState()} != ${FsmState.Call.INIT}`)
                    }
                }}
            />
        </Modal>
    }

    // 会议详情对话框
    mkConferenceDetail() {
        let { detailVisible, detail, liveState, } = this.state;
        return <Detail
            visible={detailVisible}
            detail={detail}
            living={liveState === LiveState.START}
            copyOnClick={this.iconCopyOnClick}
            onClose={(e) => {this.setState({detailVisible: false})}}
            conferenceAddrFun={this.conferenceAddr}
            liveAddrFun={this.liveAddr}
        />
    }

    // 音视频信息对话框
    mkRtcInfo() {
        let { rtcInfoVisible, asReports, arReports, vsReports, vrReports, iceDescription, height } = this.state;
        return <Rtc
            height={height}
            visible={rtcInfoVisible}
            iceDescription={iceDescription}
            asReports={asReports}
            arReports={arReports}
            vsReports={vsReports}
            vrReports={vrReports}
            data={this.rtc}
            onClose={(e) => {this.setState({rtcInfoVisible: false})}}
        />
    }

    // 权限帮助
    mkPermissionHelp() {
        let { permissionHelpVisible } = this.state;
        return <Modal
            className="permission-help"
            closable={false}
            width="70%"
            visible={permissionHelpVisible}
            onCancel={(e) => {
                this.setState({
                    permissionHelpVisible: false
                })
            }}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <Tabs animated={true}>
                    <Tabs.TabPane key="chrome" tab="谷歌Chrome浏览器">
                        <Carousel>
                            <div><img className="help-enable-video" alt="" src="./images/chrome-help-enable-video-1.png"/></div>
                        </Carousel>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="edge" tab="微软Edge浏览器">
                        <Carousel>
                            <div><img className="help-enable-video" alt="" src="./images/edge-help-enable-video-1.png" /></div>
                        </Carousel>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="qq" tab="腾讯QQ浏览器">
                        <LeftCircleFilled className="slick-btn left" onClick={(e) => {
                            this._qqCarousel && this._qqCarousel.prev && this._qqCarousel.prev()
                        }}/>
                        <Carousel dots={{ className: "carousel-dot" }} ref={(element) => this._qqCarousel = element}>
                            <div><img className="help-enable-video" alt="" src="./images/qq-help-enable-video-1.png"/></div>
                            <div><img className="help-enable-video" alt="" src="./images/qq-help-enable-video-2.png"/></div>
                        </Carousel>
                        <RightCircleFilled className="slick-btn right" onClick={(e) => {
                            this._qqCarousel && this._qqCarousel.next && this._qqCarousel.next()
                        }}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="sougou" tab="搜狗浏览器">
                        <LeftCircleFilled className="slick-btn left" onClick={(e) => {
                            this._sougouCarousel && this._sougouCarousel.prev && this._sougouCarousel.prev()
                        }}/>
                        <Carousel dots={{ className: "carousel-dot" }} ref={(element) => this._sougouCarousel = element}>
                            <div><img className="help-enable-video" alt="" src="./images/sougou-help-enable-video-1.png"/></div>
                            <div><img className="help-enable-video" alt="" src="./images/sougou-help-enable-video-2.png"/></div>
                        </Carousel>
                        <RightCircleFilled className="slick-btn right" onClick={(e) => {
                            this._sougouCarousel && this._sougouCarousel.next && this._sougouCarousel.next()
                        }}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="360" tab="360浏览器">
                        <LeftCircleFilled className="slick-btn left" onClick={(e) => {
                            this._360Carousel && this._360Carousel.prev && this._360Carousel.prev()
                        }}/>
                        <Carousel dots={{ className: "carousel-dot" }} ref={(element) => this._360Carousel = element}>
                            <div><img className="help-enable-video" alt="" src="./images/360-help-enable-video-1.png"/></div>
                            <div><img className="help-enable-video" alt="" src="./images/360-help-enable-video-2.png"/></div>
                        </Carousel>
                        <RightCircleFilled className="slick-btn right" onClick={(e) => {
                            this._360Carousel && this._360Carousel.next && this._360Carousel.next()
                        }}/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Modal>
    }

    // 入会向导对话框
    mkConferenceWizzard() {
        let { conferenceWizzardVisible } = this.state;
        let { t } = this.props;
        let span = !version.isProduction() ? 8 : 12;
        return <Modal
            className="wizzard"
            wrapClassName="wizzard-wrap"
            width={"50%"}
            mask={false}
            closable={false}
            maskClosable={true}
            visible={conferenceWizzardVisible}
            onCancel={(e) => {
                Log.info(this.sipSessionInfo(), `ui event: page wizzard invisible onclick`);
                this.setState({
                    ...this.pageInvisible('wizzard')
                })
            }}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <Typography.Title level={4}>{t('modal.conference.title')}</Typography.Title>
                <Row justify="space-between" align="middle" className="select">
                    {
                        !version.isProduction() ? <Col span={span} className="wrapper" 
                            onClick={(e) => {
                                    Log.info(this.sipSessionInfo(), `ui event: page create onclick`);
                                    e.stopPropagation();
                                    function fun() {
                                        this.setState({
                                            ...this.pageVisible('create')
                                        });
                                    }
                                    this.uiPreproc(fun);
                                }
                            }>
                            <IconFont className="icon" type="icon-startup"/>
                            <span className="description">{t('modal.conference.create')}</span>
                        </Col> : undefined
                    }
                    <Col span={span} className="wrapper" 
                        onClick={(e) => {
                                Log.info(this.sipSessionInfo(), `ui event: page join onclick`);
                                e.stopPropagation();
                                function fun() {
                                    this.setState({
                                        ...this.pageVisible('join'),
                                    });
                                }
                                this.uiPreproc(fun);
                            }
                        }>
                        <IconFont className="icon" type="icon-group"/>
                        <span className="description">{t('modal.conference.join')}</span>
                    </Col>
                    <Col span={span}
                        className="wrapper"
                        onClick={(e) => {
                            Log.info(this.sipSessionInfo(), `ui event: page report onclick`);
                            e.stopPropagation();
                            function fun() {
                                this.setState({
                                    ...this.pageVisible('report')
                                })
                            }
                            this.uiPreproc(fun);
                        }
                    }>
                        <IconFont className="icon" type="icon-bar-chart"/>
                        <span className="description">{t('modal.conference.report')}</span>
                    </Col>
                </Row>
            </div>
        </Modal>
    }

    // 远程投屏对话框
    mkConferenceReport() {
        let { conferenceReportVisible, screenCode, lastPageVisiable, mediaServers } = this.state;
        let { login, t } = this.props;

        let qrcodeValue = {
            sipNum: login?.owner,
            mediaServers: mediaServers,
        }
        let jsonQRcode = formatJson(qrcodeValue, { newlineAfterColonIfBeforeBraceOrBracket: false, padding: ''})
        jsonQRcode = jsonQRcode.replace(/(\r\n)/g, '')
        return <Modal
            className="report"
            wrapClassName="report-wrap"
            width={"50%"}
            mask={false}
            closable={false}
            maskClosable={true}
            // centered={true}
            visible={conferenceReportVisible}
            onCancel={() => {
                Log.info(this.sipSessionInfo(), `ui event: page report invisible onclick`);
                this.setState({
                    ...this.pageInvisible('report')
                })
            }}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <div className="title space-between">
                    {/* <IconFont className="icon" type="icon-group"/> */}
                    <Typography.Title className="description" level={4}>{t('modal.report_conference.title')}</Typography.Title>
                    <div className="operation">
                        <Button type="link" onClick={() => {
                            this.setState({
                                ...this.pageVisible('wizzard'),
                            })
                        }}>{t('modal.report_conference.wizzard')}</Button>
                        {/* <Dropdown
                            className="more"
                            placement="topRight"
                            arrow
                            overlay={<Menu onClick={(e) => {
                                Log.info(this.sipSessionInfo(), `ui event: page switch ${lastPageVisiable} -> ${e?.key} onclick`);

                                this.setState({
                                    ...this.pageVisible(e?.key),
                                })
                            }}>
                                <Menu.Item key="wizzard">{t('modal.report_conference.wizzard')}</Menu.Item>
                                {!version.isProduction() ? <Menu.Item key="create">{t('modal.report_conference.create')}</Menu.Item> : undefined}
                                <Menu.Item key="join">{t('modal.report_conference.join')}</Menu.Item>
                            </Menu>
                            }>
                                <Button type="link" className="btn-more" onClick={(e) => e.stopPropagation()}>{t('modal.report_conference.more')}</Button>
                        </Dropdown> */}
                    </div>
                </div>
                <div className="input-wrapper">
                    <Input
                        id="code"
                        className="input"
                        value={screenCode}
                        size="large"
                        autoComplete={"off"}
                        autoFocus={true}
                        precision={0}
                        placeholder={t('modal.report_conference.input.placeholder')}
                        onChange={(e) => {
                            this.setState({
                                screenCode: e.target.value,
                            })
                        }}
                        onPressEnter={(e) => {
                            if (screenCode) {
                                this.meetReportOnClick(e, screenCode)
                            } else {
                                message.error(t('modal.report_conference.message.input.screen_code.empty'));
                            }
                        }}
                        // onFocus={(e) => e.target.select()}
                    />
                    <Button className="ok" type="primary" size="large" htmlType="submit" onClick={(e) => {
                        e.stopPropagation();
                        if (screenCode) {
                            this.meetReportOnClick(e, screenCode)
                        } else {
                            message.error(t('modal.report_conference.message.input.screen_code.empty'));
                        }
                    }}>
                        {t('modal.report_conference.btn.start')}
                    </Button>
                </div>
                <div className="qrcode">
                    <QRCode size={256} level="L" value={jsonQRcode} />
                </div>
                {this.mkWarn(false, true, true)}
            </div>
        </Modal>
    }

    // 创建会议对话框
    mkConferenceCreate() {
        let { conferenceCreateVisible, title, exist, profile, sipConfig, stream, lastPageVisiable } = this.state;
        let {t} = this.props;
        let existComponent = undefined;
        let ok = true;
        if (mediaDevice.cameraPerm !== mediaDevice.permission.allow ||
            mediaDevice.microphonePerm !== mediaDevice.permission.allow ||
            mediaDevice.loudspeakerPerm !== mediaDevice.permission.allow ||
            !stream.preview.status) {
            ok = false;
        }

        if (exist) {
            let creator = exist.participants?.find((item) => item?.user?.id === exist?.conference?.creatorId);
            existComponent = <div className="exist-wrapper">
                <div className="tip">{t('modal.create_conference.exist.tip')}</div>
                <Descriptions className="descriptions" column={1}>
                    <Descriptions.Item className="theme" label={t('modal.create_conference.exist.descriptions.theme')}>{exist.conference?.subject}</Descriptions.Item>
                    <Descriptions.Item className="code" label={t('modal.create_conference.exist.descriptions.code')}>{exist.conference?.accessCode}</Descriptions.Item>
                    <Descriptions.Item className="creator" label={t('modal.create_conference.exist.descriptions.creator')}>{creator?.user?.nickName || profile?.name}</Descriptions.Item>
                </Descriptions>
                <Space className="buttons">
                    <Button danger type="primary" 
                        onClick={(e) => {
                            this.meetDelOnClick(e, exist.conference?.id);
                        }}>{t('modal.create_conference.exist.btn.end')}</Button>
                    <Button type="primary" 
                        onClick={(e) => {
                            if (ok) {
                                this.callType = "audiovideo";
                                // 更新配置
                                let config = Object.assign(sipConfig, {
                                    s_call_type: this.callType,
                                })
                                this.sipConfig(config);
                                this.meetJoinAgainOnClick(e, exist);
                            }
                        }}>{t('modal.create_conference.exist.btn.join')}</Button>
                </Space>
            </div>
        } else {
            existComponent = <div className="input-wrapper">
                <Input 
                    className={"input " + (!ok ? "danger" : "")}
                    value={title} 
                    size="large" 
                    autoFocus={true} 
                    placeholder={t('modal.create_conference.new.input.placeholder')} 
                    onChange={(e) => {
                        this.setState({
                            title: e.target.value
                        })
                    }} 
                    onFocus={(e) => {
                        // 获得焦点时，自动全选内容
                        e.stopPropagation();
                        e.target.select();
                    }}
                    onPressEnter={(e) => {
                        if (ok) {
                            this.callType = "audiovideo";
                            // 更新配置
                            let config = Object.assign(sipConfig, {
                                s_call_type: this.callType,
                            })
                            this.sipConfig(config);
                            this.meetCreateAndJoinOnClick(e)
                        }
                        
                    }}
                />
                <Button className="ok" type="primary" danger={!ok} size="large" onClick={(e) => {
                    if (ok) {
                        this.callType = "audiovideo";
                        // 更新配置
                        let config = Object.assign(sipConfig, {
                            s_call_type: this.callType,
                        })
                        this.sipConfig(config);
                        this.meetCreateAndJoinOnClick(e)
                    }
                }}>{t('modal.create_conference.new.btn.create')}</Button>
            </div>
        }

        return <Modal
            className="create"
            wrapClassName="create-wrap"
            width={"50%"}
            mask={false}
            closable={false}
            maskClosable={true}
            visible={conferenceCreateVisible}
            onCancel={() => {
                Log.info(this.sipSessionInfo(), `ui event: page create invisible onclick`);
                this.setState({
                    ...this.pageInvisible('create'),
                    exist: undefined
                })
            }}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <div className="title space-between">
                    {/* <IconFont className="icon" type="icon-startup"/> */}
                    <Typography.Title className="description" level={4}>{t('modal.create_conference.title')}</Typography.Title>
                    <div className="operation">
                        <Button type="link" onClick={() => {
                            this.setState({
                                ...this.pageVisible('wizzard'),
                            })
                        }}>{t('modal.create_conference.wizzard')}</Button>
                        {/* <Dropdown
                            className="more"
                            placement="topRight"
                            arrow
                            overlay={<Menu onClick={(e) => {
                                Log.info(this.sipSessionInfo(), `ui event: page switch ${lastPageVisiable} -> ${e?.key} onclick`);
                                this.setState({
                                    ...this.pageVisible(e?.key),
                                })
                            }}>
                                    <Menu.Item key="wizzard">{t('modal.create_conference.wizzard')}</Menu.Item>
                                    <Menu.Item key="join">{t('modal.create_conference.join')}</Menu.Item>
                                    <Menu.Item key="report">{t('modal.create_conference.report')}</Menu.Item>
                                </Menu>
                            }>
                                <Button type="link" className="btn-more">{t('modal.create_conference.more')}</Button>
                        </Dropdown> */}
                    </div>
                </div>
                {existComponent}
                {this.mkWarn(true, true, true)}
            </div>
        </Modal>
    }

    // 加入会议对话框
    mkConferenceJoin() {
        let { conferenceJoinVisible, code, dialHistory, sipConfig, stream, optionMicrophoneEnable, optionCameraEnable, optionLoudspeakerEnable } = this.state;
        let { t } = this.props;
        let ok = true;
        if (mediaDevice.cameraPerm !== mediaDevice.permission.allow ||
            mediaDevice.microphonePerm !== mediaDevice.permission.allow ||
            mediaDevice.loudspeakerPerm !== mediaDevice.permission.allow ||
            !stream.preview.status) {
            ok = false;
        }

        return <Modal
            className="join"
            wrapClassName="join-wrap"
            width={"50%"}
            mask={false}
            closable={false}
            maskClosable={true}
            // centered={true}
            visible={conferenceJoinVisible}
            onCancel={() => {
                Log.info(this.sipSessionInfo(), `ui event: page join invisible onclick`);
                this.setState({
                    ...this.pageInvisible('join'),
                })
            }}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <div className="title space-between">
                    {/* <IconFont className="icon" type="icon-group"/> */}
                    <Typography.Title className="description" level={4}>{t('modal.join_conference.title')}</Typography.Title>
                    <div className="operation">
                        <Button type="link" onClick={() => {
                            this.setState({
                                ...this.pageVisible('wizzard'),
                            })
                        }}>{t('modal.join_conference.wizzard')}</Button>
                        {/* <Dropdown
                            className="more"
                            placement="topRight"
                            arrow
                            overlay={<Menu onClick={(e) => {
                                Log.info(this.sipSessionInfo(), `ui event: page switch ${lastPageVisiable} -> ${e?.key} onclick`);

                                this.setState({
                                    ...this.pageVisible(e?.key),
                                })
                            }}>
                                    <Menu.Item key="wizzard">{t('modal.join_conference.wizzard')}</Menu.Item>
                                    {!version.isProduction() ? <Menu.Item key="create">{t('modal.join_conference.create')}</Menu.Item> : undefined}
                                    <Menu.Item key="report">{t('modal.join_conference.report')}</Menu.Item>
                                </Menu>
                            }>
                                <Button type="link" className="btn-more" onClick={(e) => e.stopPropagation()}>{t('modal.join_conference.more')}</Button>
                        </Dropdown> */}
                    </div>
                </div>
                <div className="input-wrapper">
                    <Input
                        id="code"
                        className={"input " + (!ok ? "danger" : "")}
                        ref={(element) => {this.codeInputDOM = element;}}
                        value={code} 
                        size="large" 
                        autoComplete={"off"}
                        autoFocus={true} 
                        precision={0}
                        placeholder={t('modal.join_conference.input.placeholder')} 
                        onChange={(e) => {
                            this.setState({
                                code: e.target.value,
                            })
                        }} 
                        onPressEnter={(e) => {
                            if (ok) {
                                this.callType = "audiovideo";
                                // 更新配置
                                let config = Object.assign(sipConfig, {
                                    s_call_type: this.callType,
                                })
                                this.sipConfig(config);
                                this.meetJoinOnClick(e, code)
                            }
                        }}
                        // onFocus={(e) => e.target.select()}
                    />
                    <Button className="ok" type="primary" size="large" danger={!ok} htmlType="submit" onClick={(e) => {
                        e.stopPropagation();
                        if (ok) {
                            this.callType = "audiovideo";
                            // 更新配置
                            let config = Object.assign(sipConfig, {
                                s_call_type: this.callType,
                            })
                            this.sipConfig(config);
                            this.meetJoinOnClick(e, code)
                        }
                    }}>
                        {t('modal.join_conference.btn')}
                    </Button>
                </div>
                <Collapse
                    className="options-collapse"
                    bordered={false}
                    defaultActiveKey="main"
                    expandIcon={({ isActive }) => <CaretRightOutlined style={{color: "#1890ff"}} rotate={isActive ? 90 : 0} />}
                >
                    <Collapse.Panel
                        key="main"
                        header={<Button type="link" className="panel-title">{t('modal.join_conference.options.title')}</Button>}
                    >
                        <div className="options">
                            <Checkbox checked={optionMicrophoneEnable} onChange={(e) => {
                                window.localStorage && window.localStorage.setItem("optionMicrophoneEnable", e.target.checked)
                                this.setState({optionMicrophoneEnable: e.target.checked})
                            }}>{t('modal.join_conference.options.microphone_enable')}</Checkbox>
                            <Checkbox checked={optionCameraEnable} onChange={(e) => {
                                window.localStorage && window.localStorage.setItem("optionCameraEnable", e.target.checked)
                                this.setState({optionCameraEnable: e.target.checked})
                            }}>{t('modal.join_conference.options.camera_enable')}</Checkbox>
                            {/* <Checkbox checked={optionLoudspeakerEnable} onChange={(e) => {
                                window.localStorage && window.localStorage.setItem("optionLoudspeakerEnable", e.target.checked)
                                this.setState({optionLoudspeakerEnable: e.target.checked})
                            }}>{t('modal.join_conference.options.loudspeaker_enable')}</Checkbox> */}
                        </div>
                    </Collapse.Panel>
                </Collapse>
                {
                    ok ? <Collapse 
                        className="history-collapse"
                        bordered={false}
                        onChange={(e) => this.commonOnChange(e)}
                        expandIcon={({ isActive }) => <CaretRightOutlined style={{color: "#1890ff"}} rotate={isActive ? 90 : 0} />}
                        >
                        <Collapse.Panel
                            key="main" 
                            header={<Button type="link" className="panel-title">{t('modal.join_conference.history.title')}</Button>}
                        >
                            <List
                                itemLayout="vertical"
                                dataSource={dialHistory.getAll()}
                                rowKey="key"
                                size="large"
                                renderItem={item => {
                                    let icon = undefined;
                                    let details = [];
                                    let theme = t('modal.join_conference.history.theme');
                                    let duration = t('modal.join_conference.history.duration');
                                    let reason = t('modal.join_conference.history.reason');
                                    let creator = t('modal.join_conference.history.creator');
                                    let none = t('common.none')
                                    let second = t('common.second')
                                    if (item.status === "success") {
                                        icon = "icon-call-history-success";
                                        details.push({label: theme, content: item.theme || none});
                                        details.push({label: duration, content: getTimeDesc(item.end - item.start)});
                                    } else if (item.status === "process") {
                                        icon = "icon-process";
                                        details.push({label: theme, content: item.theme || none});
                                        details.push({label: duration, content: `0${second}`});
                                    } else if (item.status === "cancel") {
                                        icon = "icon-call-history-cancel";
                                        details.push({label: theme, content: item.theme || none});
                                        details.push({label: duration, content: `0${second}`});
                                        details.push({label: reason, content: item.reason});
                                    } else {
                                        icon = "icon-failed";
                                        details.push({label: theme, content: item.theme || none});
                                        details.push({label: duration, content: `0${second}`});
                                        details.push({label: reason, content: item.reason});
                                    }
                                    details.push({label: creator, content: item.creator});

                                    return <List.Item 
                                        actions={[
                                            <Space className="actions">
                                                <div onClick={(e) => {
                                                    if (ok) {
                                                        this.callType = "audiovideo";
                                                        // 更新配置
                                                        let config = Object.assign(sipConfig, {
                                                            s_call_type: this.callType,
                                                        })
                                                        this.sipConfig(config);
                                                        this.meetJoinOnClick(e, item.code)
                                                    }
                                                }}>
                                                    <Button type="link" icon={<IconFont className="icon" type="icon-video"/>}>{t('modal.join_conference.history.join_again')}</Button>
                                                </div>
                                                <div onClick={(e) => {
                                                    Log.info(this.sipSessionInfo(), `ui event: delete conference history onclick. details: ${printObject(item)}`);
                                                    e.stopPropagation();
                                                    dialHistory.del(item?.key);
                                                    this.commonOnChange();
                                                }}>
                                                    <Button type="link" icon={<IconFont className="icon" type="icon-delete"/>}>{t('modal.join_conference.history.delete')}</Button>
                                                </div>
                                            </Space>
                                        ]}
                                        >
                                        <List.Item.Meta
                                            title={<Row className="title" justify="space-between" align="middle" >
                                                <Col span={12}>
                                                    <IconFont className="icon" type={icon}/>
                                                    <span className="code" onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setState({
                                                                code: codeFormatter(item.code),
                                                            });
                                                        }
                                                    }>{codeFormatter(item.code)}</span>
                                                </Col>
                                                <Col span={12} className="time-col">
                                                    <span className="time">{getDateDiff(moment().unix() - item.start, item.start)}</span>
                                                </Col>
                                            </Row>}
                                            description={
                                                <Descriptions className="descriptions" column={1}>
                                                    {details.map((item, index) => <Descriptions.Item key={index} className="item" label={item.label}>{item.content}</Descriptions.Item>)}
                                                </Descriptions>
                                            }
                                        />
                                    </List.Item>
                                }}
                            />
                        </Collapse.Panel>
                    </Collapse> : undefined
                }
                {this.mkWarn(true, true, true)}
            </div>
        </Modal>
    }

    // 挂断会议对话框
    mkConferenceHangup() {
        let { hangupVisible, number } = this.state;
        let { t, } = this.props;
        return <Modal
            className="hangup"
            closable={false}
            visible={hangupVisible}
            onCancel={() => {
                this.setState({
                    hangupVisible: false
                })
            }}
            footer={
                <Space>
                    <Tooltip key="cancel" overlayClassName="tooltip-normal2" title={t('modal.hangup_conference.btn.cancel.tooltip')} placement="top">
                        <Button onClick={(e) => {
                            Log.info(this.sipSessionInfo(), `ui event: icon hangup onclick.`);
                            e.stopPropagation(); 
                            this.setState({hangupVisible: false});
                        }}>
                            {t('modal.hangup_conference.btn.cancel.title')}
                        </Button>
                    </Tooltip>
                    <Tooltip key="del" overlayClassName="tooltip-normal2" title={t('modal.hangup_conference.btn.del.tooltip')} placement="top">
                        <Button danger type="primary" onClick={(e) => {
                            e.stopPropagation(); 
                            this.meetDelOnClick(e, number, 'normal');
                        }}>
                            {t('modal.hangup_conference.btn.del.title')}
                        </Button>
                    </Tooltip>
                    <Tooltip key="hangup" overlayClassName="tooltip-normal2" title={t('modal.hangup_conference.btn.hangup.tooltip')} placement="top">
                        <Button type="primary" onClick={(e) => {
                            e.stopPropagation(); 
                            this.meetHangupOnClick(e, number, 'normal');
                        }}>
                            {t('modal.hangup_conference.btn.hangup.title')}
                        </Button>
                    </Tooltip>
                </Space>
            }
            destroyOnClose={true}
        >
            <div className="content">
                <div className="title">
                    <InfoCircleOutlined className="icon"/>
                    <Typography.Title className="description" level={4}>{t('modal.hangup_conference.title')}</Typography.Title>
                </div>
                <div className="content">
                    <span className="description">{t('modal.hangup_conference.content')}</span>
                </div>
            </div>
        </Modal>
    }

    // 头部菜单栏
    mkMeetHeader(meetStyle) {
        let { stream, fsm, barVisible, number, code, sipCallStartTime, profile, detail, screen, isInternalMs,
            loginStatus, websocketStatus, websocketRestartCnt, sipStackStatus, sipStackRestartCnt 
        } = this.state;
        let { t, login } = this.props;
        let fsmProc = fsm.test(FsmState.Call.PROCESS);
        let fsmStable = fsm.test(FsmState.Call.STABLE);

        // 个人信息
        let statusOk = !(loginStatus !== 'success' || (websocketStatus !== 'success' && websocketRestartCnt >= 3) || (sipStackStatus !== 'started' && sipStackRestartCnt >= 3));
        let sipStackT = (sipStackStatus !== 'started' && sipStackRestartCnt >= 3) ? 'sip.stack.status.offline' : 'sip.stack.status.online';
        let sipStackClassName = (sipStackStatus !== 'started' && sipStackRestartCnt >= 3) ? 'offline' : 'online';
        let registerT = loginStatus === 'success' ? 'sip.register.status.online' : 'sip.register.status.offline';
        let registerClassName = loginStatus === 'success' ? 'online' : 'offline';
        let websocketT = (websocketStatus !== 'success' && websocketRestartCnt >= 3) ? 'websocket.status.offline' : 'websocket.status.online';
        let websocketClassName = (websocketStatus !== 'success' && websocketRestartCnt >= 3) ? 'offline' : 'online';
        let tipClassName = 'row tip hidden';
        if ((websocketStatus !== 'success' && websocketRestartCnt >= 3) || (sipStackStatus !== 'started' && sipStackRestartCnt >= 3)) {
            tipClassName = 'row tip offline show';
        }

        // 状态信息
        let sts = [];
        // 全场静音
        let mute = detail?.profile?.mute?.active || false;
        sts.push(<Tooltip key="mute" overlayClassName={"tooltip-normal"} title={t('profile.status.audio_lock')} placement="bottomRight">
            <IconFont className={"icon-" + (mute ? "show" : "hidden")} type="icon-audio-lock"/>
        </Tooltip>)

        // 内外网
        if (isInternalMs !== undefined) {
            sts.push(<Tooltip key="lan" overlayClassName={"tooltip-normal"} title={t(isInternalMs ? 'profile.status.lan' : 'profile.status.cloud')} placement="bottomRight">
                <IconFont className="icon" type={isInternalMs ? "icon-lan" : "icon-cloud"}/>
            </Tooltip>)
        }

        let profileComponent = (
            <div className="profile">
                <div className="row">
                    <Tooltip overlayClassName={"tooltip-normal"}
                        title={<Descriptions className="detail" column={1}>
                            <Descriptions.Item label={t('sip.stack.status.title')}><span className={sipStackClassName}>{t(sipStackT)}</span></Descriptions.Item>
                            <Descriptions.Item label={t('websocket.status.title')}><span className={websocketClassName}>{t(websocketT)}</span></Descriptions.Item>
                            <Descriptions.Item label={t('sip.register.status.title')}><span className={registerClassName}>{t(registerT)}</span></Descriptions.Item>
                        </Descriptions>}
                        placement="bottomLeft">
                        <span className="name">
                            <span className={statusOk ? "dot dot-success" : "dot dot-failed"}/>
                            <img className="avatar" src={profile?.avatarUrl} alt=""/>
                            <span className="description">{profile?.name}</span>
                        </span>
                    </Tooltip>
                    <span className="status">{sts}</span>
                </div>
                <div className={tipClassName}>
                    <div>{(websocketStatus !== 'success' && websocketRestartCnt >= 3) ? t('websocket.status.reconnecting') : undefined}</div>
                    <div>{(sipStackStatus !== 'started' && sipStackRestartCnt >= 3) ? t('sip.stack.status.reconnecting') : undefined}</div>
                </div>
            </div>
        )

        

        // 主题
        let themeComponent = undefined;
        if (fsmProc || fsmStable) {
            code = code?.toString() || "";
            if (code.length > 0) {
                code = codeFormatter(code)
            }
            themeComponent = <span className="theme">{code}</span>
        }

        // 计时器
        let timerComponent = (
            <Timer className="timer" start={sipCallStartTime}/>
        )

        // 退出
        let exitComponent = (
            <Tooltip overlayClassName="tooltip-normal" title={t('operation.exit.tooltip')} placement="bottomRight">
                <Popconfirm placement="bottomRight" title={t('operation.exit.confirm')} okText={t('operation.exit.ok')} cancelText={t('operation.exit.cancel')}
                    onConfirm={(e) => {
                        Log.info(this.sipSessionInfo(), `ui event: exit login onclick.`);
                        e.stopPropagation();
                        if (fsmProc || fsmStable) {
                            this.meetHangupOnClick(e, number, 'normal');
                        }
                        this.sipUnregister();
                    }} >
                    <IconFont className="icon" type="icon-exit" onClick={(e) => {e.stopPropagation();}}/>
                </Popconfirm>
            </Tooltip>
        )

        // 会议设置
        let settingComponent = <Tooltip overlayClassName="tooltip-normal" title={t('operation.setting.tooltip')} placement="bottomRight">
            <IconFont className="icon" type="icon-setting" onClick={(e) => {
                Log.info(this.sipSessionInfo(), `ui event: icon setting onclick.`);
                e.stopPropagation();
                this.setState({
                    settingVisible: true,
                })
            }}/>
        </Tooltip>

        // 成员列表
        let memberListComponent = undefined;
        if (this.callType !== "screenshare") {
            memberListComponent = (
                <Tooltip overlayClassName="tooltip-normal" title={t('operation.member_list.tooltip')} placement="bottomRight">
                    <IconFont className="icon" type="icon-member-list" onClick={(e) => {
                        Log.info(this.sipSessionInfo(), `ui event: icon member-list onclick.`);
                        e.stopPropagation();
                        this.setState({
                            memberVisible: !this.state.memberVisible,
                        })
                    }}/>
                </Tooltip>
            )
        }

        // 媒体信息
        let rtcComponent = (
            <Tooltip overlayClassName="tooltip-normal" title={t('operation.media.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-rtc" onClick={(e) => {
                    Log.info(this.sipSessionInfo(), `ui event: icon rtc onclick.`);
                    e.stopPropagation();
                    this.setState({
                        rtcInfoVisible: true,
                    })
                }}/>
            </Tooltip>
        )

        // 会议信息
        let detailComponent = (
            <Tooltip overlayClassName="tooltip-normal" title={t('operation.conference.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-info" onClick={(e) => {
                    Log.info(this.sipSessionInfo(), `ui event: icon detail onclick.`);
                    e.stopPropagation();
                    this.setState({
                        detailVisible: true,
                    })
                }}/>
            </Tooltip>
        )
        
        // 分享
        // let shareComponent = (
        //     <Tooltip overlayClassName={"tooltip-normal"} title={t('operation.share.tooltip')} placement="bottomRight">
        //         <IconFont className="icon" type="icon-share2" onClick={(e) => this.iconCopyOnClick(e, "conference", this.conferenceAddr(detail))}/>
        //     </Tooltip>
        // )

        // 隐藏菜单栏
        // let hiddenComponent = (
        //     <Tooltip overlayClassName={"tooltip-normal"} title={t('operation.hidden.tooltip')} placement="bottomRight">
        //         <IconFont className="icon" type="icon-hidden" onClick={(e) => this.barVisibleOnClick(e, 'icon')}/>
        //     </Tooltip>
        // )

        let header1 = <Drawer
            className="drawer-header"
            placement={"top"}
            closable={false}
            visible={!!(meetStyle.opacity && barVisible)}
            mask={false}
            keyboard={false}
            height={64}
            zIndex={400}
            key={"drawer_top"}
            >
            <Row className="content" justify="space-around" align="middle">
                <Col span={8} className="left">
                    <div className="status">
                        {profileComponent}
                    </div>
                </Col>
                <Col span={8} className="middle">
                    {themeComponent}
                    {timerComponent}
                </Col >
                <Col span={8} className="right">
                    {exitComponent}
                    {settingComponent}
                    {
                        fsmStable ? <div>
                            {memberListComponent}
                            {rtcComponent}
                            {detailComponent}
                            {/* {shareComponent} */}
                            {/* {hiddenComponent} */}
                        </div> : undefined
                    }
                </Col>
            </Row>
        </Drawer>
        
        // 找到目前正在预览的成员
        let header2 = undefined;
        if (fsm.test(FsmState.Call.STABLE)) {
            let curViewUserId = screen?.media?.[0] || "";
            let member = detail?.participants?.find(m => m?.user?.id === curViewUserId);
            if (member) {
                header2 = <div className={"drawer-header2 " + (barVisible ? "hidden" : "show")}>
                    <Tag className="nickname" color="#f0f0f0">{member?.user?.nickName}</Tag>
                </div>
            }
        }

        return <div>
            {header1}
            {header2}
        </div>
    }

    // 底部菜单栏
    mkMeetFooter(meetStyle) {
        let { fsm, barVisible, detail, number,
            videoEnable, audioEnable, localEnable, remoteEnable, boardEnable, remotesEnable, 
            layoutMode, menuLock, boardShare, screenShare, backgroudViewType, screenSharerId, 
            optionLoudspeakerEnable
        } = this.state;
        let { t, login } = this.props;
        let leftIcons = [];
        let midIcons = [];
        let rightIcons = [];

        let hangupComponent = undefined;
        if (login?.owner === detail?.conference?.creatorId) {
            hangupComponent = <Tooltip key="hangup" overlayClassName="tooltip-normal" title={t('operation.hangup.tooltip')} placement="top">
                <IconFont className="main" type="icon-hangup" onClick={(e) => {
                    Log.info(this.sipSessionInfo(), `ui event: icon hangup onclick.`);
                    e.stopPropagation(); 
                    this.setState({
                        hangupVisible: true
                    })
                }}/>
            </Tooltip>
        } else {
            if (this.callType === "screenshare") {
                hangupComponent = <Popconfirm key="screenshare" placement="top" title={t('operation.screen_share.confirm')} okText={t('operation.screen_share.ok')} cancelText={t('operation.screen_share.cancel')}
                    onConfirm={(e) => {
                        e.stopPropagation();
                        this.meetHangupOnClick(e, number, 'normal')
                    }} >
                    <Tooltip key="screen_share" overlayClassName="tooltip-normal" title={t('operation.screen_share.tooltip.title')} placement="top">
                        <IconFont className="main" type="icon-hangup" onClick={(e) => {e.stopPropagation();}}/>
                    </Tooltip>
                </Popconfirm>
            } else {
                hangupComponent = <Popconfirm key="hangup" placement="top" title={t('operation.hangup.confirm')} okText={t('operation.hangup.ok')} cancelText={t('operation.hangup.cancel')}
                    onConfirm={(e) => {
                        e.stopPropagation();
                        this.meetHangupOnClick(e, number, 'normal')
                    }} >
                    <Tooltip key="hangup" overlayClassName="tooltip-normal" title={t('operation.hangup.tooltip')} placement="top">
                        <IconFont className="main" type="icon-hangup" onClick={(e) => {e.stopPropagation();}}/>
                    </Tooltip>
                </Popconfirm>
            }
        }

        switch(fsm.curState()) {
            case FsmState.Call.INIT:
                break;
            case FsmState.Call.PROCESS: {
                midIcons.push(
                    <Tooltip key="hangup" overlayClassName="tooltip-normal" title={t('operation.hangup.tooltip')} placement="top">
                        <IconFont key="hangup"  className="main" type="icon-hangup" onClick={(e) => {
                            Log.info(this.sipSessionInfo(), `ui event: conference hangup(by sip) onclick.`);
                            e.stopPropagation();
                            message.loading(t('operation.hangup.cancel_doing'))
                            this.sipHangup('cancel');
                            }}/>
                    </Tooltip>
                )
                break;
            }
            case FsmState.Call.STABLE: {
                if (this.callType !== "screenshare") {
                    leftIcons.push(
                        <Tooltip key="local" overlayClassName="tooltip-normal" title={t(localEnable ? 'operation.local_preview.tooltip.show' : "operation.local_preview.tooltip.hidden")} placement="topRight">
                            <div className={localEnable ? "local-view left selected" : "local-view left unselect"} onClick={(e) => this.iconOnToggle(e, "local")}>
                                <IconFont className="icon" type="icon-local-preview" />
                            </div>
                        </Tooltip>
                    )
                    
                    if (screenSharerId && screenSharerId !== login?.owner) {
                        leftIcons.push(
                            <Tooltip key="remote" overlayClassName="tooltip-normal" title={t(remoteEnable ? 'operation.remote_preview.tooltip.show' : "operation.remote_preview.tooltip.hidden")} placement="topRight">
                                <div className={remoteEnable ? "remote-view left selected" : "remote-view left unselect"} onClick={(e) => this.iconOnToggle(e, "remote")}>
                                    <IconFont className="icon" type="icon-remote-preview" />
                                </div>
                            </Tooltip>
                        )
                    }
                }
                
                if (layoutMode === LayoutMode.OVERLAP) {
                    // leftIcons.push(
                    //     <Tooltip key="room" overlayClassName="tooltip-normal" title={t(remotesEnable ? 'operation.remotes_preview.tooltip.show' : "operation.remotes_preview.tooltip.hidden")} placement="topRight">
                    //         <IconFont className={remotesEnable ? "icon left selected" : "icon left unselect"} type="icon-room" onClick={(e) => this.iconOnToggle(e, "room")}/>
                    //     </Tooltip>
                    // )
                    if (backgroudViewType === BackgroudViewType.VIDEO) {
                        if (detail?.profile?.whiteBoard?.active) {
                            leftIcons.push(
                                <Tooltip key="board" overlayClassName="tooltip-normal" title={t(boardEnable ? 'operation.board_preview.tooltip.show' : "operation.board_preview.tooltip.hidden")} placement="topRight">
                                    <div className={boardEnable ? "board-view left selected" : "board-view left unselect"} onClick={(e) => this.iconOnToggle(e, "board")}>
                                        <IconFont className="icon" type="icon-whiteboard" />
                                    </div>
                                </Tooltip>
                            )
                        }
                    }
                }

                // midIcons.push(
                //     <Tooltip key="screen-share" overlayClassName="tooltip-normal" title={t(optionLoudspeakerEnable ? 'operation.loudspeaker.tooltip.enable' : "operation.loudspeaker.tooltip.disable")} placement="top">
                //         <div className="offcut-video" onClick={(e) => this.iconOnToggle(e, "loudspeaker")}>
                //             <IconFont className="icon" type="icon-volume"/>
                //             <EnDisable className="control" enable={optionLoudspeakerEnable}/>
                //         </div>
                //     </Tooltip>
                // )

                midIcons.push(
                    <Tooltip key="audio" overlayClassName="tooltip-normal" title={t(audioEnable ? 'operation.audio.tooltip.enable' : "operation.audio.tooltip.disable")} placement="top">
                        <div className="offcut-audio" onClick={(e) => this.iconOnToggle(e, "audio")}>
                            <IconFont className="icon" type="icon-microphone"/>
                            <EnDisable className="control" enable={audioEnable}/>
                        </div>
                    </Tooltip>
                )
                midIcons.push(hangupComponent)
                if (this.callType !== "screenshare") {
                    midIcons.push(
                        <Tooltip key="video" overlayClassName="tooltip-normal" title={t(videoEnable ? 'operation.video.tooltip.enable' : "operation.video.tooltip.disable")} placement="top">
                            <div className="offcut-video" onClick={(e) => this.iconOnToggle(e, "video")}>
                                <IconFont className="icon" type="icon-camera"/>
                                <EnDisable className="control" enable={videoEnable}/>
                            </div>
                        </Tooltip>
                    )
                } else {
                    midIcons.push(
                        <Tooltip key="screen-share" overlayClassName="tooltip-normal" title={t(videoEnable ? 'operation.screen_share.tooltip.enable' : "operation.screen_share.tooltip.disable")} placement="top">
                            <div className="offcut-video" onClick={(e) => this.iconOnToggle(e, "video")}>
                                <IconFont className="icon" type="icon-screen-share4"/>
                                <EnDisable className="control" enable={videoEnable}/>
                            </div>
                        </Tooltip>
                    )
                }

                // midIcons.push(
                //     <div className="offcut-none" key="none">
                //         <IconFont className="icon" type="icon-none"/>
                //     </div>
                // )
                
                // rightIcons.push(
                //     <Dropdown key="layoutMode"
                //         overlay={<Menu onClick={(e) => {this.menuItemOnChange(e, "layoutMode")}}>
                //             <Menu.Item key={LayoutMode.OVERLAP}><span className="menu-item"><IconFont type={layoutMode === LayoutMode.OVERLAP ? "icon-right" : ""} /><span className="description">{t('operation.layout_mode.items.overlap')}</span></span></Menu.Item>
                //             <Menu.Item key={LayoutMode.SPLIT_4}><span className="menu-item"><IconFont type={layoutMode === LayoutMode.SPLIT_4 ? "icon-right" : ""} /><span className="description">{t('operation.layout_mode.items.split4')}</span></span></Menu.Item>
                //             <Menu.Item key={LayoutMode.SPLIT_9}><span className="menu-item"><IconFont type={layoutMode === LayoutMode.SPLIT_9 ? "icon-right" : ""} /><span className="description">{t('operation.layout_mode.items.split9')}</span></span></Menu.Item>
                //         </Menu>}
                //     >
                //         <IconFont className="icon right" type="icon-layout" onClick={(e) => {e.stopPropagation();}}/>
                //     </Dropdown>
                // )
                // if (layoutMode === LayoutMode.OVERLAP) {
                //     rightIcons.push(
                //         <Dropdown key="backgroudViewType"
                //             overlay={<Menu onClick={(e) => this.menuItemOnChange(e, "backgroudViewType")}>
                //                 <Menu.Item key={BackgroudViewType.BOARD}><span className="menu-item"><IconFont type={backgroudViewType === BackgroudViewType.BOARD ? "icon-right" : ""} /><span className="description">{t('operation.background_view.items.board')}</span></span></Menu.Item>
                //                 <Menu.Item key={BackgroudViewType.VIDEO}><span className="menu-item"><IconFont type={backgroudViewType === BackgroudViewType.VIDEO ? "icon-right" : ""} /><span className="description">{t('operation.background_view.items.video')}</span></span></Menu.Item>
                //             </Menu>}
                //         >
                //             <div className="backgroud-view right" onClick={(e) => {e.stopPropagation();}}>
                //                 <IconFont className="icon" type="icon-background"/>
                //             </div>
                //         </Dropdown>
                //     )
                // }
                // rightIcons.push(
                //     <Dropdown key="lock"
                //         overlay={<Menu onClick={(e) => this.menuItemOnChange(e, "menuLock")}>
                //             <Menu.Item key={MenuState.LOCK}><span className="menu-item"><IconFont type={menuLock === MenuState.LOCK ? "icon-right" : ""} /><span className="description">{t('operation.menu.items.lock')}</span></span></Menu.Item>
                //             <Menu.Item key={MenuState.UNLOCK}><span className="menu-item"><IconFont type={menuLock === MenuState.UNLOCK ? "icon-right" : ""} /><span className="description">{t('operation.menu.items.unlock')}</span></span></Menu.Item>
                //         </Menu>}
                //     >
                //         <IconFont className="icon right" type={menuLock === MenuState.LOCK ? "icon-lock" : "icon-unlock" } onClick={(e) => {e.stopPropagation();}}/>
                //     </Dropdown>
                // )
                if (this.callType !== "screenshare") {
                    // rightIcons.push(
                    //     <Dropdown key="board" placement="topCenter"
                    //         overlay={<Menu onClick={this.menuItemBoardShareOnChange}>
                    //             <Menu.Item key={BoardShareState.START}><span className="menu-item"><IconFont type={boardShare === BoardShareState.START ? "icon-right" : ""} /><span className="description">{t('operation.board.items.start')}</span></span></Menu.Item>
                    //             <Menu.Item key={BoardShareState.STOP}><span className="menu-item"><IconFont type={boardShare === BoardShareState.STOP ? "icon-right" : ""} /><span className="description">{t('operation.board.items.stop')}</span></span></Menu.Item>
                    //         </Menu>}
                    //     >
                    //         <div className="board right" onClick={(e) => {e.stopPropagation();}}>
                    //             {/* <IconFont className="icon right" type={boardShare === BoardShareState.START ? "icon-board-start" : "icon-board-stop" }/> */}
                    //             <IconFont className="icon" type="icon-whiteboard-5"/>
                    //             <EnDisable2 className="control" enable={boardShare === BoardShareState.START}/>
                    //         </div>
                    //     </Dropdown>
                    // )
                    if (adapter.isScreenShareSupported) {
                        rightIcons.push(
                            <Dropdown key="screen" placement="topCenter"
                                overlay={<Menu onClick={this.menuItemScreenShareOnChange}>
                                    <Menu.Item key={ScreenShareState.START}>
                                        <span className="menu-item">
                                            <IconFont type={screenShare === ScreenShareState.START ? "icon-right" : ""} />
                                            <span className="description">{t('operation.screen.items.start')}</span>
                                        </span>
                                    </Menu.Item>
                                    <Menu.Item key={ScreenShareState.STOP}>
                                        <span className="menu-item">
                                            <IconFont type={screenShare === ScreenShareState.STOP ? "icon-right" : ""} />
                                            <span className="description">{t('operation.screen.items.stop')}</span>
                                        </span>
                                    </Menu.Item>
                                </Menu>}
                            >
                                <div className="share-screen right" onClick={(e) => {e.stopPropagation();}}>
                                    <IconFont className="icon" type="icon-screen-share-3"/>
                                    <EnDisable2 className="control" enable={screenShare === ScreenShareState.START}/>
                                </div>
                            </Dropdown>
                        )
                    }
                }
                
                break;
            }
            default:
                break;
        }

        return <Drawer
            className="drawer-footer"
            placement={"bottom"}
            closable={false}
            visible={!!(meetStyle.opacity && barVisible)}
            mask={false}
            keyboard={false}
            height={64}
            zIndex={500}
            key={"drawer_bottom"}
            >
            <Row className="content" justify="space-around" align="middle">
                <Col span={8} className="icons left">
                    {leftIcons}
                </Col>
                <Col span={8} className="icons mid">
                    {midIcons}
                </Col>
                <Col span={8} className="icons right">
                    {rightIcons}
                </Col>
            </Row>
        </Drawer>
    }

    // 右边成员列表
    mkMeetRight(meetStyle) {
        let { height, width, memberVisible, layoutMode, detail, screen, screenSharerId } = this.state;
        let { t } = this.props;
        let joined = 0;
        let total = 0;
        detail?.participants?.forEach(m => {
            joined += (m?.status === "ONLINE" ? 1 : 0)
            if (!m?.rcExpandScreen) {
                total += 1;
            }
        });
        return <Drawer
            className="drawer-right"
            placement="right"
            closable={true}
            visible={!!(meetStyle.opacity && memberVisible)}
            onClose={(e) => {this.setState({memberVisible: false})}}
            width={Math.min(width / 3, 500)}
            zIndex={600}
            destroyOnClose={true}
            key={"drawer_right"}
            title={t('member_list.title')}
            footer={t('member_list.summary', { total: total, joined: joined })}
            >
                <MemberList
                    className="member-list"
                    This={this}
                    height={height}
                    conferenceInfo={detail}
                    conferenceLayout={layoutMode}
                    screen={screen}
                    screenSharerId={screenSharerId}
                    memberViewOnClick={(members) => {
                        Log.info(this.sipSessionInfo(), `ui event: member view onclick `)
                        this.viewSelect(members)
                    }}
                    onLoad={(cur, total) => {
                        this.setState({
                            memberCurNum: cur,
                            memberTotalNum: total,
                        })
                    }}
                />
        </Drawer>
    }

    mkWarn(checkCamera, checkMicrophone, checkLoudspeaker) {
        let { stream } = this.state;
        let warnComponent = undefined;
        if ((mediaDevice.cameraPerm !== mediaDevice.permission.allow && checkCamera) || 
            (mediaDevice.microphonePerm !== mediaDevice.permission.allow && checkMicrophone) || 
            (mediaDevice.loudspeakerPerm !== mediaDevice.permission.allow && checkLoudspeaker) ||
            (!stream.preview.status && checkCamera)) {
            
            let that = this;
            let { t } = this.props;
            let cameraHelp = undefined;
            let cameraPermIcon = <IconFont className="icon-right-wrong" type={!stream.preview.status || mediaDevice.cameraPerm !== mediaDevice.permission.allow ? "icon-wrong" : "icon-right"} />;
            let microphoneHelp = undefined;
            let microphonePermIcon = <IconFont className="icon-right-wrong" type={mediaDevice.microphonePerm !== mediaDevice.permission.allow ? "icon-wrong" : "icon-right"} />;
            let loudspeakerHelp = undefined;
            let loudspeakerPermIcon = <IconFont className="icon-right-wrong" type={mediaDevice.loudspeakerPerm !== mediaDevice.permission.allow ? "icon-wrong" : "icon-right"} />;
            let helpOnClick = (e) => {
                e.stopPropagation();
                that.setState({
                    permissionHelpVisible: true
                })
            }

            if (mediaDevice.cameraPerm !== 'none' && mediaDevice.cameraPerm !== mediaDevice.permission.allow) {
                cameraHelp = <Button className="help" icon={<ExclamationCircleOutlined /> } type="link" onClick={helpOnClick}>
                    {t('permission.warn.camera.help')}
                </Button>
            } else if (!stream.preview.status) {
                cameraHelp = <Button className="help" type="link" icon={<ExclamationCircleOutlined /> }>
                    {t('permission.warn.camera.nostreamHelp')}
                </Button>
            } 

            if (mediaDevice.microphonePerm !== 'none' && mediaDevice.microphonePerm !== mediaDevice.permission.allow) {
                microphoneHelp = <Button className="help" icon={<ExclamationCircleOutlined />} type="link" onClick={helpOnClick}>
                    {t('permission.warn.microphone.help')}
                </Button>
            }
            
            if (mediaDevice.loudspeakerPerm !== 'none' && mediaDevice.loudspeakerPerm !== mediaDevice.permission.allow) {
                loudspeakerHelp = <Button className="help" icon={<ExclamationCircleOutlined />} type="link" onClick={helpOnClick}>
                    {t('permission.warn.loudspeaker.help')}
                </Button>
            }

            warnComponent = <div className="force-warn-warpper">
                <div className="force-warn">
                    <Descriptions
                        className="title"
                        column={1}
                        title={<span className="warning">
                            <IconFont className="permission-icon" type="icon-permission-warning" />
                            <span className="title">{t('permission.warn.failed')}</span>
                        </span>} >
                        {
                            checkCamera ? <Descriptions.Item label={
                                <div>
                                    <IconFont className="icon" type="icon-camera-2"/>
                                    <span>{t('permission.warn.camera.title')}</span>
                                </div>
                            }>
                                <div>
                                    {cameraPermIcon}
                                    {
                                        !stream.preview.status ?
                                            <span className="bad">{t(`permission.warn.nostream`)}</span> :
                                            <span className={mediaDevice.cameraPerm}>{t(`permission.warn.${mediaDevice.cameraPerm}`)}</span>
                                    }
                                    {cameraHelp}
                                </div>
                            </Descriptions.Item> : undefined
                        }
                        {
                            checkMicrophone ? <Descriptions.Item label={
                                <div>
                                    <IconFont className="icon" type="icon-microphone-2"/>
                                    <span>{t('permission.warn.microphone.title')}</span>
                                </div>
                            }>
                                <div>
                                    {microphonePermIcon}
                                    <span className={mediaDevice.microphonePerm}>{t(`permission.warn.${mediaDevice.microphonePerm}`)}</span>
                                    {microphoneHelp}
                                </div>
                            </Descriptions.Item> : undefined
                        }
                        {
                            checkLoudspeaker ? <Descriptions.Item label={
                                <div>
                                    <IconFont className="icon" type="icon-loudspeaker-2"/>
                                    <span>{t('permission.warn.loudspeaker.title')}</span>
                                </div>
                            }>
                                <div>
                                    {loudspeakerPermIcon}
                                    <span className={mediaDevice.loudspeakerPerm}>{t(`permission.warn.${mediaDevice.loudspeakerPerm}`)}</span>
                                    {loudspeakerHelp}
                                </div>
                            </Descriptions.Item> : undefined
                        }
                    </Descriptions>
                    {/* <div className="buttons">
                        <Button type="primary" onClick={(e) => {
                            Log.info(this.sipSessionInfo(), `ui event: exit login onclick.`);
                            e.stopPropagation();
                            if (fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
                                this.meetHangupOnClick(e, number, 'normal');
                            }
                            this.sipUnregister();
                        }}>{t('operation.exit.title')}</Button>
                    </div> */}
                </div>
            </div>
        }

        return warnComponent;
    }

    componentWillUnmount() {
        this.exit();

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    componentDidMount() {
        adapter.checkVersion.bind(this)();

        let state = getUrlParam("state", window.location.search) || "";
        let mediaServers = [];
        let confCode = undefined;
        let host = undefined;
        let sps = state.split(',')
        if (sps.length > 2) {
            mediaServers = sps.slice(0, sps.length - 3)
            host = sps[sps.length - 2]
            confCode = sps[sps.length - 1]
        } else if (sps.length > 1) {
            host = sps[1]
            confCode = sps[0]
        } else if (sps.length > 0) {
            confCode = sps[0]
        }
        // url中携带会议号码，登录时直接进入会议
        if (!confCode || confCode === "null" || confCode === "undefined") {
            confCode = getUrlParam("conference", window.location.search);
        }
        if (!confCode || confCode === "null" || confCode === "undefined") {
            confCode = undefined;
        }
        this.tmpConfCode = confCode;
        this.setState({
            mediaServers: mediaServers,
        })

        // 初始化SIPml协议栈
        if (window.SIPml) {
            let logLevel = config.sipml.logLevel;
            window.SIPml.setDebugLevel(logLevel);
            window.SIPml.setLogFunction(Log.sipml5);
            window.SIPml.init(this.sipInit);

            Log.info(this.sipSessionInfo(), `set sipml log level: ${logLevel}`);
        }

        // 外接设备发生变化时触发
        mediaDevice.addListener((e) => this.deviceOnChange(e));

        // 通知权限发生变化时触发
        notification.addListener((e) => this.commonOnChange(e))

        // 用户全屏，拉伸窗口时触发
        adapter.addPageResizeListener.bind(this)();

        // 用户切换tab页签，或者最小化
        adapter.addPageHiddenListener.bind(this)();

        adapter.disableRightClick(true);

        // 用户关掉标签或者浏览器之前触发
        window.onbeforeunload = function (e) {
            let { fsm } = this.state;
            if (fsm.test(FsmState.Call.PROCESS, FsmState.Call.STABLE)) {
                this.exit(false, false);
            }
        }.bind(this);

        // 用户关掉标签或者浏览器之后触发
        window.onunload = function () {
            this.exit();
        }.bind(this)

        // 出现全局错误时触发，上传错误日志
        adapter.addPageErrorListener.bind(this)(mediaDevice);
    }

    render(){
        let { height, width, fsm, views, viewSelf, screen, stream, detail, backgroudViewType, backgroudViewIndex, barVisible, screenSharerId,
            layoutMode, localEnable, remoteEnable, boardEnable, remotesEnable, videoEnable, boardShare, loginStatus, profile,
            conferenceReportVisible, conferenceWizzardVisible, lastPageVisiable
        } = this.state;
        let page = "";
        let loginStyle = {opacity: 0, width: 0, height: 0};
        let meetStyle = {opacity: 0, width: 0, height: 0};
        switch(fsm.curState()) {
            case FsmState.Login.INIT:
            case FsmState.Login.PROCESS:
            case FsmState.Login.DONE:
                page = "login";
                loginStyle = {opacity: 1,  width: width || 0, height: height || 0}
                break;
            case FsmState.Login.OUT:
            case FsmState.Login.FAILED:
            case FsmState.Call.INIT:
            case FsmState.Call.PROCESS:
            case FsmState.Call.STABLE:
            case FsmState.Call.HANGUP:
                page = "meet";
                meetStyle = {opacity: 1, width: width || 0, height: height || 0}
                break;
            default:
                return undefined;
        }

        return <Layout className={page}>
            <div style={{opacity: 0, position: "absolute", zIndex: '-999'}}>
                <input id="dom_copy" v-model="dom_copy" style={{ opacity: 0}} type="text" />
            </div>
            <Layout.Content className="background">
                {/* 入会向导对话框 */}
                {this.mkConferenceWizzard()}
                {this.mkConferenceCreate()}
                {this.mkConferenceJoin()}
                {this.mkConferenceReport()}
                {/* 其他对话框 */}
                {this.mkConferenceSetting()}
                {this.mkConferenceHangup()}
                {this.mkConferenceDetail()}
                {this.mkRtcInfo()}
                {this.mkPermissionHelp()}
                {/* 菜单栏 */}
                {this.mkMeetHeader(meetStyle)}
                {this.mkMeetFooter(meetStyle)}
                {this.mkMeetRight(meetStyle)}
                {/* 内容 */}
                <Login
                    This={this}
                    ref={(element) => {this.loginDOM = element;}}
                    style={loginStyle}
                    fsm={fsm}
                    profile={profile}
                    status={loginStatus}
                    onLogin={this.wechatOnLogin}
                />
                <VideoLayout
                    This={this}
                    viewSelf={viewSelf}
                    ref={(element) => {this.videoLayoutDOM = element;}}
                    style={meetStyle}
                    fsm={fsm}
                    views={views}
                    stream={stream}
                    callType={this.callType}
                    detail={detail}
                    screen={screen}
                    screenSharerId={screenSharerId}
                    reportMod={conferenceReportVisible || (!conferenceReportVisible && lastPageVisiable === 'report')}
                    wizzardMod={conferenceWizzardVisible || (!conferenceWizzardVisible && lastPageVisiable === 'wizzard')}
                    backgroudViewType={backgroudViewType}
                    backgroudViewIndex={backgroudViewIndex}
                    layoutMode={layoutMode}
                    boardShare={boardShare}
                    localEnable={localEnable}
                    remoteEnable={remoteEnable}
                    boardEnable={boardEnable}
                    remotesEnable={remotesEnable}
                    videoEnable={videoEnable}
                    zoomInOnClick={this.zoomInOnClick}
                    zoomOutOnClick={this.zoomOutOnClick}
                    videoDivOnMouseMove={this.videoDivOnMouseMove}
                    videoSwitchOnClick={this.videoSwitchOnClick}
                    barVisible={barVisible}
                    barVisibleOnClick={this.barVisibleOnClick}
                    localCloseOnClick={(e) => {
                        e.stopPropagation();
                        this.setState({localEnable: false});
                    }}
                    remoteCloseOnClick={(e) => {
                        e.stopPropagation();
                        this.setState({remoteEnable: false});
                    }}
                    boardCloseOnClick={(e) => {
                        e.stopPropagation();
                        this.setState({boardEnable: false});
                    }}
                    boardStopOnClick={(e) => {
                        e.stopPropagation();
                        e.key = BoardShareState.STOP
                        this.menuItemBoardShareOnChange(e)
                    }}
                    remotesCloseOnClick={(e) => {
                        e.stopPropagation();
                        this.setState({remotesEnable: false});
                    }}
                />
            </Layout.Content>
        </Layout>
    }
}

let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Main);


