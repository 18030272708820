import { adapter } from "../adapter";
import { Log } from "../../util/log";

class Device {

    permission = {
        forbidden: 'forbidden',
        allow: 'allow',
        none: 'none'
    }

    constructor() {
        this.__onChangeFuns = [];
        this.__cameras = [];
        this.__microphones = [];
        this.__loudspeakers = [];

        this.__permission = {
            microphone: this.permission.none,
            camera: this.permission.none,
            loudspeaker: this.permission.none,
        }

        this.init();
        this.getPermission();
    }

    get cameras() {
        return this.__cameras;
    }

    get microphones() {
        return this.__microphones;
    }

    get loudspeakers() {
        return this.__loudspeakers;
    }

    get cameraPerm() {
        return this.__permission.camera
    }

    get microphonePerm() {
        return this.__permission.microphone
    }

    get loudspeakerPerm() {
        return this.__permission.loudspeaker
    }

    addListener(fun) {
        fun.id = Math.random()
        this.__onChangeFuns.push(fun);
    }

    removeListener(fun) {
        let index = this.__onChangeFuns.findIndex((item) => item.id === fun.id);
        if (index !== -1) {
            this.__onChangeFuns.splice(index, 1);
        }
    }

    // 获取设备权限
    getPermission() {
        let that = this;
        // 首先查看是否获取了设备权限
        adapter.deviceEnumerate((devices) => {
            let microphone = {
                forbiddens: [],
                allows: [],
            }
            let camera = {
                forbiddens: [],
                allows: [],
            }
            let loudspeaker = {
                forbiddens: [],
                allows: [],
            }
            devices.forEach((device) => {
                // 设备id为空代表没有获取到权限，需要重新获取权限
                // console.log(device)
                if (device.kind === "audioinput") {
                    if (device.deviceId === "") {
                        microphone.forbiddens.push(device.toJSON());
                    } else {
                        microphone.allows.push(device.toJSON());
                    }
                } else if (device.kind === "videoinput") {
                    if (device.deviceId === "") {
                        camera.forbiddens.push(device.toJSON());
                    } else {
                        camera.allows.push(device.toJSON());
                    }
                } else if (device.kind === "audiooutput") {
                    if (device.deviceId === "") {
                        loudspeaker.forbiddens.push(device.toJSON());
                    } else {
                        loudspeaker.allows.push(device.toJSON());
                    }
                }
                
            })

            // console.log("microphone", microphone);
            // console.log("camera", camera);
            // console.log("loudspeaker", loudspeaker);

            // 麦克风
            if (microphone.allows.length > 0) {
                that.__permission.microphone = this.permission.allow;
            } else if (microphone.forbiddens.length > 0){
                // 重新获取权限
                adapter.getUserMedia({
                    audio: true, 
                }, (stream) => {
                    Log.info(`get microphone permission success. stream: ${stream.id}`);
                    stream.getTracks().forEach((track) => track.stop())
                    that.__permission.microphone = this.permission.allow;
                    that.init();
                }, (err) => {
                    Log.error(`get microphone permission error. detail: ${err.toString()}`)
                    that.__permission.microphone = this.permission.forbidden;
                });
            } else {
                that.__permission.microphone = this.permission.none;
            }

            // 摄像头
            if (camera.allows.length > 0) {
                that.__permission.camera = this.permission.allow;
            } else if (camera.forbiddens.length > 0) {
                // 重新获取权限
                adapter.getUserMedia({
                    video: true, 
                }, (stream) => {
                    Log.info(`get camera permission success. stream: ${stream.id}`);
                    stream.getTracks().forEach((track) => track.stop())
                    that.__permission.camera = this.permission.allow;
                    that.init();
                }, (err) => {
                    Log.error(`get camera permission error. detail: ${err.toString()}`)
                    that.__permission.camera = this.permission.forbidden;
                });
            } else {
                that.__permission.camera = this.permission.none;
            }

            // 音频输出设备没有权限
            if (loudspeaker.allows.length > 0) {
                that.__permission.loudspeaker = this.permission.allow;
            } else if (loudspeaker.forbiddens.length > 0) {
                // 重新获取权限
                // TODO: 扬声器权限怎么获取？
                that.__permission.loudspeaker = this.permission.forbidden;
            } else {
                that.__permission.loudspeaker = this.permission.none;
            }

            that.init();

        }, (err) => {
            Log.error("get media device error. " + err.toString())
        })
    }

    init(event = undefined) {
        let that = this;

        adapter.deviceEnumerate((devices) => {
            let cameras = [];
            let microphones = [];
            let loudspeakers = [];
            devices.forEach((device) => {
                // console.log(device)
                if (device.kind === 'videoinput') {
                    cameras.push(device.toJSON())
                } else if (device.kind === 'audioinput') {
                    microphones.push(device.toJSON())
                } else if (device.kind === 'audiooutput') {
                    loudspeakers.push(device.toJSON())
                    if (device.deviceId !== "") {
                        that.__permission.loudspeaker = this.permission.allow;
                    }
                }
            })
            that.__cameras = cameras;
            that.__microphones = microphones;
            that.__loudspeakers = loudspeakers;
            that.__onChangeFuns.forEach((fun) => {
                try {
                    fun(event);
                } catch (error) {
                    
                }
            })
        }, (error) => {
            Log.error("enumerate device error: ", error);
        });
    
        adapter.deviceOnChange((event) => this.getPermission(event));
    }
}

let device = new Device();
export { device };
