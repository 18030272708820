import React, { Component } from 'react';
import TweenOneIcon from './tweenOneIcon';

class EnDisable extends Component {

    render() {
        return <TweenOneIcon 
            from={{
                d: "M514.7123659-1.49343868c-281.71618577 0-510.04807901 228.33189324-510.04807901 510.04807902s228.33189324 510.04807901 510.04807901 510.048079S1024.63715556 790.14753675 1024.63715556 508.43135098 796.18197296-1.49343868 514.7123659-1.49343868zM758.57871839 406.34776155L562.54863728 599.04902998l-94.9328066 92.83688748c-0.36986808 0.49315743-0.86302552 0.98631487-1.4794723 1.60276167-0.73973616 0.73973616-1.60276167 1.4794723-2.46578719 2.21920846-5.91788924 5.67131053-13.68511887 8.7535445-21.94550594 8.75354451h-1.35618294c-8.63025515 0-16.64406349-3.20552333-22.56195274-9.12341257-0.86302552-0.73973616-1.60276167-1.4794723-2.34249782-2.09591912-0.49315743-0.49315743-0.86302552-0.86302552-1.2328936-1.35618295L255.92800093 533.58238025c-12.82209336-12.82209336-11.95906785-34.52102058 1.8493404-48.3294288 7.02749348-7.02749348 16.39748478-10.97275297 25.89076543-10.97275298 8.63025515 0 16.64406349 3.20552333 22.56195274 9.12341259l134.87855896 134.87855897 71.6311177-70.02835604 196.15337048-192.70126843c5.91788924-5.79459987 13.80840823-8.87683386 22.31537402-8.87683386 9.61657002 0 19.10985067 4.06854886 26.13734415 11.21933169 13.56182951 14.05498695 14.30156566 35.75391416 1.23289358 48.45271816z",
                style: {fill: '#73d13d'},
            }}
            to={{
                d: "M512.34677246 3.85176794c279.82359671 0 506.66543844 226.84184174 506.66543845 506.66543844S792.17036918 1017.18264481 512.34677246 1017.18264481 5.68133403 790.34080308 5.68133403 510.51720638 232.52317576 3.85176794 512.34677246 3.85176794z m248.04226867 474.01959829H264.23747856l-0.80203095 0.00908818c-18.38082241 0.42600794-33.14909752 15.45897598-33.14909751 33.94204029 0 18.75116532 15.19996315 33.95226449 33.95112846 33.95226448h496.15156257l0.80203094-0.00908817c18.38082241-0.42600794 33.14909752-15.45897598 33.14909751-33.94317631 0-18.75002929-15.19996315-33.95112846-33.95112845-33.95112847z",
                style: {fill: '#ff4d4f'},
            }}
            enable={this.props.enable}
            className={this.props.className}
        />
    }
}

export default EnDisable;