import { netRequestThunk3 } from '../util/netReqRD';

export function apiClientDownload(props, type, successFun = undefined, errorFun = undefined) {
    const { dispatch, login, sessionInfo } = props;
    const path = `/cs/v1/ldq/general/client/version`;
    const header = {
        DeviceToken: login?.token,
        RefreshToken: login?.refreshToken,
    }
    dispatch(netRequestThunk3(path, { ClientType: type }, header, sessionInfo, "POST", successFun, errorFun));
}


