/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Typography, Row, Col, Tooltip, Result, Form, Button, Input, Drawer, Checkbox, Tabs, Space } from "antd";
import { LoadingOutlined, LockOutlined, WindowsFilled, SettingOutlined, UserOutlined, AppleFilled } from '@ant-design/icons';
import { getLoginData, getUserData, saveLoginData, saveUserData, } from "../../redux/reducers/login"
import { apiAccountToken, apiToken, apiRefreshToken, apiProfile, } from "../../api/login";
import { apiClientDownload, } from "../../api/common";
import { Log } from "../../util/log";
import { message } from "../../util/message";
import { getUrlParam, printObject, compile, uncompile } from '../../util/logic';
import { adapter, getExplorerInfo } from "../adapter";
import { version, config, updateHostEnv } from '../../util/version';
import { apiMediaServerDetect, apiMediaServerList } from '../../api/conference';
import { MediaHistory } from '../localStorage/mediaHistory';

import "./login.less";

@withTranslation('translation', {withRef: true})
class Login extends Component {
    constructor(props){
        super(props);

        this.state = {
            mediaHistory: new MediaHistory(),
            loginType: version.isInternal() ? 'account' : 'qr',
        }
    }

    refreshLogin() {
        Log.info(this.props.This.sipSessionInfo(), `it's time to refresh login: ${printObject(this.props.login)}`);
        apiRefreshToken(Object.assign({sessionInfo: this.props.This.sipSessionInfo}, this.props), (dispatch, rsp, req) => {
            dispatch(saveLoginData(rsp));
        }, (dispatch, rsp, req) => {
            Log.warn(`refresh login error. details: ${printObject(rsp)}`)
        })
    }

    unlogin() {
        Log.info(this.props.This.sipSessionInfo(), `unlogin and stop timer(${this.refreshTimer})`);
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer)
            this.refreshTimer = null;
        }
    }

    login(wechatCode) {
        let that = this;
        let { t, onLogin } = this.props;
        Log.info(this.props.This.sipSessionInfo(), `start to login. wechatCode: ${wechatCode}`);

        apiToken(Object.assign({ sessionInfo: this.props.This.sipSessionInfo }, this.props), wechatCode, version.versionNo(),
            (dispatch, rsp, req) => {
                dispatch(saveLoginData(rsp));
                this.refreshTimer = setInterval(() => {
                    that.refreshLogin();
                }, config.refreshTokenInterval * 1000)
                Log.debug(this.props.This.sipSessionInfo(), `get token: ${rsp.token}(${rsp.expire}) success. start timer(${this.refreshTimer} per ${config.refreshTokenInterval}s) to refresh token`)
                apiProfile(Object.assign({sessionInfo: this.props.This.sipSessionInfo}, this.props), rsp, (dispatch, rsp2, req) => {
                    Log.debug(this.props.This.sipSessionInfo(), `get profile success.`)
                    dispatch(saveUserData(rsp2));
                    onLogin && onLogin(rsp, rsp2, true);
                }, (dispatch, rsp2, req) => {
                    Log.error(this.props.This.sipSessionInfo(), `get profile error. details: ${printObject(rsp2)}`);
                    message.error(t('login.message.api.profile.error'))
                    onLogin && onLogin(null, null, false);
                })
            },
            (dispatch, rsp, req) => {
                Log.error(this.props.This.sipSessionInfo(), `get token error. details: ${printObject(rsp)}`);
                message.error(t('login.message.api.token.error'))
                that.getTokenTimer = setTimeout(() => {
                    window.location.replace("http://" + window.location.host)
                }, 1000)
            })
    }

    detectMediaServers() {
        let that = this;
        let { mediaHistory } = this.state;
        let tmpServers = [];
        
        function distinct(arr, key) {
            let result = []
            let obj = {}
            
            if (arr) {
                for (let i of arr) {
                    if (!obj[i[key]]) {
                        result.push(i)
                        obj[i[key]] = 1
                    }
                }
            }
        
            return result
        }
        
        function detectLoop(servers, successHd = undefined, failedHd = undefined) {
            for (let server of servers.slice(0, 100)) {
                apiMediaServerDetect(Object.assign({sessionInfo: that.props.This.sipSessionInfo}, that.props), server, (dispatch, rsp3, req) => {
                    successHd && successHd(req, rsp3.Identity);
                }, (dispatch, rsp, req) => {
                    failedHd && failedHd();
                    return true;
                })
            }
            if (servers.length > 100) {
                that.detectTimer = setTimeout(() => {
                    detectLoop(servers.slice(100), successHd, failedHd)
                }, 3000)
            }
        }

        function detectSuccess(server, Identity) {
            Log.info(that.props.This.sipSessionInfo(), `detect success. details: ${JSON.stringify(server)}`)
            tmpServers.push({
                ...server,
                Identity,
            })
            tmpServers = distinct(tmpServers, "ServerHttpAddr")
            tmpServers = distinct(tmpServers, "Identity")
            that.setState({
                detectServers: tmpServers
            })
            mediaHistory.set(tmpServers);
        }

        function reDetect() {
            apiMediaServerList(Object.assign({sessionInfo: that.props.This.sipSessionInfo}, that.props), (dispatch, rsp2, req) => {
                detectLoop(distinct(rsp2.DetectList, "ServerHttpAddr"), detectSuccess);
            }, (dispatch, rsp, req) => {
                if (!window.location.protocol.match("https:")) {
                    Log.error("can't get media server list");
                    that.redetectTimer = setTimeout(() => reDetect(), 5000);
                }
                return true;
            });
        }

        let serverHistory = mediaHistory.get();
        if (serverHistory instanceof Array) {
            Log.debug("find the local config of media server: " + JSON.stringify(serverHistory))
            detectLoop(distinct(serverHistory, "ServerHttpAddr"), detectSuccess, reDetect);
        } else {
            Log.debug("can't find the local config of media server, redetect it")
        }
        reDetect()
    }

    submitOnClick() {
        let { username, password } = this.state;
        let { t, onLogin } = this.props;
        this.setState({
            loading: true
        }, () => {
            apiAccountToken(Object.assign({sessionInfo: this.props.This.sipSessionInfo}, this.props), username, password,
                (dispatch, rsp, req) => {
                    dispatch(saveLoginData(rsp));
                    this.refreshTimer = setInterval(() => {
                        this.refreshLogin();
                    }, config.refreshTokenInterval * 1000)
                    Log.debug(this.props.This.sipSessionInfo(), `get token: ${rsp.token}(${rsp.expire}) success. start timer(${this.refreshTimer} per ${config.refreshTokenInterval}s) to refresh token`)
                    apiProfile(Object.assign({sessionInfo: this.props.This.sipSessionInfo}, this.props), rsp, (dispatch, rsp2, req) => {
                        Log.debug(this.props.This.sipSessionInfo(), `get profile success.`)
                        dispatch(saveUserData(rsp2));
                        this.setState({
                            loading: false
                        })
                        onLogin && onLogin(rsp, rsp2, true);
                    }, (dispatch, rsp2, req) => {
                        Log.error(this.props.This.sipSessionInfo(), `get profile error. details: ${printObject(rsp2)}`);
                        message.error(t('login.message.api.profile.error'))
                        this.setState({
                            loading: false
                        })
                        onLogin && onLogin(null, null, false);
                    })
                },
                (_, rsp, req) => {
                    this.setState({
                        loading: false
                    })
                    switch (rsp.status) {
                        case 403:
                            message.error(t('login.message.api.username.forbidden'))
                            break;
                        
                        case 404:
                            message.error(t('login.message.api.username.error'))
                            break;
                    
                        default:
                            message.error(t('login.message.api.other.error'))
                            break;
                    }
                }
            )
        })
    }

    loginOnClick() {
        let { state, confCode, username, password, rememberMe, host } = this.state;
        if (rememberMe) {
            if (window.localStorage && username && password) {
                Log.info("save the account");
                window.localStorage.setItem("username", compile(username));
                window.localStorage.setItem("password", compile(password));
                window.localStorage.setItem("rememberMe", rememberMe);
            }
        } else {
            if (window.localStorage) {
                Log.info("clear the account");
                window.localStorage.removeItem("username");
                window.localStorage.removeItem("password");
                window.localStorage.removeItem("rememberMe");
            }
        }
        if (version.isInternal()) {
            this.submitOnClick()
        } else {
            let potocol = version.isDevelopment() ? "http://" : "https://";
            let stHost = window.localStorage?.getItem("vmeeting::csIp");
            if (stHost && stHost !== "null" && stHost !== "undefined") {
                host = stHost
            }
            if (!host) {
                switch (version.environment) {
                    case "production":
                        host = "ludiqiao.com";
                        break;
                    case "show":
                        host = "show.ludiqiao.com";
                        break;
                    case "development":
                    default:
                        host = "test.ludiqiao.com";
                        break;
                }
            }
            window.location.replace(potocol + window.location.host + `?state=${state || ""}&conference=${confCode || ""}&host=${host || ""}&u=${(username && encodeURIComponent(compile(username))) || ""}&p=${(password && encodeURIComponent(compile(password))) || ""}`)
        }
    }

    mkLoginIntroduction(opacity = 0) {
        let { version, type } = this.state;
        let { t } = this.props;
        return opacity ? <div className="introduction">
            <Typography.Title level={2}>
                {t('login.introduction.summary')}
            </Typography.Title>
            <Typography.Paragraph>
                <ul>
                    <li><Typography.Text>{t('login.introduction.feature1')}</Typography.Text></li>
                    <li><Typography.Text>{t('login.introduction.webrtc')}({type} {version})</Typography.Text></li>
                </ul>
            </Typography.Paragraph>
            <Typography.Text>
                {t('login.introduction.detail')}
            </Typography.Text>
        </div> : undefined;
    }

    mkQRcode(show = false) {
        let { detectServers, confCode, host } = this.state;

        let src = "";
        if (show) {
            let appid = "wxa30171b59c5a8112";
            let scope = "snsapi_login";
            if (!host) {
                switch (version.environment) {
                    case "production":
                        host = "ludiqiao.com";
                        break;
                    case "show":
                        host = "show.ludiqiao.com";
                        break;
                    default:
                        host = "test.ludiqiao.com";
                        break;
                }
                let stHost = window.localStorage?.getItem("vmeeting::csIp");
                if (stHost && stHost !== "null" && stHost !== "undefined") {
                    host = stHost
                }
            }
            let href = `https://vmeeting.ludiqiao.com/wechatqr.css`
            let states = []
            for (let m of (detectServers || [])) {
                states.push(m.Identity)
            }
            states.push(confCode || "")
            states.push(host)
            src = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&scope=${scope}&response_type=code&redirect_uri=${encodeURI(config.host.redirect)}&state=${states.join(",")}&login_type=jssdk&self_redirect=default&href=${encodeURI(href)}`
        }
        return <iframe
            className={"code " + (show ? "show" : "hidden")}
            id="code"
            title="login"
            src={src}
            frameBorder={0}
            allowtransparency="true"
            scrolling="no"
            width={300}
            height={300}
            sandbox={"allow-scripts allow-top-navigation allow-same-origin"}
        />
    }

    mkLoginDialog(show = false) {
        let { username, password, rememberMe } = this.state;
        let { t } = this.props;

        let usernameComponent = (
            <Form.Item className="username">
                <div className="title">{t('login.username.title')}</div>
                <Input 
                    ref={(input) => this.loginUserNameDOM = input} 
                    value={username} 
                    size="large" 
                    placeholder={t('login.username.placeholder')}
                    prefix={<UserOutlined />} 
                    onChange={(e) => {
                        this.setState({
                            username: e.target.value,
                        });
                    }} 
                    onPressEnter={(e) => this.loginPasswordDOM.focus()}
                />
            </Form.Item>
        )

        let passwordComponent = (
            <Form.Item className="password">
                <div className="title">{t('login.password.title')}</div>
                <Input.Password 
                    ref={(input) => this.loginPasswordDOM = input} 
                    autoComplete="on" 
                    value={password} 
                    size="large" 
                    placeholder={t('login.password.placeholder')}
                    prefix={<LockOutlined />} 
                    onChange={(e) => {
                        this.setState({
                            password: e.target.value,
                        });
                    }} 
                    onPressEnter={() => this.loginOnClick()}
                />
            </Form.Item>
        )

        let saveAccountComponent = (
            <Form.Item className="remember">
                <Checkbox checked={rememberMe} onChange={(e) => {
                    Log.info("event ui: remember me on click", e.target.checked)
                    this.setState({
                        rememberMe: e.target.checked
                    })
                }}>{t('login.remember_me.title')}</Checkbox>
            </Form.Item>
        )

        let buttonComponent = (
            <Form.Item className="operations">
                <Button type="primary" size="large" block loading={this.state.loading} onClick={() => this.loginOnClick()}>{t('login.card.btn')}</Button>
            </Form.Item>
        )

        return <div className="dialog">
            <Form className="content">
                {usernameComponent}
                {passwordComponent}
                {saveAccountComponent}
                {buttonComponent}
            </Form>
        </div>
    }

    mkLoginInput(show = false) {
        let { wechatCode, loginType, settingVisible } = this.state;
        let { t, login, profile } = this.props;
        // 92.0.4515.159
        let chromeMajorVersion = 100;
        if (this.state.type === "Chrome") {
            chromeMajorVersion = parseInt((this.state.version || '').split('.')[0] || '0');
        }
        let downloadComponents = [];
        if (navigator.platform.startsWith("Mac")) {
            downloadComponents.push(<Button key="mac" type="primary" icon={<AppleFilled />} onClick={() => {
                apiClientDownload(this.props, "vmeeting-mac", (_, rsp) => {
                    window.location.href = rsp?.downloadUrl
                })
            }}>
                {t("login.result.btn.mac.x64.download")}
            </Button>)
        } else {
            downloadComponents.push(<Button key="windows" type="primary" icon={<WindowsFilled />} onClick={() => {
                apiClientDownload(this.props, "vmeeting-win64", (_, rsp) => {
                    window.location.href = rsp?.downloadUrl
                })
            }}>
                {t("login.result.btn.windows.x64.download")}
            </Button>)
        }

        return <div className="control">
            {
                chromeMajorVersion >= 96 ? <Result
                    title={t("login.result.title")}
                    subTitle={t("login.result.sub_title")}
                    status="error"
                    extra={<Space>{downloadComponents}</Space>}
                /> : <>
                    <div className={"login " + (show ? "show" : "hidden")}>
                        <Tabs
                            className="tabs"
                            centered={true}
                            activeKey={loginType}
                            onChange={(activeKey) => this.setState({ loginType: activeKey })}
                            tabBarExtraContent={
                                <SettingOutlined className="setting-icon" onClick={() => {
                                    this.setState({
                                        settingVisible: !settingVisible,
                                    })
                                }}/>
                            }
                        >
                            {
                                !version.isInternal() ? <Tabs.TabPane key='qr' tab={t("login.tabs.wechat")} >
                                    {this.mkQRcode(show)}
                                </Tabs.TabPane> : undefined
                            }
                            <Tabs.TabPane key='account' tab={t("login.tabs.account")}>
                                {this.mkLoginDialog(show)}
                            </Tabs.TabPane>
                        </Tabs>
                        <Drawer
                            className="setting"
                            placement="top"
                            closable={false}
                            destroyOnClose={true}
                            width="100%"
                            height="100%"
                            onClose={() => {
                                this.setState({
                                    settingVisible: false,
                                })
                            }}
                            afterVisibleChange={(visible) => {
                                let csIp, csPort;
                                if (version.isInternal()) {
                                    csIp = window.location.hostname;
                                    csPort = "6443";
                                } else {
                                    switch (version.environment) {
                                        case "production":
                                            csIp = "ludiqiao.com";
                                            csPort = "443";
                                            break;
                                        case "show":
                                            csIp = "show.ludiqiao.com";
                                            csPort = "443";
                                            break;
                                        default:
                                            csIp = "test.ludiqiao.com";
                                            csPort = "443";
                                            break;
                                    }
                                }
                                
                                if (window.localStorage) {
                                    csIp = window.localStorage.getItem("vmeeting::csIp") || csIp;
                                    csPort = window.localStorage.getItem("vmeeting::csPort") || csPort;
                                }
                                this.setState({
                                    csIp,
                                    csPort,
                                })
                            }}
                            visible={settingVisible}
                            getContainer={false}
                            style={{ position: 'absolute' }}
                            footer={[
                                <Button key="cancel" type="primary" danger onClick={() => {
                                    this.setState({
                                        settingVisible: false,
                                    })
                                }}>
                                    {t('login.card.setting.btn.cancel')}
                                </Button>,
                                <Button key="save" type="primary" onClick={() => {
                                    updateHostEnv({
                                        ip: this.state.csIp,
                                        port: this.state.csPort
                                    })
                                    this.setState({
                                        settingVisible: false,
                                    })
                                }}>
                                    {t('login.card.setting.btn.save')}
                                </Button>,
                            ]}
                            >
                            <Form layout="vertical">
                                <Form.Item label={t('login.card.setting.form.cs.label')}>
                                    <Input.Group compact>
                                        <Input
                                            style={{ width: '70%' }}
                                            placeholder={t('login.card.setting.form.cs.placeholder.ip')}
                                            value={this.state.csIp}
                                            onChange={(e) => {
                                                this.setState({
                                                    csIp: e.target.value
                                                })
                                            }}
                                        />
                                        <Input
                                            style={{ width: '30%' }}
                                            placeholder={t('login.card.setting.form.cs.placeholder.port')}
                                            value={this.state.csPort}
                                            onChange={(e) => {
                                                this.setState({
                                                    csPort: e.target.value
                                                })
                                            }}
                                        />
                                    </Input.Group>
                                </Form.Item>
                            </Form>
                        </Drawer>
                    </div>
                    <div className={"profile " + (!show ? "show" : "hidden")}>
                        <div className="title">
                            <img className="avatar" src={wechatCode === "test" ? "./images/test-avatar.png" : login?.avatarUrl} alt=""/>
                            <div className="name">{profile?.name}</div>
                        </div>
                        <div className="status"><LoadingOutlined className="icon"/><span className="description">{t("login.message.status.doing")}</span></div>
                    </div>
                </>
            }
        </div>
    }

    componentDidMount() {
        let wechatCode = getUrlParam("code", window.location.search);
        let state = getUrlParam("state", window.location.search) || "";
        let confCode = getUrlParam("conference", window.location.search) || "";
        let host = getUrlParam("host", window.location.search) || ""
        let u = getUrlParam("u", window.location.search) || "";
        let p = getUrlParam("p", window.location.search) || "";
        
        let mediaServers = false;
        let sps = state.split(',')
        if (sps.length > 2) {
            mediaServers = true;
            host = sps[sps.length - 2]
            confCode = sps[sps.length - 1]
        } else if (sps.length > 1) {
            confCode = sps[0]
            host = sps[1]
        } else if (sps.length > 0) {
            confCode = sps[0]
        }

        console.group()
        console.log("host:", host);
        console.log("confCode:", confCode);
        console.groupEnd()

        if (host) {
            updateHostEnv({ ip: host, port: 443 })
        }

        if (wechatCode) {
            this.login(wechatCode);
        } else if (u && p) {
            this.setState({
                loginType: 'account',
                username: uncompile(u),
                password: uncompile(p),
                host: host,
                confCode: confCode,
                state: state,
            }, () => {
                this.submitOnClick();
            })
        } else {
            if (!version.isInternal()) {
                if (window.location.protocol.match("https:")) {
                    window.location.replace("http://" + window.location.host + `?state=${state}&conference=${confCode}`)
                }
            } else {
                // if (window.location.protocol.match("http:")) {
                //     window.location.replace("https://" + window.location.host)
                // }
            }
        }

        if (!u || !p) {
            this.setState({
                username: uncompile(window.localStorage?.getItem("username")),
                password: uncompile(window.localStorage?.getItem("password")),
            })
        }

        if (!mediaServers) {
            if (!version.isInternal()) {
                this.detectMediaServers();
            }
            this.setState({
                ...adapter.explorerInfo,
                wechatCode: wechatCode,
                confCode: confCode,
                rememberMe: window.localStorage?.getItem("rememberMe") !== "false",
            });
        } else {
            this.setState({
                detectServers: undefined,
                ...adapter.explorerInfo,
                wechatCode: wechatCode,
                confCode: confCode,
                rememberMe: window.localStorage?.getItem("rememberMe") !== "false",
            })
        }
    } 

    render() {
        let { t, style, status } = this.props;
        let headerComponent = <div className="header">
            <Tooltip>
                <div className="version" onClick={() => this.setState({
                    debug: !this.state.debug
                })}>
                    <span>{t('version')}</span>
                    <span>{version.software}</span>
                </div>
            </Tooltip>
        </div>
        let bodyComponent = undefined;
        let height = Math.max(style.height - 64, 0);
        if (style.width < 1280) {
            bodyComponent = <Row justify="space-between" align="middle" style={{height: height}}>
                <Col span={1}></Col>
                <Col span={11}>{this.mkLoginIntroduction(style.opacity)}</Col>
                <Col span={11} style={{maxWidth: 360}}>{this.mkLoginInput(!!(style.opacity && status !== "success"))}</Col>
                <Col span={1}></Col>
            </Row>
        } else {
            bodyComponent = <Row justify="space-between" align="middle" style={{height: height}}>
                <Col span={5}></Col>
                <Col span={6}>{this.mkLoginIntroduction(style.opacity)}</Col>
                <Col span={2}></Col>
                <Col span={6} style={{maxWidth: 360}}>{this.mkLoginInput(!!(style.opacity && status !== "success"))}</Col>
                <Col span={5}></Col>
            </Row>
        }

        let footComponent = <div className="footer">
            <div className="copyright">{t('login.footer')}</div>
        </div>

        return <div className="login-content" style={style}>
            {headerComponent}
            {bodyComponent}
            {footComponent}
        </div>
    }
}


let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Login);