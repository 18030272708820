
import { sendRequest, sendRequest2 } from './net';
import { message } from "../util/message";
import { Log } from './log';
import { printObject } from './logic';
import { version, config } from "./version";

export const ACT_NET_REQUEST_PENDING = 'ACT_NET_REQUEST_PENDING';
export const ACT_NET_REQUEST_SUCCESS = 'ACT_NET_REQUEST_SUCCESS';
export const ACT_NET_REQUEST_FAILED = 'ACT_NET_REQUEST_FAILED';
export const ACT_NET_REQUEST_RESET = 'ACT_NET_REQUEST_RESET';
export const ACT_NET_REQUEST_SET_MSG = 'ACT_NET_REQUEST_SET_MSG';
export const ACT_NET_REQUEST_CLEAR = 'ACT_NET_REQUEST_CLEAR';

const netRequestState = {};

export const netRequest_reducer = (state=netRequestState, action) => {
    let nextState = Object.assign({}, state);
    let item = nextState[action.routeCode]
    switch(action.type){
        case ACT_NET_REQUEST_PENDING: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 1, routeCode: action.routeCode, msg: undefined};
            } else {
                item.status = 1;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_SUCCESS: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 2, routeCode: action.routeCode, msg: action.msg};
            } else {
                item.status = 2;
                item.msg = action.msg;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_FAILED: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 0, routeCode: action.routeCode, msg: undefined};
            } else {
                item.status = 0;
                item.msg = undefined;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_RESET: {
            if (item !== undefined){
                item.status = 0;
                item.msg = undefined;
            }
            
            return nextState;
        }

        case ACT_NET_REQUEST_SET_MSG: {
            if (item === undefined){
                nextState[action.routeCode] = {status: 2, routeCode: action.routeCode, msg: action.msg};
            } else {
                item.msg = action.msg;
            }
            return nextState;
        }

        case ACT_NET_REQUEST_CLEAR: {
            if (item !== undefined){
                delete nextState[action.routeCode];
            }
            return nextState;
        }

        default:
            return state;
    }
}



// select handle
export const getNetRequestStatus = (state, routeCode) => {
    let element = state.net_request.find((item) => item.routeCode === routeCode)
    return element ? element.status : 0;
}

export const getNetRequestMsg = (state, routeCode) => {
    let element = state.net_request.find((item) => item.routeCode === routeCode)
    return element ? element.msg : undefined;
}

// actions define
// ACT_NET_REQUEST_PENDING
export const actNetRequestPending = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_PENDING, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_SUCCESS
export const actNetRequestSuccess = (routeCode, msg=undefined) => {
    return {
        type: ACT_NET_REQUEST_SUCCESS, 
        routeCode: routeCode, 
        msg: msg
    }
};

// ACT_NET_REQUEST_FAILED
export const actNetRequestFailed = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_FAILED, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_RESET
export const actNetRequestReset = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_RESET, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_CLEAR
export const actNetRequestClear = (routeCode) => {
    return {
        type: ACT_NET_REQUEST_CLEAR, 
        routeCode: routeCode
    }
};

// ACT_NET_REQUEST_SET_MSG
export const actNetRequestSetMsg = (routeCode, msg) => {
    return {
        type: ACT_NET_REQUEST_SET_MSG, 
        routeCode: routeCode, 
        msg: msg
    }
};

export const netRequestThunk = (path, msgBody, header, routeCode, successHd=undefined, errorHd=undefined) => async (dispatch, getState) => {
    dispatch(actNetRequestPending(routeCode));
    // var temple=await sleep(3000);
    const body = await sendRequest(path, msgBody, header);
    if (body !== undefined){
        // console.log(body, Object.prototype.toString.call(body));
        if (body.status !== 0){
            dispatch(actNetRequestFailed(routeCode));

            if (errorHd !== undefined){
                if (errorHd(dispatch, body, msgBody)){
                    return;
                }
            }

            if (body.statusMsg !== undefined && body.status !== undefined){
                message.error(`操作失败! 错误码: ${body.status} 错误提示: ${body.statusMsg}`);
            /* body是一个TypeError类型 */
            }else if (body.message === "Failed to fetch"){
                message.error(`操作失败! 错误提示: 服务端无响应`);
            }
            else{
                message.error(`操作失败! 错误提示: 服务端错误`);
            }
        }else{
            if (successHd !== undefined){
                successHd(dispatch, body, msgBody);
            }
            dispatch(actNetRequestSuccess(routeCode));
        }
    }else{
        dispatch(actNetRequestFailed(routeCode));
        if (errorHd !== undefined){
            if (errorHd(dispatch, body, msgBody)){
                return;
            }
        }
    }
}


export const netRequestThunk2 = (path, msgBody, header, sessionInfo, successHd=undefined, errorHd=undefined, noauthHd=undefined, host=undefined, method='POST') => async (dispatch, getState) => {
    let sesisionId = Math.floor(Math.random() * 1000000, 0)
    dispatch(actNetRequestPending(sesisionId));
    Log.info(sessionInfo && sessionInfo(), `${sesisionId} ==> ${method} ${host}${path} header: ${header ? printObject(header) : ''}body: ${msgBody ? printObject(msgBody) : ''}`)
    const body = await sendRequest(path, msgBody, header, method, host);
    Log.info(sessionInfo && sessionInfo(), `${sesisionId} <== ${method} ${host}${path} body: ${printObject(body)}`)
    if (body !== undefined){
        // console.log(body);
        if (body.Status !== 0) {
            dispatch(actNetRequestFailed(sesisionId));
            // token失效
            if (body.Status === 100008){
                if (noauthHd){
                    noauthHd(body);
                    return;
                }
            }   

            if (errorHd !== undefined){
                if (errorHd(dispatch, body, msgBody)){
                    return;
                }
            }

            if (body.StatusMsg !== undefined && body.Status !== undefined){
                message.error(`操作失败! 错误码: ${body.Status} 错误提示: ${body.StatusMsg}`);
            /* body是一个TypeError类型 */
            }else if (body.message === "Failed to fetch"){
                message.error(`操作失败! 错误提示: 服务端无响应`);
            }
            else{
                message.error(`操作失败! 错误提示: 服务端错误`);
            }
        } else {
            dispatch(actNetRequestSuccess(sesisionId));
            if (successHd !== undefined){
                successHd(dispatch, body, msgBody);
            }
        }
    } else {
        dispatch(actNetRequestFailed(sesisionId));
        if (errorHd !== undefined){
            if (errorHd(dispatch, body, msgBody)){
                return;
            }
        }
    }
}

export const netRequestThunk3 = (path, msgBody, header, sessionInfo, method = "POST", successHd=undefined, errorHd=undefined, noauthHd=undefined) => async (dispatch, getState) => {
    let sesisionId = Math.floor(Math.random() * 1000000, 0)
    dispatch(actNetRequestPending(sesisionId));
    path = (version.isInternal() ? "/as" : "") + path;
    Log.info(sessionInfo && sessionInfo(), `${sesisionId} ==> ${method} ${config.host.cs}${path} header: ${header ? printObject(header) : ''}body: ${msgBody ? printObject(msgBody) : ''}`)
    const body = await sendRequest2(path, msgBody, header, method);
    Log.info(sessionInfo && sessionInfo(), `${sesisionId} <== ${method} ${config.host.cs}${path} body: ${printObject(body)}`)
    if (body !== undefined){
        let errorBody = {status: body.status, url: body.url, body: body.body}
        if (200 <= body.status && body.status <= 299) {
            dispatch(actNetRequestSuccess(sesisionId));
            successHd && successHd(dispatch, body, msgBody);
        } else if (body.status === 401) {
            if (noauthHd) {
                if (!noauthHd(dispatch, path, msgBody, header, sessionInfo, method, successHd, errorHd)) {
                    dispatch(actNetRequestFailed(sesisionId));
                    if (errorHd) {
                        errorHd(dispatch, errorBody, msgBody);
                    }
                }
            } else if (errorHd) {
                dispatch(actNetRequestFailed(sesisionId));
                errorHd(dispatch, errorBody, msgBody);
            }
        } else {
            dispatch(actNetRequestFailed(sesisionId));
            errorHd && errorHd(dispatch, errorBody, msgBody);
        }
    } else {
        dispatch(actNetRequestFailed(sesisionId));
        if (errorHd !== undefined){
            if (errorHd(dispatch, {status: 500, url: path}, msgBody)){
                return;
            }
        }
    }
}