/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'
import { getUrlParam } from "../../util/logic";

@withTranslation('translation', {withRef: true})
class GotoDev extends Component {

    constructor(props){
        super(props);
        
        this.state = {

        }
    }
    
    componentDidMount() {
        let wechatCode = getUrlParam("code", window.location.search)
        let state = getUrlParam("state", window.location.search) || ""
        let host = getUrlParam("host", window.location.search) || ""
        let u = getUrlParam("u", window.location.search) || ""
        let p = getUrlParam("p", window.location.search) || ""
        if (wechatCode) {
            window.location.href = `http://localhost:9004?code=${wechatCode}&state=${state}&u=${u}&p=${p}&host=${host}`
        }
    }

    render() {
        return null;
    }
}

export default connect(
    null, 
    null
)(GotoDev);