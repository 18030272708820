import { adapter } from "../adapter";

class Notification {

    PERMISSION = {
        default: 'default',
        granted: 'granted',
        denied: 'denied',
    }

    constructor() {
        this.__onChangeFuns = [];

        this.__permission = this.PERMISSION.default;
        this.getPermission();
    }

    get permission() {
        return this.__permission;
    }

    addListener(fun) {
        fun.id = Math.random()
        this.__onChangeFuns.push(fun);
    }

    removeListener(fun) {
        let index = this.__onChangeFuns.findIndex((item) => item.id === fun.id);
        if (index !== -1) {
            this.__onChangeFuns.splice(index, 1);
        }
    }

    // 获取权限
    getPermission() {
        function onChange(perm) {
            this.__permission = perm;
            this.__onChangeFuns.forEach((fun) => {
                try {
                    fun(perm);
                } catch (error) {
                    
                }
            })
        }

        return adapter.requestPermissionNotification(onChange.bind(this))
    }

    open(message, options = {}) {
        Object.assign(options, {
            icon: './logo192.png',
            noscreen: true,
            tag: 'notification',
            renotify: true
        })
        return adapter.getNotification(message, options)
    }
}

let notification = new Notification();
export { notification };
