import { Log } from "../../util/log";


export let State = {
    // 登录状态
    Login: {
        INIT: "login::init",
        PROCESS: "login::process",
        OUT: "login::out",
        DONE: "login::done",
        FAILED: "login::failed",
    },

    // 呼叫状态
    Call: {
        INIT: "call::init",
        PROCESS: "call::process",
        STABLE: "call::stable",
        HANGUP: "call::hangup",
    }
}

export class Fsm {
    
    /**
     * 构造函数
     * @method constructor
     * @for Fsm
     * @return {Fsm} 实例对象
     */
    constructor(This) {
        this.This = This;
        this.__useData = null;
        this.__state = State.Login.INIT;
        // this.__state = State.Call.INIT;
        this.__timer = {

        }
        this.__timerConfig = {
            [State.Login.INIT]: 0,
            [State.Login.PROCESS]: 3,
            [State.Login.OUT]: 3,
            [State.Login.DONE]: 0,
            [State.Call.INIT]: 0,
            [State.Call.PROCESS]: 10,
            [State.Call.STABLE]: 0,
            [State.Call.HANGUP]: 3,
        }
    }
    /**
     * 连接一个用户数据
     * @method attach
     * @for Fsm
     * @return {Void} 无
     */
    attach(userData) {
        this.__useData = userData;
    }
    /**
     * 检查用户数据
     * @method check
     * @for Fsm
     * @param {State} userData 需要检查的用户数据
     * @param {Function} checkFun 检查函数
     * @return {Boolean} 匹配则返回true，否则false
     */
    check(userData, checkFun) {
        if (checkFun) {
            return checkFun(this.__useData, userData);
        } else {
            return this.__useData === userData;
        }
    }
    /**
     * 获取当前状态
     * @method curState
     * @for Fsm
     * @return {State} 返回当前状态
     */
    curState() {
        return this.__state;
    }
    /**
     * 状态转移
     * @method transfer
     * @for Fsm
     * @param {State} to 需要转移到的状态
     * @return {Void} 无
     */
    transfer(to) {
        if (this.__state !== to) {
            Log.info(this.This.sipSessionInfo(), `fsm state transfer ${this.__state} -> ${to}`)
            this.__state = to;
        }
    }
    /**
     * 启动状态定时器
     * @method startTimer
     * @for Fsm
     * @param {State} state 此时的状态
     * @param {Number} timeout 超时时间。单位：秒
     * @param {Function} timeoutFun 超时函数
     * @return {Number} 定时器id
     */
    startTimer(state, timeoutFun = null) {
        let that = this;
        let timeout = this.__timerConfig[state];
        this.stopTimer(state);
        if (timeout) {
            this.__timer[state] = setTimeout(() => {
                Log.warn(this.This.sipSessionInfo(), `fsm state:${state} timer(${that.__timer[state]} ${timeout}s) timeout`)
                if (timeoutFun) {
                    timeoutFun();
                }
            }, timeout * 1000);
            Log.debug(this.This.sipSessionInfo(), `fsm state:${state} start timer(${this.__timer[state]} after ${timeout}s) to exec function`)
        } else {
            Log.warn(this.This.sipSessionInfo(), `fsm state:${state} not need start timer timeout:${timeout}s`)
        }

        return this.__timer[state];
    }
    /**
     * 停止状态定时器
     * @method stopTimer
     * @for Fsm
     * @param {State} state 此时的状态
     * @return {Void} 无
     */
    stopTimer(state) {
        let timer = this.__timer[state];
        if (timer) {
            clearTimeout(timer);
            Log.debug(this.This.sipSessionInfo(), `fsm state:${state} stop timer(${timer})`)
            this.__timer[state] = null;
        } else {
            Log.debug(this.This.sipSessionInfo(), `fsm state:${state} do not stop timer(${timer})`)
        }
    }
    /**
     * 测试当前状态
     * @method test
     * @for Fsm
     * @param {State} arguments 需要测试的状态列表
     * @return {Boolean} 成功返回true，否则false
     */
    test() {
        for (let state of arguments) {
            if (this.__state === state) {
                return true;
            }
        }
        return false;
    }
}