import React, { Component } from 'react';
import { connect } from "react-redux"
import moment from "moment";
import { Button, Modal, Descriptions } from "antd";
import { withTranslation } from 'react-i18next'
import { IconFont } from "../../util/iconFont";
import { codeFormatter } from '../../util/logic';
import { getLoginData, getUserData, } from '../../redux/reducers/login'

import "./detail.less";
import "../common/paragraph.css";

@withTranslation('translation', {withRef: true})
class Detail extends Component {
    constructor(props){
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        let { t, visible, detail, living, conferenceAddrFun, liveAddrFun, copyOnClick, onClose, login } = this.props;
        let component = t('detail.none');
    
        if (detail) {
            let creator = detail?.participants?.find((part) => part?.user?.id === detail?.conference?.creatorId)?.user?.nickName;
            let me = detail?.participants?.find((part) => part?.user?.id === login?.owner);
            component = <div className="info-detail">
                <Descriptions className="descriptions" column={1}>
                    <Descriptions.Item label={t('detail.theme')}>{detail?.conference?.subject}</Descriptions.Item>
                    <Descriptions.Item label={t('detail.code')}>
                        {codeFormatter(detail?.conference?.accessCode)}
                        <span className="operation"><Button type="link" 
                            onClick={(e) => {
                                if (copyOnClick) {
                                    copyOnClick(e, "code", detail?.conference?.accessCode)
                                }
                            }
                        }>{t('common.copy')}</Button></span>
                    </Descriptions.Item>
                    <Descriptions.Item label={t('detail.creator')}>{creator}</Descriptions.Item>

                    <Descriptions.Item label={t('detail.start_time')}>{moment((detail?.conference?.startTime || 0) * 1000).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                    <Descriptions.Item label={t('detail.join_time')}>{moment((me?.joinTime || 0) * 1000).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                    <Descriptions.Item label={t('detail.addr')}>
                        {conferenceAddrFun(detail)}
                        <span className="operation"><Button type="link" 
                            onClick={(e) => {
                                if (copyOnClick) {
                                    copyOnClick(e, "conference", conferenceAddrFun(detail))
                                }
                            }
                        }>{t('common.copy')}</Button></span>
                    </Descriptions.Item>
                    {living ? <Descriptions.Item label={t('detail.live_addr')}>
                        {liveAddrFun(detail)}
                        <span className="operation"><Button type="link" 
                                onClick={(e) => {
                                if (copyOnClick) {
                                    copyOnClick(e, "live", liveAddrFun(detail))
                                }
                            }
                        }>{t('common.copy')}</Button></span>
                    </Descriptions.Item> : undefined}

                </Descriptions>
            </div>
        }

        return <Modal
            className="detail"
            width={"50%"}
            title={<span className="title">
                <IconFont className="icon" type="icon-info-dark"/>
                <span>{t('detail.info')}</span>
            </span>}
            closable={true}
            maskClosable={true}
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                {component}
            </div>
        </Modal>
    }
}


let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Detail);