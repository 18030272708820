/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'
import { getUrlParam } from "../../util/logic";

@withTranslation('translation', {withRef: true})
class GotoShow extends Component {

    constructor(props){
        super(props);
        
        this.state = {

        }
    }
    
    componentDidMount() {
        let wechatCode = getUrlParam("code", window.location.search)
        let state = getUrlParam("state", window.location.search) || ""
        if (wechatCode) {
            window.location.href = `https://vmeetingshow.ludiqiao.com?code=${wechatCode}&state=${state}`
        }
    }

    render() {
        return null;
    }
}

export default connect(
    null, 
    null
)(GotoShow);