import React, { Component } from 'react';
import TweenOneIcon from './tweenOneIcon';

class EnDisable2 extends Component {

    render() {
        return <TweenOneIcon 
            from={{
                d: "M58.76134571 582.46680349H275.23819634c21.94935922 0 35.11897429-9.75758222 39.48804096-29.27274667l73.15065969-335.96042127L516.16101603 987.18801237c5.84622763 35.09816889 59.98104349 34.61965255 66.8051092 0l124.35196018-582.70866204 28.75261952 144.80335189c4.38987207 22.46948523 17.06016541 33.18410126 39.9873627 33.18410127h189.20139093c19.51516445 0 35.11897429-15.12529237 35.11897429-34.12032968 0-19.51516445-15.12529237-34.64045682-35.11897429-34.64045682h-172.61974301l-56.06968888-248.20459747c-7.32338745-33.64181333-57.54684985-33.64181333-65.82727112 1.45635555l-122.39628174 546.63265508-127.74318762-776.77844252c-5.36771015-34.12032967-55.61197682-35.09816889-63.41388174 0L253.80896541 513.70601699H58.76134571c-19.51516445 0-35.11897429 15.60380985-35.11897316 34.61965142 0 19.0158427 15.60380985 34.12032967 35.11897316 34.12033081z",
                style: {fill: '#73d13d'},
            }}
            to={{
                d: "M1024.11855074 543.8035417l-1024.18760401 0-0.00141421-63.91821038 1024.18972533-0.00070711z",
                style: {fill: '#ff4d4f'},
            }}
            enable={this.props.enable}
            className={this.props.className}
        />
    }
}

export default EnDisable2;