/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'
import { getUrlParam } from "../../util/logic";

const electron = window.electron;

@withTranslation('translation', {withRef: true})
class GotoLocal extends Component {

    constructor(props){
        super(props);
        
        this.state = {

        }
    }
    
    componentDidMount() {
        let wechatCode = getUrlParam("code", window.location.search) || ""
        let state = getUrlParam("state", window.location.search) || ""

        let sps = state.split(',')
        let exe = sps[sps.length - 1]
        let path = `${exe}?code=${wechatCode}&state=${sps.slice(0, -1).join(',')}`
        if (exe) {
            window.location.replace(path)
        } else {
            console.log("not find exe", state);
        }
    }

    render() {
        return <div></div>;
    }
}

export default connect(
    null, 
    null
)(GotoLocal);